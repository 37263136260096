import { CreateTest } from '../../pages/tests/create/create-test.page';
import { EditTest } from '../../pages/tests/edit/edit-test.page';
import { Tests } from '../../pages/tests/index/tests.page';
import { TestView } from '../../pages/tests/view/test-view.page';
import routes from '../routes/index.routes';
import { Soon } from '@monorepo/base/src/components/soon/soon';

export const TestsRouter = [
	{
		path: routes.tests.index(),
		element: <Tests />,
	},
	{
		path: routes.tests.create(),
		element: <CreateTest />,
	},
	{
		path: routes.tests.duplicate(':testId'),
		element: <CreateTest />,
	},
	{
		path: routes.tests.edit(':testId'),
		element: <EditTest />,
	},
	{
		element: <TestView />,
		children: [
			{
				path: routes.tests.view(':testId'),
				element: <Soon />,
			},
		],
	},
];
