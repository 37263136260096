import { Fragment } from 'react';
import { CampaignInputOptionsStore } from '../../../../stores/campaigns/campaign-input-options.store';
import { Spacer } from '@monorepo/base/src/components/spacer/spacer';
import { Dropdown } from '@monorepo/base/src/components/dropdown/dropdown';

interface Props {
	inputOptions: CampaignInputOptionsStore;
	design?: string;
	format?: string;
	setDesign: (v?: string) => void;
	setFormat: (v?: string) => void;
}

export const ScorePicker = ({ inputOptions, design, format, setDesign, setFormat }: Props) => (
	<Fragment>
		<Dropdown
			label="Design score picker"
			placeholder="Select Design score picker"
			defaultOption={design || ''}
			defaultOptions={new Set(design ? [design] : [])}
			options={inputOptions.design_score_pickers}
			onSelect={v => setDesign(v ? [...v][0] : undefined)}
		/>
		<Spacer />
		<Dropdown
			label="Format score picker"
			placeholder="Select Format score picker"
			defaultOption={format || ''}
			defaultOptions={new Set(format ? [format] : [])}
			options={inputOptions.format_score_pickers}
			onSelect={v => setFormat(v ? [...v][0] : undefined)}
		/>
	</Fragment>
);
