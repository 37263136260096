import { AccountsCrudStore } from './accounts-crud.store';
import { BaseListStore } from '@monorepo/controlled/src/stores/base-list.store';
import { AccountsModel } from '../../models/account.model';
import { AccountsApi } from '../../apis/accounts.api';

export class AccountsStore {
	crud = new AccountsCrudStore();

	listStore = new BaseListStore<AccountsModel>({
		listFunc: AccountsApi.list,
		model: AccountsModel,
	});

	getCrud() {
		return this.crud;
	}
}
