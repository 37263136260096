import { makeAutoObservable } from 'mobx';
import { ReportParams, ReportResponse as IReportResponse, generateReportParams } from './report.types';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { AdminxReporterApi } from '../../apis/adminx-reporter.api';

export class ReportResponse<T> {
	public success: boolean;
	public errorMessage: string | null;
	public data: T[];
	public total: number;
	public limited: boolean;
	public footer: T;
	public columns: string[][];
	public graphData: object;

	constructor(reportResponse: IReportResponse<T>) {
		this.success = reportResponse.success;
		this.errorMessage = reportResponse.errorMessage;
		this.data = reportResponse.data;
		this.total = reportResponse.total;
		this.limited = reportResponse.limited;
		this.footer = reportResponse.footer;
		this.columns = reportResponse.columns;
		this.graphData = reportResponse.graphData;
		makeAutoObservable(this);
	}

	public getFooter(): T {
		return this.footer;
	}

	public getData(): T[] {
		return this.data;
	}
}

class ReportStore<T> {
	reportParams: ReportParams = generateReportParams();
	defaultReportParams: ReportParams;
	report = new HttpStore<ReportParams, ReportResponse<T>>({
		httpFunc: AdminxReporterApi.newReport,
		model: ReportResponse<T>,
	});

	constructor(reportParams: Partial<ReportParams>) {
		this.reportParams = { ...this.reportParams, ...reportParams };
		this.defaultReportParams = JSON.parse(JSON.stringify(this.reportParams));
		makeAutoObservable(this);
	}

	async fetchReport({ params, appendData = false }: { params: Partial<ReportParams>; appendData?: boolean }) {
		const isParamsChanged = this.isParamsAreDifferent(params);
		if (isParamsChanged) {
			this.report.abort();
			this.reportParams = { ...this.defaultReportParams, ...params };
			await this.report.fetch(this.reportParams, undefined, { appendData });
		}
	}

	getReportData(): T[] {
		return this.report.getData()?.getData() || [];
	}

	getReportFooter() {
		return this.report.getData()?.getFooter() || ({} as T);
	}

	getReportTotalRows() {
		return this.report.getData()?.total || 0;
	}

	getIsLoading() {
		return this.report.getIsLoading();
	}

	getError() {
		return this.report.getHttpError();
	}

	isParamsAreDifferent(partialParams: Partial<ReportParams>, params: ReportParams = this.reportParams): boolean {
		// Check each property explicitly if it exists in partialParams
		if (partialParams.start !== undefined && partialParams.start !== params.start) {
			return true;
		}
		if (partialParams.limit !== undefined && partialParams.limit !== params.limit) {
			return true;
		}
		if (partialParams.periodStart !== undefined && partialParams.periodStart !== params.periodStart) {
			return true;
		}
		if (partialParams.periodEnd !== undefined && partialParams.periodEnd !== params.periodEnd) {
			return true;
		}
		if (partialParams.multipleTimePeriods !== undefined) {
			if (
				partialParams.multipleTimePeriods.length !== params.multipleTimePeriods.length ||
				!partialParams.multipleTimePeriods.every(
					(val, index) => JSON.stringify(val) === JSON.stringify(params.multipleTimePeriods[index])
				)
			) {
				return true;
			}
		}
		if (partialParams.filters !== undefined) {
			if (
				partialParams.filters.length !== params.filters.length ||
				!partialParams.filters.every((val, index) => JSON.stringify(val) === JSON.stringify(params.filters[index]))
			) {
				return true;
			}
		}
		if (partialParams.groupBys !== undefined) {
			if (
				partialParams.groupBys.length !== params.groupBys.length ||
				!partialParams.groupBys.every((val, index) => val === params.groupBys[index])
			) {
				return true;
			}
		}
		if (partialParams.dateSegment !== undefined && partialParams.dateSegment !== params.dateSegment) {
			return true;
		}
		if (partialParams.sortBy !== undefined && partialParams.sortBy !== params.sortBy) {
			return true;
		}
		if (partialParams.sortOrder !== undefined && partialParams.sortOrder !== params.sortOrder) {
			return true;
		}
		if (partialParams.inResultsSort !== undefined && partialParams.inResultsSort !== params.inResultsSort) {
			return true;
		}
		if (partialParams.inResultsSortOrder !== undefined && partialParams.inResultsSortOrder !== params.inResultsSortOrder) {
			return true;
		}
		if (partialParams.inResultsFilters !== undefined) {
			if (
				partialParams.inResultsFilters.length !== params.inResultsFilters.length ||
				!partialParams.inResultsFilters.every(
					(val, index) => JSON.stringify(val) === JSON.stringify(params.inResultsFilters[index])
				)
			) {
				return true;
			}
		}
		if (partialParams.graphYaxis !== undefined && partialParams.graphYaxis.length !== params.graphYaxis.length) {
			return true;
		}
		if (partialParams.graphLines !== undefined && partialParams.graphLines.length !== params.graphLines.length) {
			return true;
		}
		if (partialParams.additionalColumns !== undefined && partialParams.additionalColumns.length !== params.additionalColumns.length) {
			return true;
		}
		if (partialParams.reportName !== undefined && partialParams.reportName !== params.reportName) {
			return true;
		}
		if (partialParams.submit !== undefined && partialParams.submit !== params.submit) {
			return true;
		}
		if (partialParams.type !== undefined && partialParams.type !== params.type) {
			return true;
		}

		return false;
	}
}

export default ReportStore;
