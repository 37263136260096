import { Page, Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useEffect, useState } from 'react';
import { PrimaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { Disable } from '@monorepo/base/src/components/disable/disable';
import { BottomBar, BottomBarActions } from '@monorepo/base/src/components/bottom-bar/bottom-bar';
import styles from './account-editor.module.scss';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import routes from '../../../modules/routes/index.routes';
import { ErrorMsg } from '../../../modules/components/error-msg/error-msg';
import { SiderSlider } from '@monorepo/base/src/components/sider-slider/sider-slider';
import { IAdminxStore } from '../../../modules/stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { AccountEditorForm } from '../../../modules/components/forms/account-editor/account-editor.form';
import { AccountsModel } from '../../../modules/models/account.model';

export const AccountEditor = observer(() => {
	const { accountsStore } = useStores<IAdminxStore>();
	const [isFormClicked, setFormClicked] = useState(false);
	const { accountId } = useParams();

	const { dispatchLog } = useLogs();
	const navigate = useNavigateParams();

	const crudStore = accountsStore.getCrud();
	const data = crudStore.getData();
	const formStore = crudStore.getFormStore();
	const isValidForm = formStore.getIsValid();
	const isLoading = crudStore.getIsLoading(); // not working on .get()
	const isSuccess = crudStore.getIsSuccess();
	const error = crudStore.getHttpError();

	const isLoadingEdit = Boolean(accountId && !data?.name);

	useEffect(() => {
		if (!isValidForm && isFormClicked) {
			dispatchLog({
				msg: 'Please complete all mandatory fields',
				type: DispatchLogsTypes.Error,
			});
		}
	}, [isFormClicked]);

	useEffect(() => {
		crudStore.reset();

		if (accountId && crudStore.data.id !== accountId) {
			crudStore.get(accountId).then(data => {
				if (!data?.id) {
					return;
				}
				crudStore.setData(new AccountsModel(data));
			});
		}

		if (isSuccess) {
			navigate(routes.accounts.index());

			dispatchLog({
				msg: `Account ${data.name} was successfully ${accountId ? 'updated' : 'added'}`,
				type: DispatchLogsTypes.Success,
			});

			crudStore.setIsSuccess(false);
		}
	}, [isSuccess, accountId]);

	const handleSave = () => {
		setFormClicked(true);

		if (accountId) {
			crudStore.edit();
		} else {
			crudStore.create();
		}
	};

	const onCloseSiderSlider = () => navigate(routes.accounts.index());

	return (
		<SiderSlider onClose={onCloseSiderSlider} isOpen={true}>
			<Disable is={isLoading || isLoadingEdit}>
				<Page>
					<Pagebar>
						<PagebarTitle unstyled={true}>
							<div className={styles.title}>{accountId ? 'Edit' : 'Create'} Account</div>
						</PagebarTitle>
					</Pagebar>
					<AccountEditorForm />
				</Page>
				<BottomBar>
					{error ? <ErrorMsg error={error} /> : null}
					<BottomBarActions>
						<PrimaryButton
							onClick={handleSave}
							debugProps={{ dataAttrs: [new DataAttribute('id', 'create_creative_groups_button')] }}
							isRotateIcon={isLoading}
							icon={isLoading || isLoadingEdit ? 'loading-01' : accountId ? 'save-01' : 'send-03'}>
							{accountId ? 'Save' : 'Create'}
						</PrimaryButton>
					</BottomBarActions>
				</BottomBar>
			</Disable>
		</SiderSlider>
	);
});
