import { Page, Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { observer } from 'mobx-react';
import { FC } from 'react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../modules/stores';
import routes from '../../../modules/routes/index.routes';
import { Outlet } from 'react-router-dom';
import { ControlledTable } from '@monorepo/controlled/src/components/table-wrapper/table-wrapper';
import { useRoute } from '../../../modules/hooks/tools/use-route';
import { useAccountHeaders } from '../../../modules/hooks/theaders/account.headers';
import styles from './accounts.module.scss';
import { useList } from '../../../modules/hooks/tools/use-list';
import { useStateBetweenPages } from '../../../modules/hooks/tools/use-state-between-pages';
import { AccountsModel } from '../../../modules/models/account.model';

const pageBarDebugProps = { dataAttrs: [new DataAttribute('id', 'keys_management_pagebar')] };

export const Accounts: FC = observer(() => {
	const { accountsStore } = useStores<IAdminxStore>();

	const { currentRouteWithoutSpecialChars } = useRoute();

	const { columns } = useAccountHeaders();
	const stateBetweenPages = useStateBetweenPages(() => accountsStore.listStore.getList().length !== 0);

	useList<AccountsModel>(accountsStore.listStore, undefined, stateBetweenPages);

	return (
		<Page>
			<Pagebar debugProps={pageBarDebugProps}>
				<PagebarTitle>Accounts</PagebarTitle>
			</Pagebar>

			<div className={styles.tableWrapper}>
				<ControlledTable
					currentRoute={currentRouteWithoutSpecialChars}
					createRoute={routes.accounts.create()}
					columns={columns}
					data={accountsStore.listStore.getList()}
					isLoading={accountsStore.listStore.getIsLoading()}
				/>
			</div>

			<Outlet />
		</Page>
	);
});
