import { FilterTypeKey, FilterType, INumberFilter, IStringFilter, IEnumFilter } from '../../components/filters-menu/filters';
import { performanceFilters } from '../../components/filters-menu/filters/perfomance.filter';
import { Statuses } from '../../enums/status';

export const testFilters = new Map<FilterTypeKey, FilterType>([
	[
		'numberFilters',
		{
			...performanceFilters.numberFilters,
			percentage: {
				columnName: 'Percentage',
				cellKey: 'percentage',
				// isPercentage: true,
			},
		} as INumberFilter,
	],
	[
		'stringFilters',
		{
			...performanceFilters.stringFilters,
			name: { columnName: 'Name', cellKey: 'name' },
			description: { columnName: 'Description', cellKey: 'description' },
		} as IStringFilter,
	],
	[
		'enumFilters',
		{
			...performanceFilters.enumFilters,
			status: {
				columnName: 'Status',
				cellKey: 'status',
				enumOptions: [
					{ value: Statuses.Active, label: 'Active' },
					{ value: Statuses.Paused, label: 'Paused' },
					{ value: Statuses.NoBalance, label: 'No Balance' },
					{ value: Statuses.New, label: 'New' },
					{ value: Statuses.Ended, label: 'Ended' },
				],
				deletable: true,
			},
		} as IEnumFilter,
	],
]);
