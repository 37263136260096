import { Fragment } from 'react';
import { Segments } from '@monorepo/base/src/components/segments/segments';
import { SelectedLinerButton } from '@monorepo/base/src/components/table/action-liner/liner-buttons';
import { QuickActionButton } from '@monorepo/base/src/components/table/quick-actions-menu/quick-actions-menu';
import { TestModel } from '../../models/test.model';
import routes from '../../routes/index.routes';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { useSegments } from '../tools/use-segments';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IEntityActions, ITableActions, useDefaultActions } from './default.actions';
import { ITableFiltersActions, TableFilters } from '@monorepo/base/src/components/table/table-filters/table-filters';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { IAdminxStore } from '../../stores';
import { SecondaryText } from '@monorepo/base/src/components/buttons/buttons';
import { ActionModal, ModalTypes } from '@monorepo/controlled/src/components/modal/modals/action-modal/action-modal';
import { EnablePauseTestsModal } from '../../components/modals/enable-pause-tests';
import { Statuses } from '../../enums/status';
import { ISegmentActions } from '@monorepo/base/src/components/table/table';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { testFilterPrototypes } from '../../components/filters-menu/filters';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { DispatchLogsTypes } from '@monorepo/controlled/src/hooks/use-logs';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { suffixToDataAttr } from '@monorepo/tools/src/lib/models/data-attr.model';

const theme = getTheme();
interface ITestActions {
	debugProps?: IDebugProps;
}

export function useTestActions(props: ITestActions = {}) {
	const { debugProps } = props;
	const { dataAttrs } = debugProps || {};
	const { testStore, modalsStore, toastsStore, settingsStore } = useStores<IAdminxStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();

	const performanceStore = testStore.getPerformance();

	const filterStore = settingsStore
		.getPages()
		.get(currentRouteWithoutSpecialChars)
		?.tableStore.getFiltersStore(currentRouteWithoutSpecialChars);
	const currentFilters = filterStore?.getCurrentFilters();
	const DefaultAvailableFilters = [...testFilterPrototypes];

	const testsCrudStore = testStore.getCrud();

	const onEditRoute = (id: id) => routes.tests.edit(id);
	const onDelete = (ids: id[]) => testStore.getCrud().delete(ids[0]);

	const navigate = useNavigateParams();

	const funcActions = {
		onEditRoute,
		onDelete,
	};

	const enableTests = (testIds: id[]) => {
		modalsStore.addModal({
			children: (
				<ActionModal
					type={ModalTypes.Info}
					primaryBtnTitle={'Enable'}
					onAction={() => {
						testsCrudStore.enableTests(testIds).then(() => {
							toastsStore.addToastOnNav({ msg: `Tests ${testIds} Enabled`, type: DispatchLogsTypes.Success });
							navigate(0);
						});
					}}>
					<EnablePauseTestsModal action={'Enable'} testIds={testIds} />
				</ActionModal>
			),
		});
	};

	const pauseTests = (testIds: id[]) => {
		modalsStore.addModal({
			children: (
				<ActionModal
					type={ModalTypes.Info}
					primaryBtnTitle={'Paused'}
					onAction={() => {
						testsCrudStore.pauseTests(testIds).then(() => {
							toastsStore.addToastOnNav({ msg: `Tests ${testIds} Paused`, type: DispatchLogsTypes.Success });
							navigate(0);
						});
					}}>
					<EnablePauseTestsModal action={'Paused'} testIds={testIds} />
				</ActionModal>
			),
		});
	};

	const SelectedActions = (props: ITableActions<TestModel>) => {
		const { rows } = props;
		const entities = rows.map(row => new TestModel(row.original));
		const { EditAction, DeleteAction } = useDefaultActions({ entities, debugProps, ...funcActions });
		const entitiesIds: id[] = [];
		entities.forEach(e => {
			const id = e.getId();
			if (id) {
				entitiesIds.push(id);
			}
		});

		return (
			<Fragment>
				<SelectedLinerButton>
					<SecondaryText
						icon={'play'}
						onClick={() => enableTests(entitiesIds)}
						textColor={'white'}
						debugProps={{ dataAttrs: suffixToDataAttr('_selected_enable', dataAttrs) }}>
						Enable
					</SecondaryText>
				</SelectedLinerButton>
				<SelectedLinerButton>
					<SecondaryText
						icon={'pause-square'}
						onClick={() => pauseTests(entitiesIds)}
						textColor={'white'}
						debugProps={{ dataAttrs: suffixToDataAttr('_selected_pause', dataAttrs) }}>
						Pause
					</SecondaryText>
				</SelectedLinerButton>
				<SelectedLinerButton>
					<EditAction debugProps={{ dataAttrs: suffixToDataAttr('_selected_edit', dataAttrs) }} />
				</SelectedLinerButton>

				<SelectedLinerButton>
					<SecondaryText
						textColor={'white'}
						icon={'file-plus-01'}
						disabled={entitiesIds.length !== 1}
						onClick={() => navigate(routes.tests.duplicate(entitiesIds[0]))}
						debugProps={{ dataAttrs: suffixToDataAttr('_selected_duplicate', dataAttrs) }}>
						Duplicate
					</SecondaryText>
				</SelectedLinerButton>

				<SelectedLinerButton>
					<DeleteAction debugProps={{ dataAttrs: suffixToDataAttr('_selected_delete', dataAttrs) }} />
				</SelectedLinerButton>
			</Fragment>
		);
	};

	const EntityActions = (props: IEntityActions<TestModel>) => {
		const { entity } = props;
		const { EditAction, DeleteAction } = useDefaultActions({ entities: [entity], ...funcActions });
		const entityId = entity.getId();
		if (!entityId) {
			// log error
			return null;
		}

		return (
			<Fragment>
				{entity.getStatus() === Statuses.Paused ? (
					<QuickActionButton>
						<SecondaryText
							icon={'circle'}
							iconSize={'14px'}
							iconColor={'#0b8043'}
							textColor={theme.titleColor}
							onClick={() => enableTests([entityId])}>
							Enable
						</SecondaryText>
					</QuickActionButton>
				) : null}
				{entity.getStatus() === Statuses.Active ? (
					<QuickActionButton>
						<SecondaryText
							icon={'pause-circle'}
							iconSize={'14px'}
							iconColor={theme.titleColor}
							textColor={theme.titleColor}
							onClick={() => pauseTests([entityId])}>
							Pause
						</SecondaryText>
					</QuickActionButton>
				) : null}
				<QuickActionButton>
					<EditAction iconColor={theme.titleColor} textColor={theme.titleColor} />
				</QuickActionButton>
				<QuickActionButton>
					<DeleteAction iconColor={theme.titleColor} textColor={theme.titleColor} iconSize={'14px'} />
				</QuickActionButton>
			</Fragment>
		);
	};

	const SegmentActions = (props: ISegmentActions) => {
		const { segments, onSegment } = useSegments<TestModel>({ performanceStore });

		return (
			<Segments
				currentSegments={performanceStore.getSegments()}
				availableSegments={segments}
				onClick={segment => onSegment(segment)}
				{...props}
			/>
		);
	};

	const FiltersActions = (props: ITableFiltersActions) => {
		if (!currentFilters) {
			return null;
		}

		return (
			<TableFilters
				{...props}
				availableFilters={DefaultAvailableFilters}
				currentFilters={currentFilters}
				clearAll={() => filterStore?.clearAll()}
				clearFilter={index => filterStore?.clearFilter(index)}
				addFilter={filter => filterStore?.addFilter(filter)}
				editFilter={(index, filter) => filterStore?.editFilter(index, filter)}
			/>
		);
	};

	return {
		SelectedActions,
		EntityActions,
		SegmentActions,
		FiltersActions,
	};
}
