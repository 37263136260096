import { FilterTypeKey, FilterType, IEntityFilter, INumberFilter } from '../../components/filters-menu/filters';
import { Statuses } from '../../enums/status';
import { BidderApi } from '../../apis/bidder.api';

export const bidderCampaignFilters = new Map<FilterTypeKey, FilterType>([
	[
		'entityFilters',
		{
			id: {
				columnName: 'Name',
				cellKey: 'id',
				isClientFilter: true,
				autocompleteCallback: async () => {
					return await BidderApi.getCampaigns().then(res => {
						return res.campaigns.map(campaign => ({
							label: `${campaign.name} (${campaign.id})`,
							value: campaign.id,
						}));
					});
				},
			},
			advertiserId: {
				columnName: 'Advertiser',
				cellKey: 'advertiserId',
				isClientFilter: true,
				autocompleteCallback: async () => {
					return await BidderApi.getAdvertisers().then(res => {
						return res.advertisers.map(advertiser => ({
							label: `${advertiser.name} (${advertiser.id})`,
							value: advertiser.id,
						}));
					});
				},
			},
		} as IEntityFilter,
	],
	[
		'numberFilters',
		{
			dailyBudget: { columnName: 'Daily Budget', isClientFilter: true, cellKey: 'dailyBudget' },
			dailyMoneySpent: { columnName: 'Daily Money Spent', isClientFilter: true, cellKey: 'dailyMoneySpent' },
			dailyMoneyEarn: { columnName: 'Daily Money Earn', isClientFilter: true, cellKey: 'dailyMoneyEarn' },
			maxLossPerDomain: { columnName: 'Max Loss Per Domain', isClientFilter: true, cellKey: 'maxLossPerDomain' },
			maxClicksToFirstConv: { columnName: 'Max Clicks to First Conv', isClientFilter: true, cellKey: 'maxClicksToFirstConv' },
			qMaxLossPerDomain: { columnName: 'Q Max Loss Per Domain', isClientFilter: true, cellKey: 'qMaxLossPerDomain' },
			qMaxClicksToFirstConv: { columnName: 'Q Max Clicks to First Conv', isClientFilter: true, cellKey: 'qMaxClicksToFirstConv' },
			dailySales: { columnName: 'Daily Sales', isClientFilter: true, cellKey: 'dailySales' },
			maxWinsToFirstClick: { columnName: 'Max Wins To First', isClientFilter: true, cellKey: 'maxWinsToFirstClick' },
			maxClicksToFirstPageLoad: {
				columnName: 'Max Clicks to First Page Load',
				isClientFilter: true,
				cellKey: 'maxClicksToFirstPageLoad',
			},
			maxPageLoadToFirstSale: { columnName: 'Max Page Load To First Sale', isClientFilter: true, cellKey: 'maxPageLoadToFirstSale' },
			pageLoadsClicksRatio: { columnName: 'Page Loads Clicks Ratio', isClientFilter: true, cellKey: 'pageLoadsClicksRatio' },
			convPageLoadsRatio: { columnName: 'Conv Page Load Ratio', isClientFilter: true, cellKey: 'convPageLoadsRatio' },
			convClicksRatio: { columnName: 'Conv Clicks Ratio', isClientFilter: true, cellKey: 'convClicksRatio' },
		} as INumberFilter,
	],

	[
		'stringFilters',
		{
			stateMachineId: { columnName: 'State Machine Id', isClientFilter: true, cellKey: 'stateMachineId' },
		},
	],

	[
		'enumFilters',
		{
			status: {
				columnName: 'Status',
				cellKey: 'status',
				enumOptions: [
					{ value: Statuses.Active, label: 'Active' },
					{ value: Statuses.Paused, label: 'Paused' },
					{ value: Statuses.NoBalance, label: 'No Balance' },
					{ value: Statuses.New, label: 'New' },
					{ value: Statuses.Ended, label: 'Ended' },
				],
				deletable: true,
			},
			targetingLinkChoicePolicy: {
				columnName: 'Targeting Link Choice Policy',
				isClientFilter: true,
				cellKey: 'targetingLinkChoicePolicy',
				enumOptions: [
					{ value: 'perPublisher', label: 'perPublisher' },
					{ value: 'byWeight', label: 'byWeight' },
					{ value: 'withConversionLimit', label: 'withConversionLimit' },
				],
				deletable: true,
			},
		},
	],
]);
