import { observer } from 'mobx-react';
import { FC, Fragment, useEffect } from 'react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { Card } from '@monorepo/base/src/components/card/card';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { IAdminxStore } from '../../../../stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import styles from './basic-info.module.scss';
import { Toggle } from '@monorepo/base/src/components/form/toggle/toggle';
import { Spacer } from '@monorepo/base/src/components/spacer/spacer';
import { DropdownNew } from '@monorepo/base/src/components/dropdown-new/dropdown-new';
import { Flex } from '@monorepo/base/src/components/flex/flex';

export const BasicInfo: FC = observer(() => {
	const { bidderStore } = useStores<IAdminxStore>();

	const campaignCrudStore = bidderStore.getCrud();
	const formStore = campaignCrudStore.getFormStore();
	const campaign = campaignCrudStore.getData().campaign;

	if (campaign === undefined) {
		return null;
	}

	useEffect(() => {
		if (campaign.campaign_advertiser_id) {
			bidderStore.campaignGroups.fetch(campaign.campaign_advertiser_id);
		}
	}, [campaign.campaign_advertiser_id]);

	return (
		<Card
			id={'basicInfo'}
			title={'Basic Info'}
			icon={'settings-01'}
			className={styles.wrapper}
			debugProps={{ dataAttrs: [new DataAttribute('id', 'basic_info_section')] }}>
			<Card>
				<div className={styles.inputsWrapper}>
					<Input
						className={styles.campaignName}
						value={campaign.campaign_name}
						placeholder={'Campaign Name'}
						onValue={value => {
							campaign.campaign_name = value;
							formStore.clearError('name');
						}}
						required
						error={formStore.getErrors().get('name')?.getMsg()}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'name')] }}
					/>
					<Input
						className={styles.campaignId}
						value={campaign.campaign_id?.toString()}
						placeholder={'Campaign ID'}
						disabled
						error={formStore.getErrors().get('name')?.getMsg()}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'name')] }}
					/>
				</div>
				<Input
					value={campaign.campaign_daily_budget?.toString()}
					placeholder={'Daily Budget'}
					type={'number'}
					required
					onValue={value => {
						campaign.campaign_daily_budget = parseFloat(value ?? 0);
						formStore.clearError('dailyBudget');
					}}
				/>
				<Input
					value={campaign.campaign_max_loss_per_domain?.toString()}
					placeholder={'Max Loss per Domain'}
					type={'number'}
					required
					onValue={value => {
						campaign.campaign_max_loss_per_domain = parseFloat(value);
						formStore.clearError('maxLossPerDomain');
					}}
				/>
				<Input
					value={campaign.campaign_max_clicks_to_first_conv?.toString()}
					placeholder={'Max Clicks to First Conversion'}
					type={'number'}
					required
					onValue={value => {
						campaign.campaign_max_clicks_to_first_conv = parseInt(value);
						formStore.clearError('maxClicksToFirstConv');
					}}
				/>
				<Input
					value={campaign.campaign_q_max_loss_per_domain?.toString()}
					placeholder={'Q Max Loss per Domain'}
					type={'number'}
					required
					onValue={value => {
						campaign.campaign_q_max_loss_per_domain = parseInt(value);
						formStore.clearError('maxLossPerDomain');
					}}
				/>
				<Input
					value={campaign.campaign_q_max_clicks_to_first_conv?.toString()}
					placeholder={'Q Max Clicks to First Conversion'}
					type={'number'}
					required
					onValue={value => {
						campaign.campaign_q_max_clicks_to_first_conv = parseFloat(value);
						formStore.clearError('maxClicksToFirstConv');
					}}
				/>
				<Input
					value={campaign.campaign_weight?.toString()}
					placeholder={'Campaign Weight'}
					type={'number'}
					required
					min={0}
					max={100}
					error={formStore.getErrors().get('campaign_weight')?.getMsg()}
					onValue={value => {
						campaign.campaign_weight = parseInt(value);
						formStore.clearError('campaign_weight');
					}}
				/>
				<Spacer />
				<DropdownNew
					defaultValues={campaign.campaign_advertiser_id ? [campaign.campaign_advertiser_id] : []}
					label="Advertiser"
					required
					options={bidderStore.getActiveAdvertisers().map(i => ({ label: i.name, value: i.id }))}
					onSelect={v => {
						campaign.campaign_advertiser_id = v[0];
						campaign.campaign_group_id = undefined;
						formStore.clearError('campaign_advertiser_id');
					}}
					error={formStore.getErrors().get('campaign_advertiser_id')?.getMsg()}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'campaign_advertiser_id')] }}
				/>
				<Spacer />
				{campaign.campaign_advertiser_id && (
					<Fragment>
						<DropdownNew
							defaultValues={campaign.campaign_group_id ? [campaign.campaign_group_id] : []}
							label="Campgin Group"
							required
							isLoading={bidderStore.campaignGroups.isLoading}
							disabled={bidderStore.campaignGroups.isLoading}
							options={bidderStore.getCampaignGroups().map(i => ({ label: i.name, value: i.id }))}
							onSelect={v => {
								campaign.campaign_group_id = v[0];
								formStore.clearError('campaign_group_id');
							}}
							error={formStore.getErrors().get('campaign_group_id')?.getMsg()}
							debugProps={{ dataAttrs: [new DataAttribute('id', 'campaign_group_id')] }}
						/>
						<Spacer />
					</Fragment>
				)}
				<Flex alignSelf={'start'}>
					<Toggle
						label={campaign.campaign_jm_exclusion === 1 ? 'Include' : 'Exclude'}
						title="Job Manager Exclusion"
						onChange={value => {
							campaign.campaign_jm_exclusion = value ? 1 : 0;
						}}
						defaultValue={campaign.campaign_jm_exclusion === 1}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'campaign_status')] }}
					/>
				</Flex>
				<Spacer />
			</Card>
		</Card>
	);
});
