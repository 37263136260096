import { IFilterProto } from '@monorepo/controlled/src/models/filter.model';
import { EnumFilterPrototype } from './enum.filter';
import { NumberFilterPrototype } from './number.filter';
// import { StatusFilterPrototype } from './status.filter';
import { StringFilterPrototype } from './string.filter';
import { advertiserFilters } from '../../../hooks/filters/advertisers.filters';
import { campaignGroupFilters } from '../../../hooks/filters/campaign-groups.filters';
import { campaignFilters } from '../../../hooks/filters/campaigns.filters';
import { creativeGroupFilters } from '../../../hooks/filters/creative-groups.filters';
import { creativeFilters } from '../../../hooks/filters/creatives.filters';
import { dealFilters } from '../../../hooks/filters/deals.filters';
import { jobFilters } from '../../../hooks/filters/jobs.filters';
import { preferenceFilters } from '../../../hooks/filters/preferences.filters';
import { testFilters } from '../../../hooks/filters/tests.filters';
import { reportFilters } from '../../../hooks/filters/reports.filters';
import { SelectOptions } from '@monorepo/base/src/components/select-new/select-new';
import { EntityFilterPrototype } from './entity.filter';
import { bidderCampaignFilters } from '../../../hooks/filters/bidderCampaign.filters';

export interface INumberFilterOptions {
	columnName: string;
	cellKey: string;
	isPercentage?: boolean;
	entity?: string;
}

export interface IStringFilterOptions {
	columnName: string;
	cellKey: string;
	entity?: string;
}

export interface IEnumFilterOptions {
	columnName: string;
	cellKey: string;
	enumOptions: SelectOptions[];
	deletable?: boolean;
	entity?: string;
}

export interface IEntityFilterOptions {
	columnName: string;
	cellKey: string;
	entity?: string;
	propertyName?: string;
	autocompleteCallback?: (...params: any) => Promise<SelectOptions[]>;
	isClientFilter?: boolean;
}

export type FilterType = INumberFilter | IStringFilter | IEnumFilter | IEntityFilter;

export type FilterTypeKey = 'numberFilters' | 'stringFilters' | 'enumFilters' | 'entityFilters';

export interface INumberFilter {
	[key: string]: INumberFilterOptions;
}

export interface IStringFilter {
	[key: string]: IStringFilterOptions;
}

export interface IEnumFilter {
	[key: string]: IEnumFilterOptions;
}

export interface IEntityFilter {
	[key: string]: IEntityFilterOptions;
}

export type IEntityFilters = {
	[key in FilterTypeKey]: FilterType;
};

enum EntitiesPrefixes {
	Advertisers = 'advertisers',
	CampaignGroups = 'campaignsgroups',
	Campaigns = 'campaigns',
	BidderCampaigns = 'biddercampaigns',
	CreativeGroups = 'creativegroups',
	Creatives = 'creatives',
	Deals = 'deals',
	Jobs = 'jobs',
	Preferences = 'preferences',
	Tests = 'tests',
	CampaignsDailyReport = 'campaignsdailyreport',
	Reports = 'reports',
}

const addEntitiesPrefix = (entitiesFilters: Map<FilterTypeKey, FilterType>[], entitiesPrefix: EntitiesPrefixes[]) => {
	entitiesFilters.forEach((entityFilters, index) => {
		entityFilters.forEach(filterType => {
			Object.keys(filterType).forEach((filterKey: string) => {
				filterType[filterKey] = { ...filterType[filterKey] }; // deep clone of performence filters
				filterType[filterKey].entity = entitiesPrefix[index];
			});
		});
	});
};

const setFilterPrototypes = (entityFilters: Map<FilterTypeKey, FilterType>): IFilterProto[] => {
	const filterPrototypes: IFilterProto[] = [];

	for (const key of entityFilters.keys()) {
		if (key === 'numberFilters') {
			const numberFilters = entityFilters.get(key) as INumberFilter;
			Object.keys(numberFilters).forEach(columnKey => {
				filterPrototypes.push(NumberFilterPrototype(numberFilters[columnKey]));
			});
		} else if (key === 'stringFilters') {
			const stringFilters = entityFilters.get(key) as IStringFilter;
			Object.keys(stringFilters).forEach(columnKey => {
				filterPrototypes.push(StringFilterPrototype(stringFilters[columnKey]));
			});
		} else if (key === 'enumFilters') {
			const enumFilters = entityFilters.get(key) as IEnumFilter;
			Object.keys(enumFilters).forEach(columnKey => {
				filterPrototypes.push(EnumFilterPrototype(enumFilters[columnKey]));
			});
		} else if (key === 'entityFilters') {
			const eFilters = entityFilters.get(key) as IEntityFilter;
			Object.keys(eFilters).forEach(columnKey => {
				filterPrototypes.push(EntityFilterPrototype(eFilters[columnKey]));
			});
		}
	}

	return filterPrototypes;
};

const initializePrototypeFilter = (filterPrototypes: IFilterProto[]) => {
	return filterPrototypes.reduce((prev, currentFilter) => {
		return { ...prev, [currentFilter.prop]: currentFilter };
	}, {});
};

// make sure that filers array and prefixes has the same order
addEntitiesPrefix(
	[
		campaignFilters,
		bidderCampaignFilters,
		advertiserFilters,
		creativeFilters,
		testFilters,
		preferenceFilters,
		campaignGroupFilters,
		creativeGroupFilters,
		dealFilters,
		jobFilters,
		reportFilters,
	],
	[
		EntitiesPrefixes.Campaigns,
		EntitiesPrefixes.BidderCampaigns,
		EntitiesPrefixes.Advertisers,
		EntitiesPrefixes.Creatives,
		EntitiesPrefixes.Tests,
		EntitiesPrefixes.Preferences,
		EntitiesPrefixes.CampaignGroups,
		EntitiesPrefixes.CreativeGroups,
		EntitiesPrefixes.Deals,
		EntitiesPrefixes.Jobs,
		EntitiesPrefixes.Reports,
	]
);

export const campaignFilterPrototypes = setFilterPrototypes(campaignFilters);
export const bidderCampaignFilterPrototypes = setFilterPrototypes(bidderCampaignFilters);
export const advertiserFilterPrototypes = setFilterPrototypes(advertiserFilters);
export const creativeFilterPrototypes = setFilterPrototypes(creativeFilters);
export const testFilterPrototypes = setFilterPrototypes(testFilters);
export const preferencesFilterPrototypes = setFilterPrototypes(preferenceFilters);
export const campaignGroupsFilterPrototypes = setFilterPrototypes(campaignGroupFilters);
export const creativeGroupsFilterPrototypes = setFilterPrototypes(creativeGroupFilters);
export const dealsFilterPrototypes = setFilterPrototypes(dealFilters);
export const jobsFilterPrototypes = setFilterPrototypes(jobFilters);
export const reportsFilterPrototypes = setFilterPrototypes(reportFilters);

export const filterPrototypesByProp: Record<string, IFilterProto> = {
	...initializePrototypeFilter(campaignFilterPrototypes),
	...initializePrototypeFilter(bidderCampaignFilterPrototypes),
	...initializePrototypeFilter(advertiserFilterPrototypes),
	...initializePrototypeFilter(creativeFilterPrototypes),
	...initializePrototypeFilter(testFilterPrototypes),
	...initializePrototypeFilter(preferencesFilterPrototypes),
	...initializePrototypeFilter(campaignGroupsFilterPrototypes),
	...initializePrototypeFilter(creativeGroupsFilterPrototypes),
	...initializePrototypeFilter(dealsFilterPrototypes),
	...initializePrototypeFilter(jobsFilterPrototypes),
	...initializePrototypeFilter(reportsFilterPrototypes),
};
