import React from 'react';
import { Card } from '@monorepo/base/src/components/card/card';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../../stores';
import styles from './bidder-initial-bids.module.scss';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { observer } from 'mobx-react';

export const BidderInitialBids = observer(() => {
	const { bidderStore } = useStores<IAdminxStore>();

	const campaignCrudStore = bidderStore.getCrud();
	const formStore = campaignCrudStore.getFormStore();
	const campaign = campaignCrudStore.getData().campaign;

	if (campaign === undefined) {
		return null;
	}

	const setValue = (field: string, value: number | string) => {
		campaign.campaign_initial_bids = {
			...campaign.campaign_initial_bids,
			[field]: value,
		};
	};

	return (
		<Card icon={'settings-01'} title={'Initial Bids'} id={'initialBids'} className={styles.wrapper}>
			<div className={styles.bidRange}>
				<Input
					value={campaign.campaign_initial_bids?.minimal_bid?.toString()}
					placeholder={'Minimal Bid'}
					type={'number'}
					onValue={value => {
						setValue('minimal_bid', value);
						formStore.clearError('campaign_initial_bids.minimal_bid');
					}}
					error={formStore.getErrors().get('campaign_initial_bids.minimal_bid')?.getMsg()}
				/>
				<span>-</span>
				<Input
					value={campaign.campaign_initial_bids?.maximum_bid?.toString()}
					placeholder={'Maximum Bid'}
					type={'number'}
					onValue={value => {
						setValue('maximum_bid', value);
						formStore.clearError('campaign_initial_bids.maximum_bid');
					}}
					error={formStore.getErrors().get('campaign_initial_bids.maximum_bid')?.getMsg()}
				/>
			</div>

			<Input
				value={campaign.campaign_initial_bids?.random_bid_step?.toString()}
				placeholder={'Step'}
				type={'number'}
				onValue={value => {
					setValue('random_bid_step', value);
					formStore.clearError('campaign_initial_bids.random_bid_step');
				}}
				error={formStore.getErrors().get('campaign_initial_bids.random_bid_step')?.getMsg()}
			/>
			<Input
				value={campaign.campaign_initial_bids?.max_loss_per_key?.toString()}
				placeholder={'Max Loss per Domain'}
				type={'number'}
				onValue={value => {
					setValue('max_loss_per_key', parseInt(value ?? 0));
					formStore.clearError('campaign_initial_bids.max_loss_per_key');
				}}
				error={formStore.getErrors().get('campaign_initial_bids.max_loss_per_key')?.getMsg()}
			/>
			<Input
				value={campaign.campaign_initial_bids?.max_num_Of_clicks_to_first_conv?.toString()}
				placeholder={'Max Clicks to First Conversion'}
				type={'number'}
				onValue={value => {
					setValue('max_num_Of_clicks_to_first_conv', parseInt(value ?? 0));
					formStore.clearError('campaign_initial_bids.max_num_Of_clicks_to_first_conv');
				}}
				error={formStore.getErrors().get('campaign_initial_bids.max_num_Of_clicks_to_first_conv')?.getMsg()}
			/>
		</Card>
	);
});
