import { makeAutoObservable, runInAction } from 'mobx';
import {
	BidderWhitelistData,
	BidderWhitelistType,
} from '../../components/forms/bidder-campaign/whitelist-blacklist/bidder-whitelist-blacklist.consts';
import { BidderWhitelistApi } from '../../apis/bidder-whitelist-blacklist.api';
import { WhiteBlackLists } from '../../enums/white-black-list-enum';

export interface BidderWhitelistBlacklistData {
	list_type?: WhiteBlackLists;
	campaign_id?: string;
	domain?: string;
	tag?: string;
}

export class BidderWhitelistBlacklistStore {
	private data: Record<BidderWhitelistType, BidderWhitelistBlacklistData[]> = {} as Record<
		BidderWhitelistType,
		BidderWhitelistBlacklistData[]
	>;
	private isLocalCache: Record<BidderWhitelistType, boolean> = {} as Record<BidderWhitelistType, boolean>;
	private isLoading: Record<BidderWhitelistType, boolean> = {} as Record<BidderWhitelistType, boolean>;

	private error: Error | null = null;

	constructor() {
		makeAutoObservable(this);
	}

	fetch(type: BidderWhitelistType, campaignId: string, skipCache = false): Promise<void> {
		if ((this.isLocalCache[type] && !skipCache) || !campaignId) {
			return Promise.resolve();
		}

		this.isLocalCache[type] = true;
		this.setIsLoading(true, type);

		return BidderWhitelistApi.get(BidderWhitelistData[type].apiUrls.get, campaignId)
			.then(res => {
				runInAction(() => {
					this.isLocalCache[type] = false;

					this.data[type] = this.mapCsv(res);

					this.setIsLoading(false, type);
				});
			})
			.catch((error: Error) => {
				runInAction(() => {
					this.error = error;
				});
			});
	}

	mapCsv(input: string): BidderWhitelistBlacklistData[] {
		const lines = input.split('\n');
		const headers = lines[0].split(',');

		return lines
			.slice(1)
			.map(line => {
				const values = line.split(',');

				const obj = {} as BidderWhitelistBlacklistData;

				if (values.every(v => !v)) {
					return false;
				}

				headers.forEach((header, index) => {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					obj[header.trim()] = values[index]?.trim() || '';
				});

				return obj;
			})
			.filter(Boolean) as BidderWhitelistBlacklistData[];
	}

	upload(type: BidderWhitelistType, data: FormData) {
		return BidderWhitelistApi.post(BidderWhitelistData[type].apiUrls.post, data);
	}

	getIsLoading(type: BidderWhitelistType): boolean {
		return this.isLoading[type];
	}

	setIsLoading(isLoading: boolean, type: BidderWhitelistType) {
		this.isLoading[type] = isLoading;
	}

	getError(): Error | null {
		return this.error;
	}

	getData() {
		return this.data;
	}
}
