import { makeAutoObservable } from 'mobx';
import { SettingsStore } from '@monorepo/controlled/src/stores/settings.store';
import { FilterStore } from '@monorepo/controlled/src/stores/filter.store';
import { TableStore } from '@monorepo/controlled/src/stores/table.store';
import { filterPrototypesByProp } from '../../components/filters-menu/filters';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { IPerformanceOptions, PerformancesColumnsSnakeCase } from '../../hooks/apis/use-reports-list';
import { ReportResponse } from '../../models/report.model';
import {
	IDomainCampaignPerformance,
	IDomainCampaignsStatus,
	IDomainHistory,
	IDomainPertargetingStatus,
	IDomainScore,
	IDomainsPerformance,
} from '../../models/domain.models';
import { AdminxReporterApi } from '../../apis/adminx-reporter.api';
import { ReportTableStore } from '../../components/report-table/report-table.store';
import ReportStore from '../../components/report-table/report.store';
import { ReportDateSegments, generateReportParams } from '../../components/report-table/report.types';
import {
	AggregationColumnConfig,
	DataColumnConfig,
	MetricsColumnConfig,
	MetricsCurrencyColumnConfig,
	MetricsPercentageColumnConfig,
} from '../../components/report-table/predefined-columns/columns';
import { advertiserNameCell, campaignNameCell } from '../../components/report-table/predefined-columns/cells/domains/domains.cells';

export enum DomainFiltersStoreKeys {
	Domains = 'domains',
}

export class DomainsStore {
	settingsStore: SettingsStore;
	list = new HttpStore<IPerformanceOptions, ReportResponse<IDomainsPerformance>>({
		httpFunc: AdminxReporterApi.report,
		model: ReportResponse<IDomainsPerformance>,
	});
	scores = new HttpStore<IPerformanceOptions, ReportResponse<IDomainScore>>({
		httpFunc: AdminxReporterApi.report,
		model: ReportResponse<IDomainScore>,
	});
	history = new HttpStore<IPerformanceOptions, ReportResponse<IDomainHistory>>({
		httpFunc: AdminxReporterApi.report,
		model: ReportResponse<IDomainHistory>,
	});
	campaignStatus = new HttpStore<IPerformanceOptions, ReportResponse<IDomainCampaignsStatus>>({
		httpFunc: AdminxReporterApi.report,
		model: ReportResponse<IDomainCampaignsStatus>,
	});
	pretargetingStatus = new HttpStore<IPerformanceOptions, ReportResponse<IDomainPertargetingStatus>>({
		httpFunc: AdminxReporterApi.report,
		model: ReportResponse<IDomainPertargetingStatus>,
	});

	campaignPerformanceMetrics = new ReportStore<IDomainCampaignPerformance>({
		...generateReportParams(),
		reportName: 'adx',
		groupBys: ['dt'],
		sortBy: 'date',
		submit: false,
		limit: 1000,
		dateSegment: ReportDateSegments.Daily,
		filters: [],
	});

	campaignPerformance = new ReportTableStore<IDomainCampaignPerformance>(
		{
			...generateReportParams(),
			reportName: 'adx',
			groupBys: ['campaign_id', 'campaign', 'domain', 'advertiser_id', 'advertiser'],
			sortBy: 'wins',
			submit: true,
			dateSegment: ReportDateSegments.None,
			filters: [],
		},
		[
			{
				...new AggregationColumnConfig({
					name: 'campaign',
					accessor: 'campaign',
					header: 'Campaign Name',
					linkTo: ['campaign_id'],
					cell: campaignNameCell,
				}),
			},
			{
				...new AggregationColumnConfig({
					name: 'campaign_id',
					accessor: 'campaignId',
					header: 'Campaign ID',
					linkTo: ['campaign'],
				}),
			},
			{
				...new AggregationColumnConfig({
					name: 'advertiser',
					accessor: 'advertiser',
					linkTo: ['advertiser_id'],
					header: 'Advertiser Name',
					cell: advertiserNameCell,
				}),
			},
			{
				...new AggregationColumnConfig({
					name: 'advertiser_id',
					accessor: 'advertiserId',
					linkTo: ['advertiser'],
					header: 'Advertiser ID',
				}),
			},
			{
				...new DataColumnConfig({
					name: 'domain',
					accessor: 'domain',
					header: 'Domain',
					alwaysHidden: true,
					searchable: false,
				}),
			},
			{ ...new MetricsPercentageColumnConfig({ name: 'cpc', accessor: 'cpc', header: 'CPC' }) },
			{ ...new MetricsPercentageColumnConfig({ name: 'cpm', accessor: 'cpm', header: 'CPM' }) },
			{ ...new MetricsColumnConfig({ name: 'auctions', accessor: 'auctions', header: 'Auctions' }) },
			{ ...new MetricsColumnConfig({ name: 'wins', accessor: 'wins', header: 'Wins' }) },
			{ ...new MetricsColumnConfig({ name: 'impressions', accessor: 'impressions', header: 'Impressions' }) },
			{ ...new MetricsColumnConfig({ name: 'clicks', accessor: 'clicks', header: 'Clicks' }) },
			{ ...new MetricsColumnConfig({ name: 'conversions', accessor: 'conversions', header: 'Conversions' }) },
			{ ...new MetricsCurrencyColumnConfig({ name: 'conversionsValue', accessor: 'conversionsValue', header: 'Conv. Value' }) },
			{ ...new MetricsCurrencyColumnConfig({ name: 'cost', accessor: 'cost', header: 'Cost' }) },
			{ ...new MetricsPercentageColumnConfig({ name: 'winRate', accessor: 'winRate', header: 'Win rate' }) },
			{ ...new MetricsPercentageColumnConfig({ name: 'ctr', accessor: 'ctr', header: 'CTR' }) },
			{ ...new MetricsPercentageColumnConfig({ name: 'roas', accessor: 'roas', header: 'ROAS' }) },
			{ ...new MetricsCurrencyColumnConfig({ name: 'avgBidFloor', accessor: 'avgBidFloor', header: 'Avg. bid floor' }) },
			{ ...new MetricsCurrencyColumnConfig({ name: 'avgBidToWin', accessor: 'avgBidToWin', header: 'Avg. bid to win' }) },
			{ ...new MetricsCurrencyColumnConfig({ name: 'maxBidFloor', accessor: 'maxBidFloor', header: 'Max bid floor' }) },
			{ ...new MetricsCurrencyColumnConfig({ name: 'maxBidToWin', accessor: 'maxBidToWin', header: 'Max bid to win' }) },
			{ ...new MetricsCurrencyColumnConfig({ name: 'minBidFloor', accessor: 'minBidFloor', header: 'Min bid floor' }) },
			{ ...new MetricsCurrencyColumnConfig({ name: 'minBidToWin', accessor: 'minBidToWin', header: 'Min bid to win' }) },
			{ ...new MetricsCurrencyColumnConfig({ name: 'optCost', accessor: 'optCost', header: 'Opt. cost' }) },
		]
	);

	constructor(settingsStore: SettingsStore) {
		makeAutoObservable(this);
		this.settingsStore = settingsStore;
		this.createDefaultFilters();
	}

	createDefaultFilters() {
		Object.values(DomainFiltersStoreKeys).map((key: DomainFiltersStoreKeys) => {
			if (!this.settingsStore.getPages().get(key)) {
				this.settingsStore.getPages().set(key, { tableStore: new TableStore() });
			}
			const domainsFilterByKey = new FilterStore({
				key,
				currentFilters: undefined,
				filterPrototypesByProp,
			});
			this.settingsStore.getPages().get(key)?.tableStore.setFiltersStore(key, domainsFilterByKey);
		});
	}

	scoresGroupBy = [
		'campaign_id',
		'campaign_name',
		'creative_group_id',
		'creative_group_name',
		'format',
		'scorer_class',
		'wins',
		'sales',
		'score',
		'created_by',
		'created_at',
		'updated_at',
	] as PerformancesColumnsSnakeCase[];

	pretargetingStatusGroupBy = ['id', 'name', 'billing_id', 'type', 'mode'] as PerformancesColumnsSnakeCase[];
	campaignStatusGroupBy = ['campaign_id', 'campaign_name', 'domain', 'campaign_status', 'list_type'] as PerformancesColumnsSnakeCase[];
	historyGroupBy = [
		'id',
		'domain_status',
		'description',
		'billing_id',
		'billing_name',
		'created_by',
		'created_at',
	] as PerformancesColumnsSnakeCase[];
}
