import { CampaignStore } from './campaigns/campaign.store';
import { AdvertiserStore } from './advertisers/advertiser.store';
import { PreferenceStore } from './preferences/preference.store';
import { CreativeStore } from './creatives/creative.store';
import { CampaignGroupsStore } from './campaign-groups/campaign-groups.store';
import { CreativeGroupsStore } from './creative-groups/creative-groups.store';
import { PerformanceResponse } from '../models/performance.model';
import { PerformanceApi } from '../apis/performance.api';
import { TestStore } from './tests/test.store';
import { DealStore } from './deals/deal.store';
import { IPerformanceOptions } from '../hooks/apis/use-reports-list';
import { JobStore } from './jobs/job.store';
import { NotificationsStore } from '@monorepo/controlled/src/stores/notifications.store';
import { AuthStore } from '@monorepo/controlled/src/stores/auth.store';
import { FormStore } from '@monorepo/controlled/src/stores/form.store';
import { DateStore } from '@monorepo/controlled/src/stores/date.store';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { ModalsStore } from '@monorepo/controlled/src/stores/modals.store';
import { ToastsStore } from '@monorepo/controlled/src/stores/toasts.store';
import { SidebarStore } from '@monorepo/controlled/src/stores/sidebar.store';
import { TableStore } from '@monorepo/controlled/src/stores/table.store';
import { SettingsStore } from '@monorepo/controlled/src/stores/settings.store';
import { TooltipsStore } from '@monorepo/controlled/src/stores/tooltips.store';
import { UserStore } from './users/user.store';
import { CidsStore } from './tools/cids/cids.store';
import { CampaignSegmentationStore } from './tools/campaign-segmentation/campaign-segmentation.store';
import { ReportStore } from './report.store';
import { DomainsStore } from './domains/domain.store';
import { BidderStore } from './bidder/bidder.store';
import { AccountsStore } from './accounts/accounts.store';

export interface IAdminxStore {
	// Data Stores
	campaignStore: CampaignStore;
	advertiserStore: AdvertiserStore;
	preferenceStore: PreferenceStore;
	creativeStore: CreativeStore;
	campaignGroupsStore: CampaignGroupsStore;
	creativeGroupsStore: CreativeGroupsStore;
	performanceStore: HttpStore<IPerformanceOptions, PerformanceResponse>;
	reportStore: ReportStore;
	testStore: TestStore;
	dealStore: DealStore;
	jobStore: JobStore;
	cidsStore: CidsStore;
	accountsStore: AccountsStore;
	campaignSegmentsStore: CampaignSegmentationStore;
	domainsStore: DomainsStore;

	// Functionality Stores
	settingsStore: SettingsStore;
	authStore: AuthStore;
	userStore: UserStore;
	sidebarStore: SidebarStore;
	formStore: FormStore;
	toastsStore: ToastsStore;
	modalsStore: ModalsStore;
	tableStore: TableStore;
	dateStore: DateStore;
	notificationsStore: NotificationsStore;
	platform: 'adminx';
	tooltipsStore: TooltipsStore;
	bidderStore: BidderStore;
}

export class AdminxStore implements IAdminxStore {
	public advertiserStore: AdvertiserStore;
	public authStore: AuthStore;
	public campaignGroupsStore: CampaignGroupsStore;
	public campaignStore: CampaignStore;
	public creativeGroupsStore: CreativeGroupsStore;
	public creativeStore: CreativeStore;
	public dateStore: DateStore;
	public dealStore: DealStore;
	public formStore: FormStore;
	public jobStore: JobStore;
	public cidsStore: CidsStore;
	public accountsStore: AccountsStore;
	public campaignSegmentsStore: CampaignSegmentationStore;
	public modalsStore: ModalsStore;
	public notificationsStore: NotificationsStore;
	public performanceStore: HttpStore<IPerformanceOptions, PerformanceResponse>;
	public reportStore: ReportStore;
	public platform = 'adminx' as const;
	public preferenceStore: PreferenceStore;
	public sidebarStore: SidebarStore;
	public tableStore: TableStore;
	public testStore: TestStore;
	public toastsStore: ToastsStore;
	public userStore: UserStore;
	public settingsStore: SettingsStore;
	public tooltipsStore: TooltipsStore;
	public domainsStore: DomainsStore;
	public bidderStore: BidderStore;

	constructor() {
		this.settingsStore = new SettingsStore();
		this.formStore = new FormStore();
		this.authStore = new AuthStore();
		this.userStore = new UserStore();
		this.sidebarStore = new SidebarStore();
		this.advertiserStore = new AdvertiserStore(this.settingsStore);
		this.campaignStore = new CampaignStore(this.settingsStore);
		this.preferenceStore = new PreferenceStore(this.settingsStore);
		this.toastsStore = new ToastsStore();
		this.modalsStore = new ModalsStore();
		this.tableStore = new TableStore();
		this.creativeStore = new CreativeStore(this.settingsStore);
		this.campaignGroupsStore = new CampaignGroupsStore(this.settingsStore);
		this.creativeGroupsStore = new CreativeGroupsStore(this.settingsStore);
		this.dateStore = new DateStore();
		this.testStore = new TestStore(this.settingsStore);
		this.dealStore = new DealStore(this.settingsStore);
		this.jobStore = new JobStore(this.settingsStore);
		this.cidsStore = new CidsStore();
		this.accountsStore = new AccountsStore();
		this.campaignSegmentsStore = new CampaignSegmentationStore();
		this.notificationsStore = new NotificationsStore();
		this.performanceStore = new HttpStore<IPerformanceOptions, PerformanceResponse>({
			httpFunc: PerformanceApi.performance,
			model: PerformanceResponse,
		});
		this.reportStore = new ReportStore(this.settingsStore);
		this.tooltipsStore = new TooltipsStore();
		this.domainsStore = new DomainsStore(this.settingsStore);
		this.bidderStore = new BidderStore(this.settingsStore);
	}
}
