import { observer } from 'mobx-react';
import { FC } from 'react';
import routes from '../../../modules/routes/index.routes';
import { Page, Pagebar, PagebarTitle, PagebarActions } from '@monorepo/base/src/components/page/page';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useList } from '../../../modules/hooks/tools/use-list';
import { ControlledTable } from '@monorepo/controlled/src/components/table-wrapper/table-wrapper';
import { CreativeModel } from '../../../modules/models/creative.model';
import { useCreativeActions } from '../../../modules/hooks/actions/creatives.actions';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { PerformancesColumnsSnakeCase } from '../../../modules/hooks/apis/use-reports-list';
import { useMergeWithPerformance } from '../../../modules/hooks/tools/use-merge-with-performance';
import { usePerformanceEntity } from '../../../modules/hooks/apis/use-performance-entity';
import { useCreativeHeaders } from '../../../modules/hooks/theaders/creatives.headers';
import { IAdminxStore } from '../../../modules/stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useRoute } from '../../../modules/hooks/tools/use-route';

const pageBarDebugProps = { dataAttrs: [new DataAttribute('id', 'creatives_pagebar')] };
const tableDebugProps = { dataAttrs: [new DataAttribute('id', 'creatives_table')] };

export const Creatives: FC = observer(() => {
	const { creativeStore } = useStores<IAdminxStore>();
	const { SelectedActions, FiltersActions, SegmentActions, EntityActions, IndexActions } = useCreativeActions();
	const { currentRouteWithoutSpecialChars } = useRoute();

	const performanceStore = creativeStore.getPerformance();

	useList<CreativeModel>(creativeStore.getListStore());
	usePerformanceEntity(performanceStore);

	const { data, summary } = useMergeWithPerformance<CreativeModel>({
		performanceStore,
		data: creativeStore.getListStore().getList(),
		key: PerformancesColumnsSnakeCase.CreativeId,
		attachExtraParams(row) {
			return {
				brand: row.brand,
				name: row.brand,
				description: row.description,
				resource_url: row.resource_url,
				resource_type: row.resource_type,
				adx_network_status: row.adx_network_status,
				adx_deals_status: row.adx_deals_status,
				size: row.size,
				default_dest_url: row.default_dest_url,
				creative_group: row.creative_group,
			};
		},
	});

	const { columns } = useCreativeHeaders({ EntityActions, summary });
	return (
		<Page>
			<Pagebar debugProps={pageBarDebugProps}>
				<PagebarTitle>Creatives</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
				</PagebarActions>
			</Pagebar>
			<ControlledTable
				currentRoute={currentRouteWithoutSpecialChars}
				createRoute={routes.creatives.create()}
				columns={columns}
				debugProps={tableDebugProps}
				isLoading={creativeStore.getListStore().getIsLoading()}
				data={data}
				isLoadingPerformance={performanceStore.getIsLoading()}
				isSummary={true}>
				{() => {
					return {
						IndexActions,
						FiltersActions,
						SelectedActions,
						SegmentActions,
					};
				}}
			</ControlledTable>
		</Page>
	);
});
