import { makeAutoObservable } from 'mobx';
import ReportStore from './report.store';
import { ColumnConfig, FilterOption, ITableState, ReportParams, ReportSortOrder } from './report.types';
import { OnChangeFn, PaginationState, SortingState, VisibilityState } from '@tanstack/react-table';

class TableState<T> implements ITableState<T> {
	columnsConfig: ColumnConfig<T>[];
	pagination: PaginationState;
	sorting: SortingState;
	filters: FilterOption[];
	columnVisibility: VisibilityState;

	constructor({
		columnsConfig,
		pagination = { pageIndex: 0, pageSize: 20 },
		sorting = [],
		filters = [],
		columnVisibility = {},
	}: ITableState<T>) {
		this.columnsConfig = columnsConfig;
		this.pagination = pagination;
		this.sorting = sorting;
		this.filters = filters;
		this.columnVisibility = columnVisibility;
		makeAutoObservable(this);
	}
}

interface TableSettings {
	hideFooter?: boolean;
}

export class ReportTableStore<T> {
	private _reportStore: ReportStore<T>;
	private _columns: ColumnConfig<T>[];
	private _tableState: TableState<T>;
	private _tableSettings: TableSettings;

	constructor(reportParams: ReportParams, columns: ColumnConfig<T>[], tableSettings: TableSettings = {}) {
		this._reportStore = new ReportStore<T>(reportParams);
		this._columns = columns;
		this._tableState = new TableState({
			columnsConfig: columns,
			pagination: {
				pageIndex: 0,
				pageSize: 20,
			},
			sorting: reportParams.sortBy
				? [
						{
							id: reportParams.sortBy,
							desc: reportParams.sortOrder === ReportSortOrder.Desc,
						},
				  ]
				: [],
			filters: [],
			columnVisibility: columns.reduce((acc, column) => {
				if (column.alwaysHidden || !column.visible) {
					acc[column.accessor] = false;
				} else {
					acc[column.accessor] = true;
				}
				return acc;
			}, {} as VisibilityState),
		});
		this._tableSettings = tableSettings;
		makeAutoObservable(this);
	}
	updateTableState(newTableState: Partial<ITableState<T>>) {
		this.tableState = { ...this.tableState, ...newTableState };
	}

	async fetchReport(reportParams: Partial<ReportParams>, appendData = false) {
		await this.reportStore.fetchReport({ params: reportParams, appendData });
	}

	onSortingChange: OnChangeFn<SortingState> = sorting => {
		const newSorting = typeof sorting === 'function' ? sorting(this.tableState.sorting) : sorting;
		if (this.reportStore.getReportTotalRows() > (this.reportStore.getReportData?.() ?? []).length) {
			const column = this.columns.find(col => col.accessor === newSorting[0]?.id);
			if (newSorting.length > 0 && column) {
				this.fetchReport({ sortBy: column.name, sortOrder: newSorting[0].desc ? ReportSortOrder.Desc : ReportSortOrder.Asc }).then(
					() => {
						this.updateTableState({ sorting: newSorting });
					}
				);
			} else {
				this.fetchReport({
					sortBy: this.reportStore.defaultReportParams.sortBy,
					sortOrder: this.reportStore.defaultReportParams.sortOrder,
				}).then(() => {
					this.updateTableState({ sorting: newSorting });
				});
				return;
			}
		} else {
			this.updateTableState({ sorting: newSorting });
		}
	};

	onColumnVisibilityChange: OnChangeFn<VisibilityState> = columnVis => {
		const columnVisibility = typeof columnVis === 'function' ? columnVis(this.tableState.columnVisibility) : columnVis;
		const newGroupBys: string[] = [];
		this.columns.forEach(col => {
			const columnsLinkedTo = col.linkTo || [];
			if (
				col.alwaysVisible ||
				col.alwaysHidden ||
				col.type === 'data' ||
				(columnVisibility[col.accessor] && col.type !== 'metrics')
			) {
				newGroupBys.push(col.name);
				newGroupBys.push(...columnsLinkedTo);
			}
		});
		const groupBys = Array.from(new Set(newGroupBys));
		if (groupBys.length !== this.reportStore.reportParams.groupBys.length) {
			this.fetchReport({ groupBys });
		}

		this.updateTableState({ columnVisibility });
	};

	onPaginationChange: OnChangeFn<PaginationState> = pagination => {
		const newPagination = typeof pagination === 'function' ? pagination(this.tableState.pagination) : pagination;
		this.updateTableState({ pagination: newPagination });
		const newStart = newPagination.pageIndex * newPagination.pageSize;
		const newLimit = newPagination.pageSize;
		const oldLimit = this.reportStore.reportParams.start + this.reportStore.reportParams.limit;
		if (newStart >= oldLimit || newLimit > oldLimit) {
			this.fetchReport(
				{
					start: newStart,
					limit: Math.max(newPagination.pageSize, this.reportStore.reportParams.limit),
				},
				true
			);
		}
	};

	toggleColumnVisibility(columnAccessor: string) {
		const columnVisibility = this.tableState.columnVisibility;
		columnVisibility[columnAccessor] = !columnVisibility[columnAccessor];
		this.updateTableState({ columnVisibility });
		const column = this.columns.find(column => column.accessor === columnAccessor);
		if (column?.type === 'aggregation') {
			if (column.linkTo?.some(linkTo => this.tableState.columnVisibility[linkTo])) {
				return;
			}
			const groupBys: string[] = [];
			this.columns.forEach(col => {
				if (
					col.alwaysVisible ||
					col.alwaysHidden ||
					col.type === 'data' ||
					(columnVisibility[col.accessor] && col.type !== 'metrics')
				) {
					groupBys.push(col.name);
					groupBys.push(...(col.linkTo ?? []));
				}
			});
			this.fetchReport({
				groupBys: Array.from(new Set(groupBys)),
			});
		}
	}

	get reportStore() {
		return this._reportStore;
	}

	set reportStore(value: ReportStore<T>) {
		this._reportStore = value;
	}

	get columns() {
		return this._columns;
	}

	set columns(value: ColumnConfig<T>[]) {
		this._columns = value;
	}

	get tableState() {
		return this._tableState;
	}

	set tableState(value: TableState<T>) {
		this._tableState = value;
	}

	get tableSettings() {
		return this._tableSettings;
	}

	set tableSettings(value: TableSettings) {
		this._tableSettings = value;
	}
}
