import { CellContext } from '@tanstack/react-table';
import { IDomainCampaignPerformance } from '../../../../../models/domain.models';
import { TableLink } from '@monorepo/base/src/components/table/table-link/table-link';
import routes from '../../../../../routes/index.routes';

export const advertiserNameCell = ({ row }: CellContext<IDomainCampaignPerformance, unknown>) => {
	return <TableLink to={routes.advertisers.view(row.getValue('advertiserId'))}>{row.getValue('advertiser')}</TableLink>;
};
export const campaignNameCell = ({ row }: CellContext<IDomainCampaignPerformance, unknown>) => {
	return <TableLink to={routes.campaigns.view(row.getValue('campaignId'))}>{row.getValue('campaign')}</TableLink>;
};
