import { observer } from 'mobx-react';
import { FC, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { ControlledTable } from '@monorepo/controlled/src/components/table-wrapper/table-wrapper';
import { Page, Pagebar, PagebarTitle, PagebarActions } from '@monorepo/base/src/components/page/page';
import {
	IPerformanceOptionsDateSegments,
	IPerformanceOptionsSortBy,
	IPerformanceOptionsSortOrder,
	PerformancesColumnsSnakeCase,
	groupByMapper,
} from '../../../modules/hooks/apis/use-reports-list';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../modules/stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useRoute } from '../../../modules/hooks/tools/use-route';
import { usePageSettings } from '@monorepo/tools/src/lib/hooks/tools/use-page-settings';
import { useReportActions } from '../../../modules/hooks/actions/report.actions';
import { useReportsList } from '../../../modules/hooks/apis/use-reports-list';
import { useReportHeaders } from '../../../modules/hooks/theaders/report.headers';
import { useDownload } from '@monorepo/tools/src/lib/hooks/tools/use-download';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import dayjs from 'dayjs';
import { DispatchLogsTypes } from '@monorepo/controlled/src/hooks/use-logs';
import { PerformanceApi } from '../../../modules/apis/performance.api';
import { Card } from '@monorepo/base/src/components/card/card';
import { Chart } from '@monorepo/base/src/components/chart/chart';
import { SpacerY } from '@monorepo/base/src/components/spacer/spacer';
import { useMetrics } from '../../../modules/hooks/tools/use-metrics';
import { availablePerformanceToMetrics } from '../../../modules/models/performance.model';

export const Reports: FC = observer(() => {
	const { reportStore, toastsStore } = useStores<IAdminxStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();
	const columnVisibility = usePageSettings().tableStore?.getColumnVisibility();
	const reportList = reportStore.getReportList();
	const metricsStore = reportStore.getMetrics();
	const [isLoadingCsv, setIsLoadingCsv] = useState<boolean>(false);
	const defaultSortBy = usePageSettings().tableStore?.getSortingBy();
	const groupByColumns = Object.keys(columnVisibility || {})
		.map((col: string) => {
			if (typeof col === 'string' && columnVisibility && columnVisibility[col]) {
				return groupByMapper[col];
			}
		})
		.filter(val => !!val) as PerformancesColumnsSnakeCase[];

	const { fetchParams, fetchReports } = useReportsList(reportList, {
		groupBys: groupByColumns,
		reportName: 'adx',
		submit: false,
		sortBy: defaultSortBy?.[0]?.id || IPerformanceOptionsSortBy.Wins,
		sortOrder: defaultSortBy?.[0]?.id ? IPerformanceOptionsSortOrder.Desc : IPerformanceOptionsSortOrder.Asc,
		dateSegment: groupByColumns.includes(PerformancesColumnsSnakeCase.Dt)
			? IPerformanceOptionsDateSegments.Daily
			: IPerformanceOptionsDateSegments.None,
	});

	useReportsList(metricsStore, {
		groupBys: [PerformancesColumnsSnakeCase.Dt],
		reportName: 'adx',
		dateSegment: IPerformanceOptionsDateSegments.Daily,
		submit: false,
	});

	const data = reportList.getData()?.data;

	const { columns } = useReportHeaders({ summary: reportList.getData()?.footer });
	const { FiltersActions } = useReportActions();
	const { metrics, xLabels } = useMetrics(metricsStore.getData());

	const onDownloadReportFile = () => {
		const fileName = `adminx report ${dayjs(fetchParams.periodStart).format('YYYY/MM/DD')} - ${dayjs(fetchParams.periodEnd).format(
			'YYYY/MM/DD'
		)}`;
		const totalRows = data?.length || 0;
		if (totalRows <= 0) {
			toastsStore.addToast({
				msg: 'Cannot export empty file.',
				type: DispatchLogsTypes.Info,
			});
		} else {
			setIsLoadingCsv(true);
			useDownload({
				httpStore: new HttpStore<undefined, File | null>({
					httpFunc: () => PerformanceApi.downloadCsv({ ...fetchParams, limit: 100000 }).finally(() => setIsLoadingCsv(false)),
				}),
				fileName,
				extension: 'csv',
			});
		}
	};

	return (
		<Page>
			<Pagebar>
				<PagebarTitle>Dynamic Reports</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
				</PagebarActions>
			</Pagebar>
			<Card full debugProps={{ dataAttrs: [new DataAttribute('id', 'chart')] }}>
				<Chart
					isLoading={metricsStore.getIsLoading()}
					metrics={metrics}
					labels={xLabels}
					legendOptions={Object.keys(availablePerformanceToMetrics)}
				/>
			</Card>
			<SpacerY y={2} />
			<ControlledTable
				currentRoute={currentRouteWithoutSpecialChars}
				columns={columns}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'reports_table')] }}
				onExportToCsv={() => {
					onDownloadReportFile();
				}}
				data={data || []}
				isLoading={reportList.getIsLoading()}
				fetchReports={fetchReports}
				defaultSortBy={defaultSortBy}
				isGlobalFilter={false}
				isLoadingCsv={isLoadingCsv}
				isSummary={true}>
				{() => {
					return {
						FiltersActions,
					};
				}}
			</ControlledTable>
			<Outlet />
		</Page>
	);
});
