import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../stores';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { useEffect } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { IDomainScore } from '../../../models/domain.models';
import { IMergedHeadersReturn } from '../index.headers';
import { TableLink } from '@monorepo/base/src/components/table/table-link/table-link';
import routes from '../../../routes/index.routes';

const columnHelper = createColumnHelper<IDomainScore>();

export const useDomainScoreHeaders = (): IMergedHeadersReturn<IDomainScore> => {
	const { settingsStore, sidebarStore } = useStores<IAdminxStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();
	const tableStore = settingsStore.pages.get(currentRouteWithoutSpecialChars)?.tableStore;

	useEffect(() => {
		if (tableStore && Object.keys(tableStore.getColumnVisibility()).length === 0) {
			tableStore?.setDefaultColumnVisibility({
				campaignId: false,
				campaignName: true,
				creativeGroupId: false,
				creativeGroupName: true,
				format: true,
				scorerClass: true,
				wins: true,
				sales: true,
				score: true,
				createdBy: true,
				createdAt: true,
				updatedAt: true,
			});
			tableStore?.setColumnVisibility(tableStore.getDefaultColumnVisibility());
		}
	}, [tableStore]);

	return {
		columns: [
			columnHelper.accessor('campaignId', {
				header: 'Campaign ID',
			}),
			columnHelper.accessor('campaignName', {
				header: 'Campaign Name',
				cell: info => {
					const { row } = info;
					return (
						<TableLink
							to={routes.campaigns.view(row.getValue('campaignId'))}
							toEdit={routes.campaigns.edit(row.getValue('campaignId'))}
							onClick={() => sidebarStore.setSidebarOpen(false)}>
							{row.getValue('campaignName')}
						</TableLink>
					);
				},
			}),
			columnHelper.accessor('creativeGroupId', {
				header: 'Creative Group Id',
			}),
			columnHelper.accessor('creativeGroupName', {
				header: 'Creative Group',
				cell: info => {
					const { row } = info;
					return (
						<TableLink to={routes.creativeGroups.creatives(row.getValue('creativeGroupId'))}>
							{row.getValue('creativeGroupName')}
						</TableLink>
					);
				},
			}),
			columnHelper.accessor('format', {
				header: 'Creative Format',
			}),
			columnHelper.accessor('score', {
				header: 'Score',
			}),
			columnHelper.accessor('scorerClass', {
				header: 'Scorer Class',
			}),
			columnHelper.accessor('wins', {
				header: 'Wins',
			}),
			columnHelper.accessor('sales', {
				header: 'Sales',
			}),
			columnHelper.accessor('createdBy', {
				header: 'Created By',
			}),
			columnHelper.accessor('createdAt', {
				header: 'Created At',
			}),
			columnHelper.accessor('updatedAt', {
				header: 'Updated At',
			}),
		],
	};
};
