import { Card } from '@monorepo/base/src/components/card/card';
import { observer } from 'mobx-react';
import styles from './bidder-targeting.module.scss';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../../stores';
import { CampaignTargetingLinkChoicePolicy } from '../../../../models/bidder-campaign/bidder-campaign.types';
import { Toggle } from '@monorepo/base/src/components/form/toggle/toggle';
import { Statuses } from '../../../../enums/status';
import { sentenceCase } from 'change-case';
import { Spacer } from '@monorepo/base/src/components/spacer/spacer';
import {
	BidderCampaignTargetingLinkCidModel,
	BidderCampaignTargetingLinkModel,
} from '../../../../models/bidder-campaign/bidder-campaign-targeting-link.model';
import { Dropdown } from '@monorepo/base/src/components/dropdown/dropdown';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { Textarea } from '@monorepo/base/src/components/form/textarea/textarea';
import { BidderCampaignTargetingModel } from '../../../../models/bidder-campaign/bidder-campaign-targeting.model';

export const TargetingLinkCid = observer(({ cid }: { cid: BidderCampaignTargetingLinkCidModel }) => {
	return (
		<div className={styles.inputsWrapper}>
			<Input
				className={`${styles.inputWithHint}`}
				value={`${cid.cid}`}
				placeholder={'cid'}
				title={'CID'}
				onValue={value => {
					cid.cid = parseInt(value) || 0;
				}}
			/>
			<Input
				className={`${styles.inputWithHint}`}
				value={`${cid.weight}`}
				placeholder={'Weight'}
				title={'Weight'}
				onValue={value => {
					cid.weight = parseInt(value) || 0;
				}}
			/>
		</div>
	);
});

interface ITargetingLinkProps {
	targeting: BidderCampaignTargetingModel;
	targetingLink: BidderCampaignTargetingLinkModel;
	policy: CampaignTargetingLinkChoicePolicy;
}

export const TargetingLink = observer(({ targeting, targetingLink, policy }: ITargetingLinkProps) => {
	const { bidderStore } = useStores<IAdminxStore>();
	const campaignCrudStore = bidderStore.getCrud();
	const activePublishers = campaignCrudStore.getData().campaign.campaign_publisher_lists?.white_list;

	const onSelectPublisher = (selectedPublisher: Set<string> | undefined) => {
		const publisherName = selectedPublisher?.values().next().value;
		targetingLink.pid = activePublishers?.find(p => p.publisher_name === publisherName)?.pid;
	};

	const renderTargetingLinkToggle = () => {
		return (
			<Toggle
				label={targetingLink.status === Statuses.Active ? sentenceCase(Statuses.Active) : sentenceCase(Statuses.Paused)}
				onChange={status => {
					targetingLink.status = status ? Statuses.Active : Statuses.Paused;
				}}
				defaultValue={targetingLink.status === Statuses.Active}
			/>
		);
	};

	return (
		<Card title={`Targeting Link - (${targetingLink.yid || 'no YID'})`} titleChildren={renderTargetingLinkToggle()} collapsible>
			{policy === CampaignTargetingLinkChoicePolicy.PerPublisher && (
				<Dropdown
					required={true}
					defaultOption={activePublishers?.find(p => p.pid === targetingLink.pid?.toString())?.publisher_name}
					label={'Select a Publisher'}
					options={activePublishers?.map(i => i.publisher_name) || []}
					onSelect={onSelectPublisher}
				/>
			)}
			<Spacer />
			<Textarea
				value={targetingLink.dest_link}
				placeholder={'Destination Link'}
				onValue={value => {
					targetingLink.dest_link = value;
				}}
			/>
			<Spacer />
			<Toggle
				label={'Encode URL'}
				onChange={encodeUrl => {
					targetingLink.encode_url = encodeUrl;
				}}
				defaultValue={targetingLink.encode_url}
			/>
			<Input
				className={`${styles.inputWithHint}`}
				value={targetingLink.yid}
				placeholder={'YID'}
				onValue={value => {
					targetingLink.yid = value;
				}}
			/>
			<div className={styles.inputsWrapper}>
				{policy === CampaignTargetingLinkChoicePolicy.ByWeight && (
					<Input
						className={`${styles.inputWithHint}`}
						value={`${targetingLink.weight || 0}`}
						title={'Weight'}
						placeholder={'Weight'}
						type="number"
						onValue={value => {
							targetingLink.weight = parseFloat(value || '0');
						}}
					/>
				)}
				{policy !== CampaignTargetingLinkChoicePolicy.ByWeight && (
					<Input
						className={`${styles.inputWithHint}`}
						value={`${targetingLink.max_conversions || 0}`}
						title={'Max Conversions'}
						placeholder={'Max Conversions'}
						type="text"
						onValue={value => {
							targetingLink.max_conversions = parseInt(value || '0');
						}}
					/>
				)}
			</div>
			<Input
				className={`${styles.inputWithHint}`}
				value={targetingLink.rh_oid}
				placeholder="Offer id"
				onValue={v => {
					targetingLink.rh_oid = v;
					if (!targeting.id) {
						targeting.rh_offer_id = v;
					}
				}}
			/>
			<Input
				className={`${styles.inputWithHint}`}
				value={targetingLink.rh_pid}
				placeholder="RH_publisher id"
				onValue={v => {
					targetingLink.rh_pid = v;
					if (!targeting.id) {
						targeting.rh_publisher_id = v;
					}
				}}
			/>
			<Spacer />
			<Card title={`CID's - (${targetingLink.cids?.length ?? 0})`} collapsible>
				{targetingLink.cids?.map(cid => (
					<TargetingLinkCid key={cid.id} cid={cid} />
				))}
				<Spacer />
				<div className={styles.createButtonWrapper}>
					<Icon size={'1.5rem'} className={styles.addIconButton} isMFP onClick={targetingLink.addNewCid}>
						plus
					</Icon>
				</div>
			</Card>
		</Card>
	);
});
