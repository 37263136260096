import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { currencyFormat, percents } from '@monorepo/tools/src/lib/utils/number';
import { createColumnHelper } from '@tanstack/react-table';
import { useEffect } from 'react';
import { IPerformanceItem, PerformanceModel } from '../../models/performance.model';
import { IAdminxStore } from '../../stores';
import { IEntityHeaders, IMergedHeadersReturn } from './index.headers';
import { useWithPerformanceHeaders } from './with-performance.headers';

const columnHelper = createColumnHelper<IPerformanceItem>();

export const useReportHeaders = (props: IEntityHeaders = {}): IMergedHeadersReturn<PerformanceModel> => {
	const { settingsStore } = useStores<IAdminxStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();
	const tableStore = settingsStore.pages.get(currentRouteWithoutSpecialChars)?.tableStore;
	const mergedPerforamnceHeaders = useWithPerformanceHeaders({ summaryItem: props.summary }).columns;

	useEffect(() => {
		if (tableStore && Object.keys(tableStore.getColumnVisibility()).length === 0) {
			tableStore.setDefaultColumnVisibility({
				avgBidFloor: false,
				avgBidToWin: false,
				minBidFloor: false,
				maxBidFloor: false,
				maxBidToWin: false,
				optCost: false,
				optGain: false,
				gain: false,
				campaignId: true,
				campaign: true,
				strategyId: false,
				campaignGroupId: false,
				campaignGroup: false,
				creativeId: false,
				creativeBrandName: false,
				creativeSize: false,
				creativeGroupDesign: false,
				date: false,
				advertiserId: true,
				advertiser: true,
				testId: false,
				testName: false,
				testGroupName: false,
				page: false,
				domain: false,
				bidKey: false,
				publisherId: false,
				browserName: false,
				osName: false,
				geo: false,
				state: false,
				city: false,
				auctionType: false,
				preferredDealId: false,
				preferredDealName: false,
				clicks: true,
				auctions: true,
				wins: true,
				conversions: true,
				cost: true,
				conversionValue: true, // this is revenue
				winRate: true,
				roas: true,
				cvr: true,
				ctr: true,
			});
			tableStore.setColumnVisibility(tableStore.getDefaultColumnVisibility());

			if (tableStore.getSortingBy().length === 0) {
				tableStore.setSortingBy([
					{
						id: 'wins',
						desc: true,
					},
				]);
			}
		}
	}, [tableStore]);

	const performanceHeaders = {
		columns: [
			columnHelper.accessor('campaignId', {
				header: 'Campaign ID',
				cell: ({ row }) => <span>{row.getValue('campaignId') ?? '-'}</span>,
			}),
			columnHelper.accessor('campaign', {
				header: 'Campaign',
				cell: ({ row }) => <span>{row.getValue('campaign') ?? '-'}</span>,
			}),
			columnHelper.accessor('strategyId', {
				header: 'Strategy ID',
				cell: ({ row }) => <span>{row.getValue('strategyId') ?? '-'}</span>,
			}),
			columnHelper.accessor('campaignGroupId', {
				header: 'Campaign Group ID',
				cell: ({ row }) => <span>{row.getValue('campaignGroupId') ?? '-'}</span>,
			}),
			columnHelper.accessor('campaignGroup', {
				header: 'Campaign Group Name',
				cell: ({ row }) => <span>{row.getValue('campaignGroup') ?? '-'}</span>,
			}),
			columnHelper.accessor('creativeId', {
				header: 'Creative ID',
				cell: ({ row }) => <span>{row.getValue('creativeId') ?? '-'}</span>,
			}),
			columnHelper.accessor('creativeBrandName', {
				header: 'Creative Brand Name',
				cell: ({ row }) => <span>{row.getValue('creativeBrandName') ?? '-'}</span>,
			}),
			columnHelper.accessor('creativeSize', {
				header: 'Creative Size',
				cell: ({ row }) => <span>{row.getValue('creativeSize') ?? '-'}</span>,
			}),
			columnHelper.accessor('creativeGroupDesign', {
				header: 'Creative Group Design',
				cell: ({ row }) => <span>{row.getValue('creativeGroupDesign') ?? '-'}</span>,
			}),
			columnHelper.accessor('advertiserId', {
				header: 'Advertiser ID',
				cell: ({ row }) => <span>{row.getValue('advertiserId') ?? '-'}</span>,
			}),
			columnHelper.accessor('advertiser', {
				header: 'Advertiser Name',
				cell: ({ row }) => <span>{row.getValue('advertiser') ?? '-'}</span>,
			}),
			columnHelper.accessor('testId', {
				header: 'Test ID',
				cell: ({ row }) => <span>{row.getValue('testId') ?? '-'}</span>,
			}),
			columnHelper.accessor('testName', {
				header: 'Test Name',
				cell: ({ row }) => <span>{row.getValue('testName') ?? '-'}</span>,
			}),
			columnHelper.accessor('testGroupName', {
				header: 'Test Group Name',
				cell: ({ row }) => <span>{row.getValue('testGroupName') ?? '-'}</span>,
			}),
			columnHelper.accessor('page', {
				header: 'Page',
				cell: ({ row }) => <span>{row.getValue('page') ?? '-'}</span>,
			}),
			columnHelper.accessor('domain', {
				header: 'Domain',
				cell: ({ row }) => <span>{row.getValue('domain') ?? '-'}</span>,
			}),
			columnHelper.accessor('bidKey', {
				header: 'Bid Key',
				cell: ({ row }) => <span>{row.getValue('bidKey') ?? '-'}</span>,
			}),
			columnHelper.accessor('publisherId', {
				header: 'Publisher ID',
				cell: ({ row }) => <span>{row.getValue('publisherId') ?? '-'}</span>,
			}),
			columnHelper.accessor('browserName', {
				header: 'Browser Name',
				cell: ({ row }) => <span>{row.getValue('browserName') ?? '-'}</span>,
			}),
			columnHelper.accessor('osName', {
				header: 'OS Name',
				cell: ({ row }) => <span>{row.getValue('osName') ?? '-'}</span>,
			}),
			columnHelper.accessor('geo', {
				header: 'Geo',
				cell: ({ row }) => <span>{row.getValue('geo') ?? '-'}</span>,
			}),
			columnHelper.accessor('state', {
				header: 'State',
				cell: ({ row }) => <span>{row.getValue('state') ?? '-'}</span>,
			}),
			columnHelper.accessor('city', {
				header: 'City',
				cell: ({ row }) => <span>{row.getValue('city') ?? '-'}</span>,
			}),
			columnHelper.accessor('auctionType', {
				header: 'Auction Type',
				cell: ({ row }) => <span>{row.getValue('auctionType') ?? '-'}</span>,
			}),
			columnHelper.accessor('preferredDealId', {
				header: 'Preferred Deal ID',
				cell: ({ row }) => <span>{row.getValue('preferredDealId') ?? '-'}</span>,
			}),
			columnHelper.accessor('preferredDealName', {
				header: 'Preferred Deal Name',
				cell: ({ row }) => <span>{row.getValue('preferredDealName') ?? '-'}</span>,
			}),
			columnHelper.accessor('avgBidFloor', {
				header: 'Avg. Bid Floor',
				cell: ({ row }) => <span>{row.getValue('avgBidFloor') ?? '-'}</span>,
			}),
			columnHelper.accessor('avgBidToWin', {
				header: 'Avg. Bid to Win',
				cell: ({ row }) => <span>{row.getValue('avgBidToWin') ?? '-'}</span>,
			}),
			columnHelper.accessor('cpa', {
				header: 'CPA',
				cell: ({ row }) => <span>{currencyFormat(row.getValue('cpa'), true, { decimalLen: 4 }) ?? '0'}</span>,
			}),
			columnHelper.accessor('cpc', {
				header: 'CPC',
				cell: ({ row }) => <span>{currencyFormat(row.getValue('cpc'), true, { decimalLen: 4 }) ?? '0'}</span>,
			}),
			columnHelper.accessor('cpm', {
				header: 'CPM',
				cell: ({ row }) => <span>{percents(row.getValue('cpm')) ?? '0%'}</span>,
			}),
			columnHelper.accessor('minBidFloor', {
				header: 'Min Bid Floor',
				cell: ({ row }) => <span>{row.getValue('minBidFloor') ?? '-'}</span>,
			}),
			columnHelper.accessor('maxBidFloor', {
				header: 'Max Bid Floor',
				cell: ({ row }) => <span>{row.getValue('maxBidFloor') ?? '-'}</span>,
			}),
			columnHelper.accessor('maxBidToWin', {
				header: 'Max Bid To Win',
				cell: ({ row }) => <span>{row.getValue('maxBidToWin') ?? '-'}</span>,
			}),
			...mergedPerforamnceHeaders,
		],
	};

	return performanceHeaders;
};
