import { CreateCreativeGroups } from '../../pages/creative-groups/create/create-creative-group.page';
import { EditCreativeGroups } from '../../pages/creative-groups/edit/edit-creative-group.page';
import { CreativeGroups } from '../../pages/creative-groups/index/creative-groups.page';
import { CreativeGroupView } from '../../pages/creative-groups/view/creative-group-view.page';
import { CreateCreative } from '../../pages/creatives/create/create-creative.page';
import routes from '../routes/index.routes';
import { Soon } from '@monorepo/base/src/components/soon/soon';
import { CreativeGroupCreatives } from '../../pages/creative-groups/view/creatives/creatives.creative-group';

export const CreativeGroupsRouter = [
	{
		path: routes.creativeGroups.index(),
		element: <CreativeGroups />,
		children: [
			{
				path: routes.creativeGroups.create(),
				element: <CreateCreativeGroups />,
			},
			{
				path: routes.creativeGroups.edit(':creativeGroupId'),
				element: <EditCreativeGroups />,
			},
		],
	},
	{
		path: routes.creativeGroups.createCreative(':creativeGroupId'),
		element: <CreateCreative />,
	},
	{
		element: <CreativeGroupView />,
		children: [
			{
				path: routes.creativeGroups.view(':creativeGroupId'),
				element: <Soon />,
			},
			{
				path: routes.creativeGroups.creatives(':creativeGroupId'),
				element: <CreativeGroupCreatives />,
			},
		],
	},
];
