import { Card } from '@monorepo/base/src/components/card/card';
import { BidderWhitelistData, BidderWhitelistType } from './bidder-whitelist-blacklist.consts';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../../stores';
import { Fragment, useEffect, useState } from 'react';
import { Spacer } from '@monorepo/base/src/components/spacer/spacer';
import { ButtonGroup } from '@monorepo/base/src/components/buttons/group/button-group';
import { WhiteBlackLists } from '../../../../enums/white-black-list-enum';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { numberFormatterWithCommas } from '@monorepo/tools/src/lib/utils/number';
import styles from './bidder-whitelist-blacklist.module.scss';
import { IndexLinerButton } from '@monorepo/base/src/components/table/action-liner/liner-buttons';
import { SecondaryText } from '@monorepo/base/src/components/buttons/buttons';
import { BarLoader } from '@monorepo/base/src/components/bar-loader/bar-loader';
import { Upload } from '@monorepo/base/src/components/form/upload/upload';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { useDownload } from '@monorepo/tools/src/lib/hooks/tools/use-download';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { DispatchLogsTypes } from '@monorepo/controlled/src/hooks/use-logs';
import { BidderWhitelistApi } from '../../../../apis/bidder-whitelist-blacklist.api';
import { Tooltip } from '@monorepo/controlled/src/components/tooltip/tooltip/tooltip';

interface Props {
	type: BidderWhitelistType;
}

const defaultCount = { countBlacklist: 0, countWhitelist: 0 };
const theme = getTheme();

export const BidderWhitelistBlacklist = observer(({ type }: Props) => {
	const [isDownloading, setIsDownloading] = useState(false);
	const [isUploading, setIsUploading] = useState(false);

	const {
		toastsStore,
		bidderStore: { whitelistBlacklist },
	} = useStores<IAdminxStore>();
	const { bidderCampaignId } = useParams() as { bidderCampaignId: string };

	useEffect(() => {
		whitelistBlacklist.fetch(type, bidderCampaignId);
	}, []);

	const handleDownload = async () => {
		setIsDownloading(true);

		useDownload({
			httpStore: new HttpStore<undefined, File | null>({
				httpFunc: () =>
					BidderWhitelistApi.get(BidderWhitelistData[type].apiUrls.get, bidderCampaignId) as unknown as Promise<File | null>,
			}),
			fileName: `${bidderCampaignId}_Bidder_${type}_${new Date()
				.toISOString()
				.substring(0, 19)
				.replace('T', ' ')
				.replace(/:/g, '-')}`,
			extension: BidderWhitelistData[type].extension,
		})
			.catch(err => {
				toastsStore.addToast({ msg: 'Download Failed, try again later.', type: DispatchLogsTypes.Error });
				console.log(err);
			})
			.finally(() => {
				setIsDownloading(false);
			});
	};

	const handleFileUpload = async (fileList: File | FileList) => {
		const file = Array.isArray(fileList) ? fileList[0] : fileList;

		setIsUploading(true);

		const formData = new FormData();
		formData.append('campaignId', bidderCampaignId);
		formData.append('file', file);

		whitelistBlacklist
			.upload(type, formData)
			.then(() => {
				whitelistBlacklist.fetch(type, bidderCampaignId, true);
				toastsStore.addToast({ msg: 'Upload Success', type: DispatchLogsTypes.Success });
			})
			.catch(() => {
				toastsStore.addToast({ msg: 'Upload Failed', type: DispatchLogsTypes.Error });
			})
			.finally(() => {
				setIsUploading(false);
			});
	};

	const data = whitelistBlacklist.getData()?.[type];

	const { countBlacklist, countWhitelist } =
		data?.reduce(
			(acc, i) => {
				// ISPs only have "blacklist_isp" value
				if (type === BidderWhitelistType.ISPs || i.list_type === WhiteBlackLists.Blacklist) {
					acc.countBlacklist += 1;
				}

				if (i.list_type === WhiteBlackLists.Whitelist) {
					acc.countWhitelist += 1;
				}

				return acc;
			},
			{ ...defaultCount }
		) || defaultCount;

	const getButtonTooltip = (action: string) => (bidderCampaignId ? '' : `File can be ${action}ed only after campaign create`);

	return (
		<Card
			className={styles.container}
			title={BidderWhitelistData[type].title}
			titleChildren={
				!bidderCampaignId || whitelistBlacklist.getIsLoading(type) ? undefined : (
					<Fragment>
						Blacklisted: {numberFormatterWithCommas.format(countBlacklist)}
						&nbsp;/&nbsp; Whitelisted: {numberFormatterWithCommas.format(countWhitelist)}
					</Fragment>
				)
			}>
			<Spacer />
			<ButtonGroup className={styles.buttonGroup} disabled={!bidderCampaignId}>
				<IndexLinerButton>
					<Tooltip content={getButtonTooltip('download')}>
						<div>
							<SecondaryText
								className={styles.actions}
								icon={'download-04'}
								onClick={handleDownload}
								iconColor={theme.actionLinerActionIconsColor}
								textColor={theme.gray500}
								disabled={isDownloading || !bidderCampaignId}
								iconSize={'18px'}>
								Download {BidderWhitelistData[type].title}
							</SecondaryText>
							<BarLoader is={isDownloading} />
						</div>
					</Tooltip>
				</IndexLinerButton>
				<IndexLinerButton>
					<Tooltip content={getButtonTooltip('upload')}>
						<div>
							<SecondaryText
								className={styles.actions}
								icon={'upload-04'}
								iconColor={theme.actionLinerActionIconsColor}
								textColor={theme.gray500}
								disabled={isUploading || !bidderCampaignId}
								iconSize={'18px'}>
								<Upload accept={`.${BidderWhitelistData[type].extension}`} onUpload={handleFileUpload}>
									Upload {BidderWhitelistData[type].title}
								</Upload>
							</SecondaryText>
							<BarLoader is={isUploading} />
						</div>
					</Tooltip>
				</IndexLinerButton>
			</ButtonGroup>
		</Card>
	);
});
