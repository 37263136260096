import { DataSheetGrid as ReactDataSheetGrid, Column, keyColumn, checkboxColumn, textColumn, intColumn } from 'react-datasheet-grid';

import 'react-datasheet-grid/dist/style.css';

type Props<T> = {
	columns: Array<Column & { id: string; title: string; type: DataSheetGridColType }>;
	rows: T[];
	height?: number;
	onChange: (v: T[]) => void;
};

export enum DataSheetGridColType {
	Checkbox = 'checkbox',
	Text = 'text',
	Int = 'int',
}

const dataSheetGridColTypeToColComp = {
	[DataSheetGridColType.Checkbox]: checkboxColumn,
	[DataSheetGridColType.Text]: textColumn,
	[DataSheetGridColType.Int]: intColumn,
};

export const DataSheetGrid = <T,>({ columns, rows, height, onChange }: Props<T>) => {
	const handleChange = (v: T[]) => {
		onChange?.(v);
	};

	return (
		<ReactDataSheetGrid<T>
			value={rows}
			height={height}
			onChange={handleChange}
			columns={
				columns.map(({ id, type, ...props }) => ({
					...keyColumn(id, dataSheetGridColTypeToColComp[type]),
					...props,
				})) as Column[]
			}
			addRowsComponent={false}
		/>
	);
};
