import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../stores';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { useEffect } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { IDomainPertargetingStatus } from '../../../models/domain.models';
import { IMergedHeadersReturn } from '../index.headers';

const columnHelper = createColumnHelper<IDomainPertargetingStatus>();

export const useDomainPretargetingStatusHeaders = (): IMergedHeadersReturn<IDomainPertargetingStatus> => {
	const { settingsStore } = useStores<IAdminxStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();
	const tableStore = settingsStore.pages.get(currentRouteWithoutSpecialChars)?.tableStore;

	useEffect(() => {
		if (tableStore && Object.keys(tableStore.getColumnVisibility()).length === 0) {
			tableStore?.setDefaultColumnVisibility({
				id: true,
				billingId: true,
				type: true,
				mode: true,
				name: true,
			});
			tableStore?.setColumnVisibility(tableStore.getDefaultColumnVisibility());
		}
	}, [tableStore]);

	return {
		columns: [
			columnHelper.accessor('id', {
				header: 'ID',
			}),
			columnHelper.accessor('billingId', {
				header: 'Billing ID',
			}),
			columnHelper.accessor('name', {
				header: 'Name',
			}),
			columnHelper.accessor('type', {
				header: 'Type',
			}),
			columnHelper.accessor('mode', {
				header: 'Mode',
			}),
		],
	};
};
