import { observer } from 'mobx-react';
import { Fragment, useState } from 'react';
import { CreativeSelectionModes } from '../../../enums/creative-selection-modes';
import { useCampaignInputOptions } from '../../../hooks/apis/use-campaign-input-options';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { CampaignModel, ICampaign } from '../../../models/campaign.model';
import { StrategyModel } from '../../../models/strategy.model';
import { TestGroupModel } from '../../../models/test-group.model';
import { SecondaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { ButtonGroup } from '@monorepo/base/src/components/buttons/group/button-group';
import { GButton } from '@monorepo/base/src/components/buttons/group/button/g-button';
import { Card } from '@monorepo/base/src/components/card/card';
import { Dropdown, option } from '@monorepo/base/src/components/dropdown/dropdown';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { Textarea } from '@monorepo/base/src/components/form/textarea/textarea';
import { Toggle } from '@monorepo/base/src/components/form/toggle/toggle';
import { Spacer } from '@monorepo/base/src/components/spacer/spacer';
import styles from './test-form.module.scss';
import { IAdminxStore } from '../../../stores';
import { Statuses } from '../../../enums/status';
import { ScorePicker } from './components';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { IPerformanceOptionsSortBy, PerformancesColumnsSnakeCase, useReportsList } from '../../../hooks/apis/use-reports-list';

export const TestForm = observer(() => {
	const { testStore, campaignStore } = useStores<IAdminxStore>();
	const inputOptions = campaignStore.getInputOptions();
	const testCrudStore = testStore.getCrud();
	const formStore = testCrudStore.getFormStore();
	const test = testCrudStore.getData();
	const testGroups: undefined | TestGroupModel[] = test.getTestGroups();
	const campaigns = campaignStore
		.getListStore()
		.getData()
		?.getData()
		.map(campaign => new CampaignModel(campaign as ICampaign));
	const campaignOptions = campaigns?.map(campaign => campaign.getName() || '');
	const [isRemoved, setIndexToRemove] = useState<number | undefined>(undefined);
	const campaign = test.getCampaign();
	const strategies = inputOptions.getStrategies();
	const campaignsCSM = campaign?.getCreativeSelectionMode();

	useCampaignInputOptions();
	useReportsList(campaignStore.getListStore(), {
		groupBys: [PerformancesColumnsSnakeCase.Id, PerformancesColumnsSnakeCase.Name],
		reportName: 'adxCampaigns',
		sortBy: IPerformanceOptionsSortBy.Id,
	});

	const onCampaignSelect = (options: Set<option>) => {
		const optionsArr = Array.from(options);
		const selectedCampaign = optionsArr.map(option => campaigns?.find(campaign => option === campaign.getName()));
		if (selectedCampaign[0]) {
			test.setCampaign(selectedCampaign[0]);
		}
	};

	const onStrategySelect = (testGroup: TestGroupModel, strategyName: option | undefined) => {
		const strategy = strategies.find(s => `${s.name} (${s.id})` === strategyName);

		if (strategy) {
			testGroup.setStrategy(new StrategyModel(strategy));
		}
	};

	const removeTestGroupAnimation = (index: number) => {
		setIndexToRemove(index);
		setTimeout(() => {
			test.removeTestGroup(index);
			setIndexToRemove(undefined);
		}, 200);
	};

	return (
		<div className={styles.wrapper}>
			<Card title={'Test Information'} icon={'paint'} debugProps={{ dataAttrs: [new DataAttribute('id', 'test_form_main')] }}>
				<Fragment>
					<Input
						value={test.getName()}
						placeholder={'Test Name'}
						onValue={value => {
							test.setName(value);
							formStore.clearError('name');
						}}
						required={true}
						error={formStore.getErrors().get('name')?.getMsg()}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'test_name')] }}
					/>
					<Dropdown
						label={'Select Campaign'}
						options={campaignOptions || []}
						defaultOption={campaign?.name}
						onSelect={(campaign: Set<option> | undefined) => campaign && onCampaignSelect(campaign)}
						autocomplete={true}
						autocompletePlaceholder={'Search Campaign'}
						required={true}
						error={formStore.getErrors().get('campaign')?.getMsg()}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'test_campaign')] }}
					/>
					<Textarea
						value={test.getDescription()}
						placeholder={'Description'}
						onValue={value => {
							test.setDescription(value);
							formStore.clearError('description');
						}}
						error={formStore.getErrors().get('description')?.getMsg()}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'test_description')] }}
					/>
					<Input
						type={'number'}
						value={test?.getPercentage()?.toString()}
						placeholder={'Percentage'}
						onValue={value => {
							test.setPercentage(parseInt(value));
							formStore.clearError('name');
						}}
						required={true}
						error={formStore.getErrors().get('percentage')?.getMsg()}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'test_percentage')] }}
					/>
					<div className={`${styles.testGroupWrapper} ${styles.normalGroup}`}>
						<div className={`${styles.testTitleWrapper}`}>
							<span className={styles.title}>Group Normal</span>
						</div>
						<Dropdown
							label={'Select Cache & bid Key Generator'}
							defaultOption={campaign?.getCacheKeyGenerator()}
							options={campaignStore.getInputOptions().getKeyGenerators()}
							error={formStore.getErrors().get('cache_key_generator')?.getMsg()}
							disabled={true}
							onSelect={(cacheKeyGenerator: Set<option> | undefined) => {
								campaign?.setCacheKeyGenerator(cacheKeyGenerator?.values().next().value);
								formStore.clearError('cache_key_generator');
							}}
							debugProps={{ dataAttrs: [new DataAttribute('id', 'test_cache_key_generator')] }}
						/>
						<Dropdown
							required={true}
							defaultOption={campaign?.getStrategy()?.name}
							label={'Select Strategy'}
							disabled={true}
							error={formStore.getErrors().get('strategy')?.getMsg()}
							options={[]}
							onSelect={() => null}
							debugProps={{ dataAttrs: [new DataAttribute('id', 'test_strategy')] }}
						/>
						<Spacer />
						{/* todo-score-picker */}
						{/*<ScorePicker*/}
						{/*	inputOptions={inputOptions}*/}
						{/*	design={campaign?.design_score_picker}*/}
						{/*	format={campaign?.format_score_picker}*/}
						{/*	setDesign={(v) => {*/}
						{/*		if (campaign) campaign.design_score_picker = v;*/}
						{/*	}}*/}
						{/*	setFormat={(v) => {*/}
						{/*		if (campaign) campaign.format_score_picker = v;*/}
						{/*	}}*/}
						{/*/>*/}
						<Spacer />
						<ButtonGroup
							title={'Creative Selection Modes'}
							defaultValues={campaignsCSM ? new Set([campaignsCSM]) : undefined}
							error={formStore.getErrors().get('creative_selection_mode')?.getMsg()}
							onChange={setValues => {
								campaign?.setCreativeSelectionMode(setValues.values().next().value);
								formStore.clearError('creative_selection_mode');
							}}>
							<GButton
								disabled={true}
								icon={'slash-circle-01'}
								text={CreativeSelectionModes.Off}
								debugProps={{ dataAttrs: [new DataAttribute('id', 'test_selection_mode_off')] }}
								value={CreativeSelectionModes.Off}
							/>
							<GButton
								disabled={true}
								icon={'edit-05'}
								text={CreativeSelectionModes.Format}
								value={CreativeSelectionModes.Format}
								debugProps={{ dataAttrs: [new DataAttribute('id', 'test_selection_mode_format')] }}
							/>
							<GButton
								disabled={true}
								icon={'design-services'}
								text={CreativeSelectionModes.Design}
								value={CreativeSelectionModes.Design}
								debugProps={{ dataAttrs: [new DataAttribute('id', 'test_selection_mode_design')] }}
							/>
							<GButton
								disabled={true}
								icon={'circle'}
								text={CreativeSelectionModes.Full}
								value={CreativeSelectionModes.Full}
								debugProps={{ dataAttrs: [new DataAttribute('id', 'test_selection_mode_full')] }}
							/>
						</ButtonGroup>
						<Textarea
							value={campaign?.getDestinationUrl()}
							placeholder={'Destination URL'}
							onValue={value => {
								campaign?.setDestinationUrl(value);
								formStore.clearError('dest_url');
							}}
							error={formStore.getErrors().get('dest_url')?.getMsg()}
							disabled={true}
							debugProps={{ dataAttrs: [new DataAttribute('id', 'test_dest_url')] }}
						/>
					</div>
				</Fragment>
			</Card>

			{testGroups?.map((testGroup, i) => {
				const strategy = testGroup.getStrategy();
				const creativeSelectionMode = testGroup.getCreativeSelectionMode();

				const groupNumber = i + 1;
				return (
					<Card
						className={`${styles.testGroupWrapper} ${isRemoved === i ? styles.removeAnimation : ''}`}
						key={i}
						debugProps={{ dataAttrs: [new DataAttribute('id', `test_group_${groupNumber}_form`)] }}>
						<div className={`${styles.testTitleWrapper}`}>
							<span className={styles.title}>Test Group {i + 1}</span>
							<Toggle
								classNames={styles.status}
								label={testGroup.getStatus() === Statuses.Active ? Statuses.Active : Statuses.Paused}
								onChange={status => testGroup.setStatus(status ? Statuses.Active : Statuses.Paused)}
								defaultValue={testGroup.getStatus() === Statuses.Active}
								debugProps={{ dataAttrs: [new DataAttribute('id', `test_group_${groupNumber}_status`)] }}
							/>
							{testGroups.length > 1 ? (
								<SecondaryButton
									className={styles.closeBtn}
									onClick={() => removeTestGroupAnimation(i)}
									isRotateIcon={false}
									icon={'x-close'}
									debugProps={{ dataAttrs: [new DataAttribute('id', `test_group_${groupNumber}_close_button`)] }}
								/>
							) : null}
						</div>
						<Input
							value={testGroup.getName()}
							placeholder={'Group Name'}
							onValue={value => {
								testGroup.setName(value);
								formStore.clearError('name');
							}}
							required={true}
							error={formStore.getErrors().get('name')?.getMsg()}
							debugProps={{ dataAttrs: [new DataAttribute('id', `test_group_${groupNumber}_name`)] }}
						/>
						<Input
							value={testGroup.getCreativeGroup()}
							placeholder={'Creative Group'}
							onValue={value => {
								testGroup.setCreativeGroup(value);
								formStore.clearError('creative_group');
							}}
							error={formStore.getErrors().get('creative_group')?.getMsg()}
							debugProps={{ dataAttrs: [new DataAttribute('id', `test_creative_group_${groupNumber}`)] }}
						/>
						<Dropdown
							label={'Select Cache & bid Key Generator'}
							defaultOption={testGroup.getCacheKeyGenerator()}
							options={campaignStore.getInputOptions().getKeyGenerators()}
							onSelect={(cacheKeyGenerator: Set<option> | undefined) => {
								testGroup.setCacheKeyGenerator(cacheKeyGenerator?.values().next().value);
								formStore.clearError('cache_key_generator');
							}}
							error={formStore.getErrors().get('cache_key_generator')?.getMsg()}
							debugProps={{ dataAttrs: [new DataAttribute('id', `test_cache_key_generator_${groupNumber}`)] }}
						/>
						<Dropdown
							defaultOption={strategy ? `${strategy?.name} (${strategy?.id})` : ''}
							label={'Select Strategy'}
							options={strategies.map(s => `${s.name} (${s.id})`)}
							onSelect={(strategyName: Set<option> | undefined) =>
								onStrategySelect(testGroup, strategyName?.values().next().value)
							}
							error={formStore.getErrors().get('strategy')?.getMsg()}
							debugProps={{ dataAttrs: [new DataAttribute('id', `test_group_${groupNumber}_strategy`)] }}
						/>
						<Spacer />
						<ScorePicker
							inputOptions={inputOptions}
							design={testGroup.design_score_picker}
							format={testGroup.format_score_picker}
							setDesign={v => (testGroup.design_score_picker = v)}
							setFormat={v => (testGroup.format_score_picker = v)}
						/>
						<Input
							type={'number'}
							value={testGroup?.getWeight()?.toString()}
							placeholder={'Weight'}
							onValue={value => testGroup?.setWeight(parseInt(value))}
							min={1}
							debugProps={{ dataAttrs: [new DataAttribute('id', `test_group_${groupNumber}_weight`)] }}
						/>
						<Input
							type={'number'}
							value={testGroup?.getSecondBidFactor()?.toString()}
							placeholder={'Second Bid Factor'}
							onValue={value => testGroup?.setSecondBidFactor(parseFloat(value))}
							min={1}
							debugProps={{ dataAttrs: [new DataAttribute('id', `test_group_${groupNumber}_second_bid_factor`)] }}
						/>
						<Spacer />
						<ButtonGroup
							title={'Creative Selection Modes'}
							defaultValues={creativeSelectionMode ? new Set([creativeSelectionMode]) : undefined}
							error={formStore.getErrors().get('creative_selection_mode')?.getMsg()}
							onChange={setValues => {
								testGroup.setCreativeSelectionMode(setValues.values().next().value);
								formStore.clearError('creative_selection_mode');
							}}>
							<GButton
								icon={'slash-circle-01'}
								text={CreativeSelectionModes.Off}
								value={CreativeSelectionModes.Off}
								debugProps={{
									dataAttrs: [new DataAttribute('id', `test_group_${groupNumber}_creative_selection_mode_off_button`)],
								}}
							/>
							<GButton
								icon={'edit-05'}
								text={CreativeSelectionModes.Format}
								value={CreativeSelectionModes.Format}
								debugProps={{
									dataAttrs: [new DataAttribute('id', `test_group_${groupNumber}_creative_selection_mode_format_button`)],
								}}
							/>
							<GButton
								icon={'design-services'}
								text={CreativeSelectionModes.Design}
								value={CreativeSelectionModes.Design}
								debugProps={{
									dataAttrs: [new DataAttribute('id', `test_group_${groupNumber}_creative_selection_mode_design_button`)],
								}}
							/>
							<GButton
								icon={'circle'}
								text={CreativeSelectionModes.Full}
								value={CreativeSelectionModes.Full}
								debugProps={{
									dataAttrs: [new DataAttribute('id', `test_group_${groupNumber}_creative_selection_mode_full_button`)],
								}}
							/>
						</ButtonGroup>
						<Textarea
							value={testGroup.getDestinationUrl()}
							placeholder={'Destination URL'}
							onValue={value => {
								testGroup.setDestinationUrl(value);
								formStore.clearError('dest_url');
							}}
							error={formStore.getErrors().get('dest_url')?.getMsg()}
							rows={7}
							debugProps={{ dataAttrs: [new DataAttribute('id', `test_group_${groupNumber}_dest_url_input`)] }}
						/>
					</Card>
				);
			})}
		</div>
	);
});
