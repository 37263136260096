import { observer } from 'mobx-react';
import classNames from 'classnames';
import styles from './bidder-domain-list.module.scss';
import { IAdminxStore } from '../../../../stores';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { Card } from '@monorepo/base/src/components/card/card';
import { IBidderCampaignPublisherDomainStatusLimitsGrid } from '../../../../models/bidder-campaign/bidder-campaign-domain-limits.model';
import { DataSheetGrid, DataSheetGridColType } from '../../../datasheet-grid';
import { Spinner } from '@monorepo/base/src/components/spinner/spinner';
import React from 'react';
import { Flex } from '@monorepo/base/src/components/flex/flex';
import { PrimaryButton } from '@monorepo/base/src/components/buttons/buttons';

export const BidderDomainList = observer(() => {
	const { bidderStore } = useStores<IAdminxStore>();
	const campaignCrudStore = bidderStore.getCrud();
	const campaign = campaignCrudStore.getData().campaign;
	const publishers = bidderStore.publishersList.getData()?.publishers;

	const list = campaign.get_campaign_publisher_domain_status_limits_grid(publishers);
	const isAllEnabled = list?.every(i => i.enabled);
	const isLoading = campaignCrudStore.isLoading || bidderStore.publishersList.isLoading || !list?.length;

	const handleSelectAll = () => {
		campaign.campaign_publisher_domain_status_limits_grid = list?.map(i => ({
			...i,
			enabled: !isAllEnabled,
		}));
	};

	return (
		<Card
			className={classNames(styles.wrapper, isLoading && styles.loading)}
			icon={'list'}
			id={'bidderDomainLimits'}
			title={'Publisher Domain Status Limit'}>
			{isLoading ? (
				<Flex justifyContent="center" height="100%">
					<Spinner size={'40px'} className={styles.spinner} />
				</Flex>
			) : (
				<Flex gap="12px" flexDirection="column" alignItems="flex-start" className={styles.gridWrapper}>
					<PrimaryButton width="110px" onClick={handleSelectAll}>
						{isAllEnabled ? 'Disable' : 'Enable'} All
					</PrimaryButton>

					<DataSheetGrid<IBidderCampaignPublisherDomainStatusLimitsGrid>
						height={630}
						columns={[
							{ id: 'enabled', title: '', type: DataSheetGridColType.Checkbox, minWidth: 50, maxWidth: 50 },
							{ id: 'name', title: 'Publisher Domain name', type: DataSheetGridColType.Text, minWidth: 220, disabled: true },
							{ id: 'GOOD', title: 'GOOD', type: DataSheetGridColType.Int },
							{ id: 'MEDIUM', title: 'MEDIUM', type: DataSheetGridColType.Int },
							{ id: 'NEW', title: 'NEW', type: DataSheetGridColType.Int },
							{ id: 'NEW_WITH_SALE', title: 'NEW_WITH_SALE', type: DataSheetGridColType.Int, minWidth: 150 },
							{ id: 'RANDOMTIER1', title: 'RANDOMTIER1', type: DataSheetGridColType.Int, minWidth: 150 },
							{ id: 'RANDOMTIER2', title: 'RANDOMTIER2', type: DataSheetGridColType.Int, minWidth: 150 },
							{ id: 'RT2_WITH_SALE', title: 'RT2_WITH_SALE', type: DataSheetGridColType.Int, minWidth: 150 },
						]}
						rows={list}
						onChange={data => (campaign.campaign_publisher_domain_status_limits_grid = data)}
					/>
				</Flex>
			)}
		</Card>
	);
});
