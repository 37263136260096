import { useWithPerformanceHeaders } from './with-performance.headers';
import { IEntityHeaders, IMergedHeadersReturn, renderSubRow } from './index.headers';
import { CreativeModel, ICreative } from '../../models/creative.model';
import { IPerformanceItem } from '../../models/performance.model';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { TableLink } from '@monorepo/base/src/components/table/table-link/table-link';
import routes from '../../routes/index.routes';
import { HighlighStatus, HighlightStatuses } from '@monorepo/base/src/components/highlight/highlight';
import { OverflowColumn } from '@monorepo/base/src/components/table/overflow-column/overflow-column';
import { CreativePreview } from '@monorepo/base/src/components/table/creative-preview/creative-preview';
import { Tag } from '@monorepo/base/src/components/tag/tag';
import { isSubRow } from '@monorepo/base/src/components/table/table';
import { IAdminxStore } from '../../stores';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { useEffect } from 'react';
import { QuickActionsMenu } from '@monorepo/base/src/components/table/quick-actions-menu/quick-actions-menu';
import { TableSelectionHeader, TableSelectionCell } from '@monorepo/base/src/components/table/table-selection-cell/table-selection-cell';
import { createColumnHelper } from '@tanstack/react-table';
import { TableMergeColumn } from '@monorepo/base/src/components/table/table-merge-column/table-merge-column';

const columnHelper = createColumnHelper<ICreative & IPerformanceItem>();

interface IUseCreativeHeaders extends IEntityHeaders {
	hasDetach?: boolean;
}

export const useCreativeHeaders = (props: IUseCreativeHeaders = {}): IMergedHeadersReturn<CreativeModel> => {
	const { includeColumns = {}, EntityActions, summary } = props;
	const { creative_group = true } = includeColumns;
	const { sidebarStore, settingsStore } = useStores<IAdminxStore>();
	const mergedPerforamnceHeaders = useWithPerformanceHeaders({ summaryItem: summary }).columns;
	const { currentRouteWithoutSpecialChars } = useRoute();
	const tableStore = settingsStore.pages.get(currentRouteWithoutSpecialChars)?.tableStore;

	useEffect(() => {
		if (tableStore && Object.keys(tableStore.getColumnVisibility()).length === 0) {
			tableStore.setDefaultColumnVisibility({
				impressions: false,
				description: false,
				default_dest_url: false,
				resource_url: false,
				resource_preview: true,
				resource_type: false,
				// adx_creative_status: true,
				adx_network_status: true,
				adx_deals_status: true,
				size: false,
				creativeGroupDesign: true,
				name: true,
				id: true,
				auctions: true,
				wins: true,
				clicks: true,
				conversions: true,
				cost: true,
				conversionValue: true,
				winRate: false,
				roas: true,
				ctr: true,
				lpCvr: false,
				optCost: false,
				optGain: false,
				gain: false,
			});
			tableStore?.setColumnVisibility(tableStore.getDefaultColumnVisibility());
		}
	}, [tableStore]);

	const renderHierachyColumns = () => {
		const columns = [];

		if (creative_group) {
			columns.push(
				columnHelper.accessor(row => `${row?.creative_group?.design}`, {
					id: 'creativeGroupDesign',
					header: 'Creative Group',
					cell: ({ row }) => {
						const creative = new CreativeModel(row.original);
						const creativeGroupId = creative.getCreativeGroup()?.getId();

						if (!creativeGroupId) {
							return null;
						}

						return (
							<TableLink
								to={routes.creativeGroups.creatives(creativeGroupId)}
								onClick={() => sidebarStore.setSidebarOpen(false)}>
								{row.getValue('creativeGroupDesign')}
							</TableLink>
						);
					},
				})
			);
		}

		return columns;
	};

	/**
	 * Fuck ts, it should be hash table
	 * @param status
	 * @returns
	 */
	const _mapToTagStatus = (status: string | undefined): HighlighStatus => {
		if (status === 'approved') {
			return HighlightStatuses.Success;
		}
		if (status === 'disapproved') {
			return HighlightStatuses.Error;
		}
		if (status === 'new') {
			return HighlightStatuses.New;
		}
		if (status === 'pending_review') {
			return HighlightStatuses.Pending;
		}
		return HighlightStatuses.Info;
	};

	return {
		columns: [
			columnHelper.display({
				id: 'selection',
				enableHiding: false,
				header: headerProps => <TableSelectionHeader headerProps={headerProps} />,
				cell: cellProps => {
					const { row } = cellProps;
					const creative = new CreativeModel(row.original);

					if (isSubRow(row)) {
						return <span></span>;
					}
					if (!EntityActions) {
						return null;
					}
					return (
						<TableMergeColumn>
							<TableSelectionCell cellProps={cellProps} />
							<QuickActionsMenu>
								<EntityActions entity={creative} />
							</QuickActionsMenu>
						</TableMergeColumn>
					);
				},
			}),
			columnHelper.accessor('id', {
				header: 'ID',
			}),
			columnHelper.accessor('name', {
				header: 'Brand',
				cell: info => {
					const { row } = info;
					if (isSubRow(row)) {
						return renderSubRow({ row });
					}
					return <TableLink toEdit={routes.creatives.edit(row.getValue('id'))}>{row.getValue('name')}</TableLink>;
				},
			}),
			columnHelper.accessor('description', {
				header: 'Description',
			}),
			columnHelper.accessor('resource_preview', {
				header: 'Resource Preview',
				cell: ({ row }) => <CreativePreview type={row.getValue('resource_type')} data={row.getValue('resource_url')} />,
			}),
			columnHelper.accessor('resource_url', {
				header: 'Resource URL',
				cell: ({ row }) => <TableLink to={row.getValue('resource_url')}>{row.getValue('resource_url')}</TableLink>,
			}),
			columnHelper.accessor('resource_type', {
				header: 'Resource Type',
			}),
			columnHelper.accessor('adx_network_status', {
				header: 'Network Status',
				cell: info => {
					const { row } = info;
					const creativeNetworkStatus = row.getValue('adx_network_status') as string;
					return (
						<Tag withIcon={true} type={_mapToTagStatus(creativeNetworkStatus?.toLowerCase())}>
							{creativeNetworkStatus}
						</Tag>
					);
				},
			}),
			columnHelper.accessor('adx_deals_status', {
				header: 'Deals Status',
				cell: info => {
					const { row } = info;
					const DealsStatus = row.getValue('adx_deals_status') as string;
					return (
						<Tag withIcon={true} type={_mapToTagStatus(DealsStatus?.toLowerCase())}>
							{DealsStatus}
						</Tag>
					);
				},
			}),
			columnHelper.accessor('size', {
				header: 'Size',
			}),
			columnHelper.accessor('default_dest_url', {
				header: 'Destination URL',
				cell: ({ row }) => <OverflowColumn data={row.getValue('default_dest_url')} />,
			}),
			...renderHierachyColumns(),
			...mergedPerforamnceHeaders,
		],
	};
};
