import { currencyFormatter } from '@monorepo/tools/src/lib/utils/number';
import { TableLink } from '@monorepo/base/src/components/table/table-link/table-link';
import { TableMergeColumn } from '@monorepo/base/src/components/table/table-merge-column/table-merge-column';
import { isSubRow } from '@monorepo/base/src/components/table/table';
import { Tag } from '@monorepo/base/src/components/tag/tag';
import { CampaignModel, ICampaign } from '../../models/campaign.model';
import { IPerformanceItem } from '../../models/performance.model';
import routes from '../../routes/index.routes';
import { useCampaignActions } from '../actions/campaign.actions';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IEntityHeaders, IMergedHeadersReturn, renderSubRow } from './index.headers';
import { useWithPerformanceHeaders } from './with-performance.headers';
import { IAdminxStore } from '../../stores';
import { mapToTagStatus, Status, Statuses } from '../../enums/status';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { useEffect } from 'react';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { SpacerX } from '@monorepo/base/src/components/spacer/spacer';
import { QuickActionsMenu } from '@monorepo/base/src/components/table/quick-actions-menu/quick-actions-menu';
import { TableSelectionHeader, TableSelectionCell } from '@monorepo/base/src/components/table/table-selection-cell/table-selection-cell';
import { TableStatusCell } from '../../components/table-status-cell/table-status-cell';
import { createColumnHelper } from '@tanstack/react-table';
import { TableInlineEditCell } from '../../components/table-inline-edit-cell/table-inline-edit-cell';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
// todo ISSUE-20456
// import { sortingFns } from '@tanstack/react-table';

const columnHelper = createColumnHelper<ICampaign & IPerformanceItem>();

export const useCampaignHeaders = (props: IEntityHeaders = {}): IMergedHeadersReturn<CampaignModel> => {
	const { includeColumns = {}, summary } = props;
	const { advertiser = true, campaign_group = true } = includeColumns;
	const { sidebarStore, settingsStore, campaignStore } = useStores<IAdminxStore>();
	const { EntityActions } = useCampaignActions();
	const { dispatchLog } = useLogs();
	const { columns: mergedPerforamnceHeaders } = useWithPerformanceHeaders({
		summaryItem: summary,
	});
	const { currentRouteWithoutSpecialChars } = useRoute();
	const tableStore = settingsStore.pages.get(currentRouteWithoutSpecialChars)?.tableStore;

	useEffect(() => {
		if (tableStore && Object.keys(tableStore.getColumnVisibility()).length === 0) {
			tableStore.setDefaultColumnVisibility({
				name: true,
				status: true,
				advertiserName: true,
				advertiserId: false,
				campaignGroupName: true,
				campaignGroupId: true,
				dailyBudgetLimit: true,
				id: true,
				auctions: true,
				auctionsValid: false,
				wins: true,
				clicks: true,
				conversions: true,
				cost: true,
				conversionValue: true,
				winRate: true,
				roas: true,
				ctr: true,
				lpCvr: true,
				impressions: false,
				optCost: false,
				optGain: false,
				gain: false,
				dailyBudget: true,
				spm: true,
			});
			tableStore?.setColumnVisibility(tableStore.getDefaultColumnVisibility());
		}
	}, [tableStore]);

	const renderHierachyColumns = () => {
		const columns = [];

		if (advertiser) {
			columns.push(
				columnHelper.accessor('advertiserName', {
					header: 'Advertiser',
					cell: info => {
						const { row } = info;
						if (!row.getValue('advertiserId')) {
							return <span>{row.getValue('advertiserName')}</span>;
						}
						return (
							<TableLink
								to={routes.advertisers.campaigns(row.getValue('advertiserId'))}
								onClick={() => {
									sidebarStore.setSidebarOpen(false);
								}}>
								{row.getValue('advertiserName')}
							</TableLink>
						);
					},
				})
			);
			columns.push(
				columnHelper.accessor('advertiserId', {
					header: 'Advertiser ID',
				})
			);
		}

		if (campaign_group) {
			columns.push(
				columnHelper.accessor('campaignGroupName', {
					header: 'Campaign Group',
					cell: info => {
						const { row } = info;

						if (!row.getValue('campaignGroupId')) {
							return <span>{row.getValue('campaignGroupName')}</span>;
						}
						return (
							<TableLink
								to={routes.campaignGroups.campaigns(row.getValue('campaignGroupId'))}
								onClick={() => sidebarStore.setSidebarOpen(false)}>
								{row.getValue('campaignGroupName')}
							</TableLink>
						);
					},
				})
			);
			columns.push(
				columnHelper.accessor('campaignGroupId', {
					header: 'Campaign Group ID',
				})
			);
		}

		return columns;
	};

	return {
		columns: [
			columnHelper.display({
				id: 'selection',
				enableHiding: false,
				header: headerProps => (
					<TableMergeColumn>
						<TableSelectionHeader headerProps={headerProps} />
						<SpacerX x={0.7} />
						<Icon size={'14px'} isMFP={true} color={'#c5c5c5'}>
							circle
						</Icon>
						<SpacerX x={0.7} />
					</TableMergeColumn>
				),
				cell: cellProps => {
					const { row } = cellProps;
					const campaign = new CampaignModel(row.original);
					const status = campaign.getStatus()?.trim() as Statuses;

					if (isSubRow(row)) {
						return <span></span>;
					}
					if (!EntityActions) {
						return null;
					}
					return (
						<TableMergeColumn>
							<TableSelectionCell cellProps={cellProps} />
							<SpacerX x={0.7} />
							<TableStatusCell status={status} />
							<QuickActionsMenu>
								<EntityActions entity={campaign} />
							</QuickActionsMenu>
						</TableMergeColumn>
					);
				},
			}),
			columnHelper.accessor('name', {
				header: 'Name',
				cell: info => {
					const { row } = info;
					if (isSubRow(row)) {
						return renderSubRow({ row });
					}
					return (
						<TableLink
							to={routes.campaigns.view(row.getValue('id'))}
							toEdit={routes.campaigns.edit(row.getValue('id'))}
							onClick={() => {
								sidebarStore.setSidebarOpen(false);
							}}>
							{row.getValue('name')}
						</TableLink>
					);
				},
			}),
			columnHelper.accessor('status', {
				header: 'Status',
				cell: info => {
					const { row } = info;
					if (isSubRow(row)) {
						return <span></span>;
					}
					const status = row.getValue('status') as string | undefined;
					return <Tag type={mapToTagStatus(status?.toUpperCase().trim() as Status)}>{status?.trim()}</Tag>;
				},
			}),
			columnHelper.accessor('id', {
				header: 'ID',
			}),
			columnHelper.accessor(row => row?.dailyBudget, {
				id: 'dailyBudget',
				header: 'Budget',
				enableResizing: false,
				cell: ({ row }) => {
					if (isSubRow(row)) {
						return <span></span>;
					}

					return (
						<TableInlineEditCell
							value={currencyFormatter.format(row.getValue('dailyBudget'))}
							inputValue={row.getValue('dailyBudget')}
							inputWidth={70}
							onSave={async daily_budget => {
								await campaignStore.getCrud().get(row.getValue('id'));

								await campaignStore.getCrud().edit({
									daily_budget: {
										...campaignStore.getCrud().getEditFormData().daily_budget,
										limit: +daily_budget,
									},
								});

								dispatchLog({
									msg: `Campaign ${row.getValue('name')} was successfully edited`,
									type: DispatchLogsTypes.Success,
								});

								campaignStore.getCrud().reset();
							}}
						/>
					);
				},
			}),
			columnHelper.accessor('spm', {
				header: 'SPM',
				cell: info => {
					const { row } = info;
					if (isSubRow(row)) {
						return <span></span>;
					}
					return <span>{row.getValue('spm')}</span>;
				},
			}),
			...renderHierachyColumns(),
			// todo ISSUE-20456
			// columnHelper.accessor('auctionsValid', {
			// 	header: 'Auctions Valid',
			// 	cell: ({ row }) => <span>{(row.getValue('auctionsValid') as number)?.toLocaleString() ?? '-'}</span>,
			// 	footer: () => <span>{summary?.auctionsValid?.toLocaleString()}</span>,
			// 	sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
			// 	meta: {
			// 		isPerformance: true,
			// 	},
			// }),
			...mergedPerforamnceHeaders,
		],
	};
};
