import { makeAutoObservable } from 'mobx';
import { url } from '@monorepo/tools/src/lib/types/url';
import { ResourceTypes } from '../enums/creative-resource-types';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { BannerModel } from './banner.model';
import { CampaignModel, BannerSize } from './campaign.model';
import { CreativeGroupModel } from './creative-group.model';

/**
 * The creative the creative crud page uses
 */
export interface ICreative {
	id?: id;
	brand?: string;
	description?: string;
	creative_group?: CreativeGroupModel;
	size?: string;
	htmlSizes?: string[];
	banner_sizes?: BannerSize[];
	resource_url?: string;
	resource_type?: ResourceTypes;
	resource_preview?: string;
	default_dest_url?: string;
	adx_creative_id?: string;
	// DEPRECATED
	// this.adx_creative_status = creative?.adx_creative_status;
	// adx_creative_status?: string;
	banners?: BannerModel[];
	related_campaigns?: CampaignModel[];
	bannersFormData?: FormData;
	adx_network_status?: string;
	adx_deals_status?: string;
}

/**
 * The creative the creative edit page uses
 */
export interface ICreativeEdit {
	id?: id;
	brand?: string;
	description?: string;
	creative_group?: CreativeGroupModel;
	size?: string;
	banner_sizes?: BannerSize[];
	resource_url?: string;
	resource_type?: ResourceTypes;
	default_dest_url?: string;
	adx_creative_id?: string;
	adx_creative_status?: string;
	banners?: BannerModel[];
	related_campaigns?: CampaignModel[];
}

/**
 * The creative to send to server
 */
export interface ICreativeCreateForm {
	brand?: string;
	description?: string;
	size?: string;
	banner_sizes?: BannerSize[];
	resource_url?: string;
	resource_type?: ResourceTypes;
	creative_group?: {
		id?: id;
	} | null;
	default_dest_url?: url;
}

export interface IMultiCreativeCreateForm {
	brand?: string;
	description?: string;
	size?: string;
	banner_sizes?: BannerSize[];
	resource_url?: string;
	resource_type?: ResourceTypes;
	creative_group?: {
		id?: id;
	} | null;
	default_dest_url?: url;
}

export interface ICreativeEditForm {
	id?: number | string;
	brand?: string;
	description?: string;
	size?: string;
	banner_sizes?: BannerSize[];
	resource_url?: string;
	resource_type?: ResourceTypes;
	creative_group?: {
		id?: id;
	} | null;
	default_dest_url?: url;
}

export class CreativeModel implements ICreative {
	id?: id;
	brand?: string;
	description?: string;
	creative_group?: CreativeGroupModel;
	size?: string;
	banner_sizes?: BannerSize[];
	htmlSizes?: string[];
	resource_url?: string;
	resource_type?: ResourceTypes;
	default_dest_url?: string;
	adx_creative_id?: string;
	adx_creative_status?: string;
	banners?: BannerModel[];
	fileExtension = '';
	related_campaigns?: CampaignModel[];
	bannersFormData?: FormData;
	totalBanners?: number;
	adx_network_status?: string;
	adx_deals_status?: string;

	constructor(creative?: ICreative) {
		this.ctor(creative);

		makeAutoObservable(this);
	}

	public ctor(creative?: ICreative) {
		this.id = creative?.id;
		this.brand = creative?.brand;
		this.description = creative?.description;
		this.size = creative?.size;
		this.htmlSizes = [];
		this.resource_url = creative?.resource_url;
		this.resource_type = creative?.resource_type || ResourceTypes.IMAGE;
		this.default_dest_url = creative?.default_dest_url;
		this.adx_creative_id = creative?.adx_creative_id;
		// DEPRECATED
		// this.adx_creative_status = creative?.adx_creative_status;
		this.creative_group = creative?.creative_group ? new CreativeGroupModel(creative?.creative_group) : undefined;
		this.related_campaigns = creative?.related_campaigns?.map(
			related_campaign => new CampaignModel(related_campaign) || new CampaignModel()
		);
		this.banners = creative?.banners?.map(banner => new BannerModel(banner));
		this.banner_sizes = creative?.banner_sizes;
		this.adx_network_status = creative?.adx_network_status;
		this.adx_deals_status = creative?.adx_deals_status;
	}

	public getId() {
		return this.id;
	}

	public setId(id: id) {
		this.id = id;
	}

	public getBrand() {
		return this.brand;
	}

	public setBrand(brand: string) {
		this.brand = brand;
	}

	public getDescription(): string | undefined {
		return this.description;
	}

	public setDescription(description: string): void {
		this.description = description;
	}

	public getSize(): string | undefined {
		return this.size;
	}

	public setSize(size: string): void {
		this.size = size;
	}

	public getHtmlSizes(): string[] | undefined {
		return this.htmlSizes;
	}

	public setHtmlSizes(htmlSizes: string[]): void {
		this.htmlSizes = htmlSizes;
	}

	public getResourceUrl(): string | undefined {
		return this.resource_url;
	}

	public setResourceUrl(resource_url: string | undefined): void {
		this.resource_url = resource_url;
	}

	public getResourceType(): ResourceTypes | undefined {
		return this.resource_type;
	}

	public setResourceType(resource_type: ResourceTypes): void {
		this.resource_type = resource_type;
	}

	public getDefaultDestUrl(): string | undefined {
		return this.default_dest_url;
	}

	public setDefaultDestUrl(default_dest_url: string): void {
		this.default_dest_url = default_dest_url;
	}

	public getAdxCreativeId(): string | undefined {
		return this.adx_creative_id;
	}

	public setAdxCreativeId(adx_creative_id: string): void {
		this.adx_creative_id = adx_creative_id;
	}

	// public getAdxCreativeStatus(): string | undefined {
	// 	return this.adx_creative_status;
	// }

	// public setAdxCreativeStatus(adx_creative_status: string): void {
	// 	this.adx_creative_status = adx_creative_status;
	// }

	public getAdxDealsStatus(): string | undefined {
		return this.adx_deals_status;
	}

	public setAdxDealsStatus(adx_deals_status: string): void {
		this.adx_deals_status = adx_deals_status;
	}
	public getAdxNetworkStatus(): string | undefined {
		return this.adx_network_status;
	}

	public setAdxNetworkStatus(adx_network_status: string): void {
		this.adx_network_status = adx_network_status;
	}

	public getCreativeGroup(): CreativeGroupModel | undefined {
		return this.creative_group;
	}

	public setCreativeGroup(creative_group: CreativeGroupModel) {
		this.creative_group = creative_group;
	}

	public getBanners(): BannerModel[] | undefined {
		return this.banners;
	}

	public setBanners(banners: BannerModel[]): void {
		this.banners = banners;
	}

	public getFileExtension() {
		return this.fileExtension;
	}

	public setFileExtension(fileExtension: string) {
		this.fileExtension = fileExtension;
	}

	public getCampaigns(): CampaignModel[] | undefined {
		return this.related_campaigns;
	}

	public setCampaigns(related_campaigns: CampaignModel[]) {
		this.related_campaigns = related_campaigns;
	}

	public getBannersFormData(): FormData | undefined {
		return this.bannersFormData;
	}

	public setBannersFormData(bannersFormData: FormData): void {
		this.bannersFormData = bannersFormData;
	}

	public getBannerSizes(): BannerSize[] | undefined {
		return this.banner_sizes;
	}

	public setBannerSizes(banner_sizes: BannerSize[]) {
		this.banner_sizes = banner_sizes;
	}
}
