import React, { Fragment, useRef, useState } from 'react';
import { Flex } from '@monorepo/base/src/components/flex/flex';
import { Icon, IconFonts } from '@monorepo/base/src/components/icon/icon';
import styles from './table-inline-edit-cell.module.scss';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { Spinner } from '@monorepo/base/src/components/spinner/spinner';

interface Props {
	value: string;
	inputValue?: string;
	inputWidth?: number;
	onSave: (v: string) => Promise<void>;
}

export const TableInlineEditCell = ({ value, inputValue, inputWidth, onSave }: Props) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const [isEdit, setIsEdit] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [newValue, setNewValue] = useState(inputValue || value);

	const startEdit = () => {
		setIsEdit(true);
		setTimeout(() => inputRef.current?.focus());
	};

	const exitEdit = () => setIsEdit(false);

	const save = async () => {
		setIsSaving(true);
		await onSave(newValue);
		setIsSaving(false);

		exitEdit();
	};

	return (
		<Flex gap="8px" className={styles.wrapper}>
			{isEdit ? (
				<Fragment>
					<Input
						inputWrapperClassName={styles.inputWrapper}
						style={{ width: `${inputWidth || 50}px` }}
						unstyled
						ref={inputRef}
						disabled={isSaving}
						value={newValue}
						onValue={v => setNewValue(v)}
					/>

					{isSaving ? (
						<Spinner size={'12px'} className={styles.spinner} />
					) : (
						<Flex gap="4px">
							<div className={styles.icon} onClick={save}>
								<Icon isMFP={true} size={'14px'} font={IconFonts.Filled}>
									save-01
								</Icon>
							</div>
							<div className={styles.icon} onClick={exitEdit}>
								<Icon isMFP={true} size={'16px'} font={IconFonts.Filled}>
									x-close
								</Icon>
							</div>
						</Flex>
					)}
				</Fragment>
			) : (
				<Fragment>
					<span>{value}</span>

					<div className={`tableLinkEditIcon ${styles.icon}`} onClick={startEdit}>
						<Icon isMFP={true} size={'14px'} font={IconFonts.Filled}>
							edit-02
						</Icon>
					</div>
				</Fragment>
			)}
		</Flex>
	);
};
