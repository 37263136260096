import { currencyFormat } from '@monorepo/tools/src/lib/utils/number';
import { TableLink } from '@monorepo/base/src/components/table/table-link/table-link';
import routes from '../../routes/index.routes';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { AdvertiserModel, IAdvertiser } from '../../models/advertiser.model';
import { useWithPerformanceHeaders } from './with-performance.headers';
import { Tag } from '@monorepo/base/src/components/tag/tag';
import { IEntityHeaders, IMergedHeadersReturn, renderSubRow } from './index.headers';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { isSubRow } from '@monorepo/base/src/components/table/table';
import { IPerformanceItem } from '../../models/performance.model';
import { IAdminxStore } from '../../stores';
import { mapToTagStatus, Status, Statuses } from '../../enums/status';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { useEffect } from 'react';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { SpacerX } from '@monorepo/base/src/components/spacer/spacer';
import { QuickActionsMenu } from '@monorepo/base/src/components/table/quick-actions-menu/quick-actions-menu';
import { TableSelectionHeader, TableSelectionCell } from '@monorepo/base/src/components/table/table-selection-cell/table-selection-cell';
import { TableStatusCell } from '../../components/table-status-cell/table-status-cell';
import { createColumnHelper } from '@tanstack/react-table';
import { TableMergeColumn } from '@monorepo/base/src/components/table/table-merge-column/table-merge-column';

const columnHelper = createColumnHelper<IAdvertiser & IPerformanceItem>();

export const useAdvertiserHeaders = (props: IEntityHeaders = {}): IMergedHeadersReturn<AdvertiserModel> => {
	const { onEdit, summary, EntityActions } = props;
	const { sidebarStore, settingsStore } = useStores<IAdminxStore>();
	const mergedPerforamnceHeaders = useWithPerformanceHeaders({ summaryItem: summary, withoutDateColumn: true }).columns;
	const { currentRouteWithoutSpecialChars } = useRoute();
	const tableStore = settingsStore.pages.get(currentRouteWithoutSpecialChars)?.tableStore;

	useEffect(() => {
		if (tableStore && Object.keys(tableStore.getColumnVisibility()).length === 0) {
			tableStore?.setDefaultColumnVisibility({
				name: true,
				status: true,
				id: true,
				userCapEnabled: true,
				totalBudget: true,
				auctions: true,
				wins: true,
				clicks: true,
				conversions: true,
				cost: true,
				conversionValue: true,
				winRate: true,
				roas: true,
				ctr: true,
				lpCvr: true,
				impressions: false,
				statsTotalEarn: false,
				dailyMoneyEarn: true,
				dailyMoneySpent: true,
				totalMoneyEarn: true,
				totalMoneySpent: true,
				optCost: false,
				optGain: false,
				gain: false,
			});
			tableStore?.setColumnVisibility(tableStore.getDefaultColumnVisibility());

			if (tableStore.getSortingBy().length === 0) {
				tableStore.setSortingBy([
					{
						id: 'id',
						desc: false,
					},
				]);
			}
		}
	}, [tableStore]);

	return {
		columns: [
			columnHelper.display({
				id: 'selection',
				enableHiding: false,
				header: headerProps => (
					<TableMergeColumn>
						<TableSelectionHeader headerProps={headerProps} />
						<SpacerX x={0.7} />
						<Icon size={'14px'} isMFP={true} color={'#c5c5c5'}>
							circle
						</Icon>
						<SpacerX x={0.7} />
					</TableMergeColumn>
				),
				cell: cellProps => {
					const { row } = cellProps;
					const advertiser = new AdvertiserModel(row.original);
					const status = advertiser.getStatus() as Statuses;
					if (isSubRow(row)) {
						return <span></span>;
					}
					if (!EntityActions) {
						return null;
					}
					return (
						<TableMergeColumn>
							<TableSelectionCell cellProps={cellProps} />
							<SpacerX x={0.7} />
							<TableStatusCell status={status} />
							<QuickActionsMenu>
								<EntityActions entity={advertiser} />
							</QuickActionsMenu>
						</TableMergeColumn>
					);
				},
			}),
			columnHelper.accessor('name', {
				header: 'Name',
				cell: info => {
					const { row } = info;
					if (isSubRow(row)) {
						return renderSubRow({ row });
					}
					const toEdit = (() => {
						if (onEdit) {
							return onEdit(row.getValue('id'));
						}
						return routes.advertisers.edit(row.getValue('id'));
					})();
					return (
						<TableLink
							to={routes.advertisers.campaignGroups(row.getValue('id'))}
							toEdit={toEdit}
							toEditState={{ isBlockRequest: true } as BetweenPagesStateProps}
							onClick={() => {
								if (!toEdit) {
									sidebarStore.setSidebarOpen(false);
								}
							}}>
							{row.getValue('name')}
						</TableLink>
					);
				},
			}),
			columnHelper.accessor('status', {
				header: 'Status',
				cell: ({ row }) => {
					if (isSubRow(row)) {
						return <span></span>;
					}
					const status = row.getValue('status') as string;
					return <Tag type={mapToTagStatus(status?.toUpperCase()?.trim() as Status)}>{status?.trim()}</Tag>;
				},
			}),
			columnHelper.accessor('id', {
				header: 'ID',
			}),
			columnHelper.accessor('userCapEnabled', {
				header: 'User Cap Enabled',
				cell: ({ row }) => {
					if (isSubRow(row)) {
						return <span></span>;
					}
					return <span>{row.getValue('userCapEnabled') ? 'Enabled' : 'Disabled'}</span>;
				},
			}),
			columnHelper.accessor('totalBudget', {
				header: 'Total Spent',
				cell: ({ row }) => {
					if (isSubRow(row)) {
						return <span></span>;
					}

					return (
						<span style={{ whiteSpace: 'pre' }}>
							{currencyFormat(row.original.totalMoneySpent)} / {currencyFormat(row.getValue('totalBudget')) ?? '-'}
						</span>
					);
				},
			}),
			columnHelper.accessor('totalMoneyEarn', {
				header: 'Total Money Earn',
				cell: ({ row }) => <span>{currencyFormat(row.getValue('totalMoneyEarn')) ?? '-'}</span>,
			}),
			columnHelper.accessor('dailyMoneySpent', {
				header: 'Total Daily Spent',
				cell: ({ row }) => <span>{currencyFormat(row.getValue('dailyMoneySpent')) ?? '-'}</span>,
			}),
			columnHelper.accessor('dailyMoneyEarn', {
				header: 'Total Daily Earn',
				cell: ({ row }) => <span>{currencyFormat(row.getValue('dailyMoneyEarn')) ?? '-'}</span>,
			}),
			...mergedPerforamnceHeaders,
		],
	};
};
