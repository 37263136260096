import { makeObservable, observable } from 'mobx';
import { BidderCampaignCrudStore } from './bidder-campaign-crud.store';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { BidderApi } from '../../apis/bidder.api';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { BidderInputOptionsStore } from './bidder-input-options.store';
import { BidderWhitelistBlacklistStore } from './bidder-whitelist-blacklist.store';
import { BaseListStore } from '@monorepo/controlled/src/stores/base-list.store';
import { BidderCampaignTableModel, IBidderAdvertiser } from '../../models/bidder-campaign/bidder-camapign.model';
import { bidderCampaignFilterPrototypes, filterPrototypesByProp } from '../../components/filters-menu/filters';
import { FiltersMap, FilterStore } from '@monorepo/controlled/src/stores/filter.store';
import { FilterModel } from '@monorepo/controlled/src/models/filter.model';
import { Statuses } from '../../enums/status';
import { StringActions } from '@monorepo/tools/src/lib/utils/string';
import { TableStore } from '@monorepo/controlled/src/stores/table.store';
import { SettingsStore } from '@monorepo/controlled/src/stores/settings.store';
import { BidderCampaignGroup } from '../../models/bidder-campaign/bidder-camapign.model';

export class BidderStore {
	crud = new BidderCampaignCrudStore();
	inputOptions = new BidderInputOptionsStore();
	whitelistBlacklist = new BidderWhitelistBlacklistStore();

	campaignsList = new BaseListStore<BidderCampaignTableModel>({
		// response is { campaigns: [...] } instead of [...], handled in BaseListStore via listPath
		listFunc: BidderApi.getCampaigns as any,
		listPath: 'campaigns',
		model: BidderCampaignTableModel,
	});

	publishersList = new HttpStore<undefined, { success: boolean; publishers: { id: id; name: string }[] }>({
		httpFunc: BidderApi.getPublishers,
	});

	advertisers = new HttpStore<undefined, { success: boolean; advertisers: IBidderAdvertiser[] }>({
		httpFunc: BidderApi.getAdvertisers,
	});

	campaignGroups = new HttpStore<id, { success: boolean; campaign_groups: BidderCampaignGroup[] }>({
		httpFunc: id => BidderApi.getCampaignGroups(id),
	});

	settingsStore: SettingsStore;

	constructor(settingsStore: SettingsStore) {
		makeObservable(this, {
			crud: observable,
		});
		this.settingsStore = settingsStore;
		this.createDefaultFilters();
	}

	createDefaultFilters() {
		const prototype = bidderCampaignFilterPrototypes.find(filter => filter.MenuComponent === 'Status');
		let defaultFilters: FiltersMap = new Map();

		if (prototype) {
			defaultFilters = new Map([
				[
					1,
					new FilterModel({
						index: 1,
						label: `Status is ${Statuses.Active}`,
						action: StringActions.Equals,
						value: [Statuses.Active],
						prototype,
					}),
				],
			]);
		}

		const key = 'biddercampaigns';

		if (!this.settingsStore.getPages().get(key)) {
			this.settingsStore.getPages().set(key, { tableStore: new TableStore() });
		}
		const bidderCampaignFilterByKey = new FilterStore({
			key,
			currentFilters: defaultFilters,
			filterPrototypesByProp,
		});
		this.settingsStore.getPages().get(key)?.tableStore.setFiltersStore(key, bidderCampaignFilterByKey);
	}

	getCrud() {
		return this.crud;
	}

	getInputOptions() {
		return this.inputOptions;
	}

	getActiveAdvertisers() {
		return this.advertisers.getData()?.advertisers.filter(a => a.status === 'ACTIVE') || [];
	}

	getCampaignGroups() {
		return this.campaignGroups.getData()?.campaign_groups || [];
	}
}
