import { makeAutoObservable } from 'mobx';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { AdvertiserModel } from './advertiser.model';
export interface ICreativeGroup {
	id?: id;
	design?: string;
	advertiser?: AdvertiserModel;
	advertiserName?: string;
}

export interface ICreativeGroupCreateForm {
	id?: id;
	design?: string;
	advertiser?: { id?: id };
}

export interface ICreativeGroupEditForm {
	id?: id;
	design?: string;
	advertiser?: { id?: id };
}

export class CreativeGroupModel implements ICreativeGroup {
	id?: id;
	design?: string;
	advertiser;

	constructor(creativeGroup?: ICreativeGroup) {
		this.id = creativeGroup?.id;
		this.design = creativeGroup?.design;
		this.advertiser = creativeGroup?.advertiser ? new AdvertiserModel(creativeGroup.advertiser) : undefined;

		makeAutoObservable(this);
	}

	getId(): id | undefined {
		return this.id;
	}

	getDesign(): string | undefined {
		return this.design;
	}

	setDesign(design: string): void {
		this.design = design;
	}

	getAdvertiser(): AdvertiserModel | undefined {
		return this.advertiser;
	}

	setAdvertiser(advertiser: AdvertiserModel): void {
		this.advertiser = advertiser;
	}
}
