import { id } from '@monorepo/tools/src/lib/types/primitives';
import { Status } from '../../enums/status';
import {
	CampaignTargetingLinkChoicePolicy,
	IBidderCampaignDomains,
	IBidderCampaignDomainStatesDistribution,
	IBidderCampaignInitialBids,
	PerformancePixelType,
} from './bidder-campaign.types';
import { BidderCampaignTargetingModel, IBidderCampaignTargeting } from './bidder-campaign-targeting.model';
import { makeAutoObservable } from 'mobx';
import {
	BidderCampaignPublisherDomainStatusLimitsModel,
	IBidderCampaignPublisherDomainStatusLimits,
	IBidderCampaignPublisherDomainStatusLimitsGrid,
	IBidderCampaignPublisherLists,
} from './bidder-campaign-domain-limits.model';
import { BidderCampaignTargetingLinkCidModel, BidderCampaignTargetingLinkModel } from './bidder-campaign-targeting-link.model';

export interface IBidderCampaign {
	campaign_name?: string;
	campaign_id?: id;
	campaign_advertiser_id?: id;
	campaign_status?: Status;
	campaign_daily_budget?: number;
	campaign_max_loss_per_domain?: number;
	campaign_max_clicks_to_first_conv?: number;
	campaign_q_max_loss_per_domain?: number;
	campaign_weight?: number;
	campaign_q_max_clicks_to_first_conv?: number;
	campaign_group_id?: id;
	campaign_unique_sale?: number;
	campaign_performance_pixel_type?: PerformancePixelType;
	campaign_jm_exclusion?: number;
	campaign_targeting_link_choice_policy?: CampaignTargetingLinkChoicePolicy;
	campaign_targeting?: IBidderCampaignTargeting[];
	campaign_publisher_domain_status_limits?: IBidderCampaignPublisherDomainStatusLimits[];
	campaign_publisher_lists?: IBidderCampaignPublisherLists;
	campaign_initial_bids?: IBidderCampaignInitialBids;
	domains_states_distribution?: IBidderCampaignDomainStatesDistribution[];
	campaign_domains?: IBidderCampaignDomains;
}

export class BidderCampaignModel implements IBidderCampaign {
	private _campaign_name?: string;
	private _campaign_id?: id;
	private _campaign_advertiser_id?: id;
	private _campaign_status?: Status;
	private _campaign_daily_budget?: number;
	private _campaign_max_loss_per_domain?: number;
	private _campaign_max_clicks_to_first_conv?: number;
	private _campaign_q_max_loss_per_domain?: number;
	private _campaign_q_max_clicks_to_first_conv?: number;
	private _campaign_group_id?: id;
	private _campaign_weight?: number;
	private _campaign_unique_sale?: number;
	private _campaign_performance_pixel_type?: PerformancePixelType;
	private _campaign_jm_exclusion?: number;
	private _campaign_targeting_link_choice_policy?: CampaignTargetingLinkChoicePolicy;
	private _campaign_targeting?: BidderCampaignTargetingModel[];
	private _campaign_publisher_domain_status_limits?: BidderCampaignPublisherDomainStatusLimitsModel[];
	private _campaign_publisher_lists?: IBidderCampaignPublisherLists;
	private _campaign_initial_bids: IBidderCampaignInitialBids;
	private _domains_states_distribution?: IBidderCampaignDomainStatesDistribution[];
	private _campaign_domains?: IBidderCampaignDomains;

	constructor(data: Partial<IBidderCampaign> = {}) {
		this._campaign_name = data.campaign_name;
		this._campaign_id = data.campaign_id;
		this._campaign_advertiser_id = data.campaign_advertiser_id;
		this._campaign_status = data.campaign_status;
		this._campaign_daily_budget = data.campaign_daily_budget;
		this._campaign_max_loss_per_domain = data.campaign_max_loss_per_domain;
		this._campaign_max_clicks_to_first_conv = data.campaign_max_clicks_to_first_conv;
		this._campaign_q_max_loss_per_domain = data.campaign_q_max_loss_per_domain;
		this._campaign_q_max_clicks_to_first_conv = data.campaign_q_max_clicks_to_first_conv;
		this._campaign_group_id = data.campaign_group_id;
		this._campaign_weight = data.campaign_weight;
		this._campaign_unique_sale = data.campaign_unique_sale;
		this._campaign_performance_pixel_type = data.campaign_performance_pixel_type;
		this._campaign_jm_exclusion = data.campaign_jm_exclusion;
		this._campaign_targeting_link_choice_policy = data.campaign_targeting_link_choice_policy;
		this._campaign_targeting = data.campaign_targeting?.map(link => new BidderCampaignTargetingModel(link)) ?? [];
		this._campaign_publisher_domain_status_limits = data.campaign_publisher_domain_status_limits?.map(
			domain => new BidderCampaignPublisherDomainStatusLimitsModel(domain)
		);
		this._campaign_publisher_lists = data.campaign_publisher_lists;
		this._campaign_initial_bids = data.campaign_initial_bids!;
		this._domains_states_distribution = data.domains_states_distribution;
		this._campaign_domains = data.campaign_domains;

		makeAutoObservable(this);
	}

	get campaign_name(): string | undefined {
		return this._campaign_name;
	}

	set campaign_name(value: string | undefined) {
		this._campaign_name = value;
	}

	get campaign_id(): id | undefined {
		return this._campaign_id;
	}

	set campaign_id(value: id | undefined) {
		this._campaign_id = value;
	}

	get campaign_advertiser_id(): id | undefined {
		return this._campaign_advertiser_id;
	}

	set campaign_advertiser_id(value: id | undefined) {
		this._campaign_advertiser_id = value;
	}

	get campaign_status(): Status | undefined {
		return this._campaign_status;
	}

	set campaign_status(value: Status | undefined) {
		this._campaign_status = value;
	}

	get campaign_daily_budget(): number | undefined {
		return this._campaign_daily_budget;
	}

	set campaign_daily_budget(value: number | undefined) {
		this._campaign_daily_budget = value;
	}

	get campaign_max_loss_per_domain(): number | undefined {
		return this._campaign_max_loss_per_domain;
	}

	set campaign_max_loss_per_domain(value: number | undefined) {
		this._campaign_max_loss_per_domain = value;
	}

	get campaign_max_clicks_to_first_conv(): number | undefined {
		return this._campaign_max_clicks_to_first_conv;
	}

	set campaign_max_clicks_to_first_conv(value: number | undefined) {
		this._campaign_max_clicks_to_first_conv = value;
	}

	get campaign_q_max_loss_per_domain(): number | undefined {
		return this._campaign_q_max_loss_per_domain;
	}

	set campaign_q_max_loss_per_domain(value: number | undefined) {
		this._campaign_q_max_loss_per_domain = value;
	}

	get campaign_q_max_clicks_to_first_conv(): number | undefined {
		return this._campaign_q_max_clicks_to_first_conv;
	}

	set campaign_q_max_clicks_to_first_conv(value: number | undefined) {
		this._campaign_q_max_clicks_to_first_conv = value;
	}

	get campaign_group_id(): id | undefined {
		return this._campaign_group_id;
	}

	set campaign_group_id(value: id | undefined) {
		this._campaign_group_id = value;
	}

	get campaign_weight(): number | undefined {
		return this._campaign_weight;
	}

	set campaign_weight(value: number | undefined) {
		this._campaign_weight = value;
	}

	get campaign_unique_sale(): number | undefined {
		return this._campaign_unique_sale;
	}

	set campaign_unique_sale(value: number | undefined) {
		this._campaign_unique_sale = value;
	}

	get campaign_performance_pixel_type(): PerformancePixelType | undefined {
		return this._campaign_performance_pixel_type;
	}

	set campaign_performance_pixel_type(value: PerformancePixelType | undefined) {
		this._campaign_performance_pixel_type = value;
	}

	get campaign_jm_exclusion(): number | undefined {
		return this._campaign_jm_exclusion;
	}

	set campaign_jm_exclusion(value: number | undefined) {
		this._campaign_jm_exclusion = value;
	}

	get campaign_targeting_link_choice_policy(): CampaignTargetingLinkChoicePolicy {
		return this._campaign_targeting_link_choice_policy || CampaignTargetingLinkChoicePolicy.ByWeight;
	}

	set campaign_targeting_link_choice_policy(value: CampaignTargetingLinkChoicePolicy | undefined) {
		this._campaign_targeting_link_choice_policy = value;
	}

	get campaign_targeting(): BidderCampaignTargetingModel[] | undefined {
		return this._campaign_targeting;
	}

	set campaign_targeting(value: IBidderCampaignTargeting[] | undefined) {
		this._campaign_targeting = value?.map(link => new BidderCampaignTargetingModel(link)) ?? [];
	}

	get campaign_publisher_domain_status_limits(): IBidderCampaignPublisherDomainStatusLimits[] | undefined {
		return this._campaign_publisher_domain_status_limits;
	}

	set campaign_publisher_domain_status_limits(value: IBidderCampaignPublisherDomainStatusLimits[] | undefined) {
		this._campaign_publisher_domain_status_limits = value?.map(domain => new BidderCampaignPublisherDomainStatusLimitsModel(domain));
	}

	get_campaign_publisher_domain_status_limits_grid(
		publishers?: { id: id; name: string }[]
	): IBidderCampaignPublisherDomainStatusLimitsGrid[] | undefined {
		return publishers?.map(p => {
			const publisher = this._campaign_publisher_domain_status_limits?.find(l => l.pid === p.id);
			const limits = publisher?.domain_status_limits?.reduce((acc, j) => {
				acc[j.domain_status] = j.limit;
				return acc;
			}, {} as Record<string, number>) || {
				GOOD: 100,
				MEDIUM: 100,
				NEW: 100,
				NEW_WITH_SALE: 100,
				RANDOMTIER1: 100,
				RANDOMTIER2: 100,
				RT2_WITH_SALE: 100,
			};

			return {
				enabled: !!this._campaign_publisher_lists?.white_list?.find(j => j.pid === p.id),
				pid: p.id.toString(),
				name: p.name,
				...limits,
			};
		});
	}

	set campaign_publisher_domain_status_limits_grid(value: IBidderCampaignPublisherDomainStatusLimitsGrid[] | undefined) {
		const campaign_publisher_domain_status_limits: IBidderCampaignPublisherDomainStatusLimits[] = [];
		const campaign_publisher_lists: IBidderCampaignPublisherLists = { white_list: [], black_list: [] };

		value?.forEach(gridItem => {
			const origPublisher = this.campaign_publisher_domain_status_limits?.find(p => p.pid === gridItem.pid);

			const publisherCommon = { pid: gridItem.pid, publisher_name: gridItem.name };

			// set domain status limits
			campaign_publisher_domain_status_limits.push({
				...publisherCommon,
				domain_status_limits:
					origPublisher?.domain_status_limits?.map(dsl => ({
						id: dsl.id,
						domain_status: dsl.domain_status,
						limit: gridItem[dsl.domain_status as keyof IBidderCampaignPublisherDomainStatusLimitsGrid] as number,
					})) ||
					['GOOD', 'MEDIUM', 'NEW', 'NEW_WITH_SALE', 'RANDOMTIER1', 'RANDOMTIER2', 'RT2_WITH_SALE'].map(domain_status => ({
						id: 0,
						domain_status,
						limit: gridItem[domain_status as keyof IBidderCampaignPublisherDomainStatusLimitsGrid] as number,
					})),
			});

			// set campaign_publisher_lists -> white_list when "enabled"
			if (gridItem.enabled) {
				campaign_publisher_lists?.white_list.push(publisherCommon);
			}
		});

		this.campaign_publisher_domain_status_limits = campaign_publisher_domain_status_limits;
		this.campaign_publisher_lists = campaign_publisher_lists;
	}

	get campaign_publisher_lists(): IBidderCampaignPublisherLists | undefined {
		return this._campaign_publisher_lists;
	}

	set campaign_publisher_lists(v: IBidderCampaignPublisherLists | undefined) {
		this._campaign_publisher_lists = v;
	}

	get campaign_initial_bids(): IBidderCampaignInitialBids {
		return this._campaign_initial_bids;
	}

	set campaign_initial_bids(value: IBidderCampaignInitialBids) {
		this._campaign_initial_bids = value;
	}

	get domains_states_distribution(): IBidderCampaignDomainStatesDistribution[] | undefined {
		return this._domains_states_distribution;
	}

	set domains_states_distribution(value: IBidderCampaignDomainStatesDistribution[] | undefined) {
		this._domains_states_distribution = value;
	}

	get campaign_domains(): IBidderCampaignDomains | undefined {
		return this._campaign_domains;
	}

	set campaign_domains(value: IBidderCampaignDomains | undefined) {
		this._campaign_domains = value;
	}

	addTargeting() {
		this.campaign_targeting = [
			...(this.campaign_targeting || []),
			new BidderCampaignTargetingModel({
				id: 0,
				campaign_targeting_links: [
					new BidderCampaignTargetingLinkModel({
						id: 0,
						cids: [new BidderCampaignTargetingLinkCidModel({ id: 0, cid: 0, weight: 0 })],
					}),
				],
			}),
		];
	}

	toObject(): IBidderCampaign {
		return {
			campaign_name: this.campaign_name,
			campaign_id: this.campaign_id,
			campaign_advertiser_id: this.campaign_advertiser_id,
			campaign_status: this.campaign_status,
			campaign_daily_budget: this.campaign_daily_budget,
			campaign_max_loss_per_domain: this.campaign_max_loss_per_domain,
			campaign_max_clicks_to_first_conv: this.campaign_max_clicks_to_first_conv,
			campaign_q_max_loss_per_domain: this.campaign_q_max_loss_per_domain,
			campaign_q_max_clicks_to_first_conv: this.campaign_q_max_clicks_to_first_conv,
			campaign_weight: this.campaign_weight,
			campaign_group_id: this.campaign_group_id,
			campaign_unique_sale: this.campaign_unique_sale || 1,
			campaign_performance_pixel_type: this.campaign_performance_pixel_type || PerformancePixelType.P,
			campaign_jm_exclusion: this.campaign_jm_exclusion || 0,
			campaign_targeting_link_choice_policy: this.campaign_targeting_link_choice_policy,
			campaign_targeting: this._campaign_targeting?.map(link => link.toObject()) || [],
			campaign_publisher_domain_status_limits:
				this._campaign_publisher_domain_status_limits
					?.filter(domain => this._campaign_publisher_lists?.white_list?.find(wl => wl.pid === domain.pid))
					?.map(domain => domain.toObject()) || [],
			campaign_publisher_lists: this._campaign_publisher_lists || { white_list: [], black_list: [] },
			campaign_initial_bids: this.campaign_initial_bids,
			domains_states_distribution: this.domains_states_distribution,
			campaign_domains: this.campaign_domains,
		};
	}
}

export type BidderAdvertiser = {
	id: id;
	name: string;
	status: string;
};

export type BidderCampaignGroup = {
	id: id;
	name: string;
};
export interface IBidderCampaignTable {
	id: number;
	name: string;
	status: string;
	advertiserId: number;
	advertiserName: string;
	campaignGroupId: number;
	dailyBudget: number;
	dailyMoneySpent: number;
	dailyMoneyEarn: number;
	dailySales: number;
	maxLossPerDomain: number;
	maxClicksToFirstConv: number;
	qMaxLossPerDomain: number;
	qMaxClicksToFirstConv: number;
	stateMachineId: number;
	maxWinsToFirstClick: number;
	pageLoadsClicksRatio: number;
	maxClicksToFirstPageLoad: number;
	maxPageLoadToFirstSale: number;
	convPageLoadsRatio: number;
	convClicksRatio: number;
	targetingLinkChoicePolicy: string;
}

export interface IBidderAdvertiser {
	id: string;
	name: string;
	status?: string;
}

export class BidderCampaignTableModel {
	id: number;
	name: string;
	status: string;
	advertiserId: number;
	advertiserName: string;
	campaignGroupId: number;
	dailyBudget: number;
	dailyMoneySpent: number;
	dailyMoneyEarn: number;
	dailySales: number;
	maxLossPerDomain: number;
	maxClicksToFirstConv: number;
	qMaxLossPerDomain: number;
	qMaxClicksToFirstConv: number;
	stateMachineId: number;
	maxWinsToFirstClick: number;
	pageLoadsClicksRatio: number;
	maxClicksToFirstPageLoad: number;
	maxPageLoadToFirstSale: number;
	convPageLoadsRatio: number;
	convClicksRatio: number;
	targetingLinkChoicePolicy: string;

	constructor(data: IBidderCampaignTable) {
		this.id = data.id;
		this.name = data.name;
		this.status = data.status;
		this.advertiserId = data.advertiserId;
		this.advertiserName = data.advertiserName;
		this.campaignGroupId = data.campaignGroupId;
		this.dailyBudget = data.dailyBudget;
		this.dailyMoneySpent = data.dailyMoneySpent;
		this.dailyMoneyEarn = data.dailyMoneyEarn;
		this.dailySales = data.dailySales;
		this.maxLossPerDomain = data.maxLossPerDomain;
		this.maxClicksToFirstConv = data.maxClicksToFirstConv;
		this.qMaxLossPerDomain = data.qMaxLossPerDomain;
		this.qMaxClicksToFirstConv = data.qMaxClicksToFirstConv;
		this.stateMachineId = data.stateMachineId;
		this.maxWinsToFirstClick = data.maxWinsToFirstClick;
		this.pageLoadsClicksRatio = data.pageLoadsClicksRatio;
		this.maxClicksToFirstPageLoad = data.maxClicksToFirstPageLoad;
		this.maxPageLoadToFirstSale = data.maxPageLoadToFirstSale;
		this.convPageLoadsRatio = data.convPageLoadsRatio;
		this.convClicksRatio = data.convClicksRatio;
		this.targetingLinkChoicePolicy = data.targetingLinkChoicePolicy;
	}
}
