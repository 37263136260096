import { CellContext } from '@tanstack/react-table';
import { ColumnType, FilterDefinition, ColumnConfig as IColumnConfig } from '../report.types';
import { currencyFormat, percents } from '@monorepo/tools/src/lib/utils/number';

type ColumnRequiredConfig<T> = Required<Pick<IColumnConfig<T>, 'name' | 'accessor' | 'header'>> & Partial<IColumnConfig<T>>;

export const generateDataColumn = () => {
	return {
		availableFilters: [] as FilterDefinition[],
		visible: true,
		type: 'data' as ColumnType,
		searchable: true,
	};
};

export const generateMetricColumn = () => {
	return {
		...generateDataColumn(),
		type: 'metrics' as ColumnType,
	};
};

export const generateAggregationColumn = () => {
	return {
		...generateDataColumn(),
		type: 'aggregation' as ColumnType,
	};
};

export class ColumnConfig<T> implements IColumnConfig<T> {
	name: string;
	accessor: string;
	header: string;
	availableFilters: FilterDefinition[];
	visible: boolean;
	type: ColumnType;
	alwaysVisible?: boolean;
	alwaysHidden?: boolean;
	searchable: boolean;
	cell?: (info: CellContext<T, unknown>) => void;
	linkTo?: string[];
	footerFormatter: (value: unknown) => string;

	constructor(column: ColumnRequiredConfig<T>) {
		this.name = column.name;
		this.accessor = column.accessor;
		this.header = column.header;
		this.availableFilters = column.availableFilters ?? [];
		this.visible = column.visible ?? true;
		this.type = column.type ?? 'data';
		this.alwaysVisible = column.alwaysVisible;
		this.alwaysHidden = column.alwaysHidden;
		this.searchable = column.searchable ?? true;
		this.cell = column.cell;
		this.linkTo = column.linkTo;
		this.footerFormatter = column.footerFormatter || (value => (value as number)?.toLocaleString() ?? '-');
	}
}

export class MetricsColumnConfig<T> extends ColumnConfig<T> {
	constructor(column: ColumnRequiredConfig<T>) {
		super({ ...generateMetricColumn(), ...column });
		this.cell = ({ row }) => <span>{row.getValue(column.accessor)?.toLocaleString() ?? '-'}</span>;
	}
}
export class MetricsCurrencyColumnConfig<T> extends ColumnConfig<T> {
	constructor(column: ColumnRequiredConfig<T>) {
		super({ ...generateMetricColumn(), ...column });
		this.cell = ({ row }) => <span>{currencyFormat(row.getValue(column.accessor)) ?? '-'}</span>;
		this.footerFormatter = value => currencyFormat(value as number) ?? '-';
	}
}
export class MetricsPercentageColumnConfig<T> extends ColumnConfig<T> {
	constructor(column: ColumnRequiredConfig<T>) {
		super({ ...generateMetricColumn(), ...column });
		this.cell = ({ row }) => <span>{percents(row.getValue(column.accessor)) ?? '0%'}</span>;
		this.footerFormatter = value => percents(value as number) ?? '0%';
	}
}

export class AggregationColumnConfig<T> extends ColumnConfig<T> {
	constructor(column: ColumnRequiredConfig<T>) {
		super({ ...generateAggregationColumn(), ...column });
	}
}

export class DataColumnConfig<T> extends ColumnConfig<T> {
	constructor(column: ColumnRequiredConfig<T>) {
		super({ ...generateDataColumn(), ...column });
	}
}
