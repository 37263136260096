import { observer } from 'mobx-react';
import { FC, useState } from 'react';
import { ControlledTable } from '@monorepo/controlled/src/components/table-wrapper/table-wrapper';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import routes from '../../../modules/routes/index.routes';
import { useCampaignActions } from '../../../modules/hooks/actions/campaign.actions';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import {
	IPerformanceOptionsDateSegments,
	IPerformanceOptionsSortBy,
	IPerformanceOptionsSortOrder,
	PerformancesColumnsSnakeCase,
	groupByMapper,
	useReportsList,
} from '../../../modules/hooks/apis/use-reports-list';
import { useRoute } from '../../../modules/hooks/tools/use-route';
import { Chart } from '@monorepo/base/src/components/chart/chart';
import { SpacerY } from '@monorepo/base/src/components/spacer/spacer';
import { useMetrics } from '../../../modules/hooks/tools/use-metrics';
import { useCampaignHeaders } from '../../../modules/hooks/theaders/campaigns.headers';
import { Page, Pagebar, PagebarTitle, PagebarActions } from '@monorepo/base/src/components/page/page';
import { IAdminxStore } from '../../../modules/stores';
import { availablePerformanceToMetrics } from '../../../modules/models/performance.model';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { Card } from '@monorepo/base/src/components/card/card';
import { PerformanceApi } from '../../../modules/apis/performance.api';
import dayjs from 'dayjs';
import { DispatchLogsTypes } from '@monorepo/controlled/src/hooks/use-logs';
import { useDownload } from '@monorepo/tools/src/lib/hooks/tools/use-download';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { usePageSettings } from '@monorepo/tools/src/lib/hooks/tools/use-page-settings';

const actionsDebugProps = { dataAttrs: [new DataAttribute('id', 'campaigns_actions')] };
const pageBarDebugProps = { dataAttrs: [new DataAttribute('id', 'pagebar')] };
const tableDebugProps = { dataAttrs: [new DataAttribute('id', 'campaigns_table')] };

export const Campaigns: FC = observer(() => {
	const { campaignStore, toastsStore } = useStores<IAdminxStore>();
	const metricsStore = campaignStore.getMetrics();
	const reportList = campaignStore.getListStore();
	const columnVisibility = usePageSettings().tableStore?.getColumnVisibility();
	const defaultSortBy = usePageSettings().tableStore?.getSortingBy();
	const { currentRouteWithoutSpecialChars } = useRoute();
	const [isLoadingCsv, setIsLoadingCsv] = useState<boolean>(false);

	const groupByColumns = Object.keys(columnVisibility || {})
		.map((col: string) => {
			if (typeof col === 'string' && columnVisibility && columnVisibility[col]) {
				return groupByMapper[col];
			}
		})
		.filter(val => !!val) as PerformancesColumnsSnakeCase[];

	const { fetchParams, fetchReports } = useReportsList(reportList, {
		groupBys: [...groupByColumns],
		reportName: 'adxCampaigns',
		sortBy: defaultSortBy?.[0]?.id || IPerformanceOptionsSortBy.Wins,
		sortOrder: defaultSortBy?.[0]?.desc ? IPerformanceOptionsSortOrder.Desc : IPerformanceOptionsSortOrder.Asc,
		dateSegment: groupByColumns.includes(PerformancesColumnsSnakeCase.Dt)
			? IPerformanceOptionsDateSegments.Daily
			: IPerformanceOptionsDateSegments.None,
		submit: true,
	});

	useReportsList(metricsStore, {
		groupBys: [PerformancesColumnsSnakeCase.Dt],
		reportName: 'adxCampaigns',
		dateSegment: IPerformanceOptionsDateSegments.Daily,
	});

	const onDownloadReportFile = () => {
		const fileName = `Campaigns report ${dayjs(fetchParams.periodStart).format('YYYY/MM/DD')} - ${dayjs(fetchParams.periodEnd).format(
			'YYYY/MM/DD'
		)}`;
		let totalRows = reportList?.getData()?.total || 0;

		if (typeof totalRows === 'string') {
			totalRows = parseInt(totalRows);
		}

		if (totalRows <= 0) {
			toastsStore.addToast({
				msg: 'Cannot export empty file.',
				type: DispatchLogsTypes.Info,
			});
		} else if (totalRows >= 100000) {
			// PublishersApi.requestCsv({ ...fetchParams, limit: 100000 }, { queryParams: { fileName } });
			// toastsStore.addToast({
			// 	msg: 'Exporting to CSV, an email will be sent to you when the file is ready.',
			// 	type: DispatchLogsTypes.Success,
			// });
		} else {
			setIsLoadingCsv(true);
			useDownload({
				httpStore: new HttpStore<undefined, File | null>({
					httpFunc: () => PerformanceApi.downloadCsv({ ...fetchParams }).finally(() => setIsLoadingCsv(false)),
				}),
				fileName,
				extension: 'csv',
			});
		}
	};
	const { columns } = useCampaignHeaders({ summary: reportList.getData()?.footer });
	const { SelectedActions, FiltersActions } = useCampaignActions({ debugProps: actionsDebugProps });

	const { metrics, xLabels } = useMetrics(metricsStore.getData());

	return (
		<Page>
			<Pagebar debugProps={pageBarDebugProps}>
				<PagebarTitle>Campaigns</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
				</PagebarActions>
			</Pagebar>
			<Card full debugProps={{ dataAttrs: [new DataAttribute('id', 'chart')] }}>
				<Chart
					isLoading={metricsStore.getIsLoading()}
					metrics={metrics}
					labels={xLabels}
					legendOptions={Object.keys(availablePerformanceToMetrics)}
				/>
			</Card>
			<SpacerY y={2} />
			<ControlledTable
				currentRoute={currentRouteWithoutSpecialChars}
				debugProps={tableDebugProps}
				createRoute={routes.campaigns.create()}
				columns={columns}
				onExportToCsv={onDownloadReportFile}
				isLoadingCsv={isLoadingCsv}
				defaultSortBy={defaultSortBy}
				data={reportList.getData()?.data || []}
				isLoading={campaignStore.getListStore().getIsLoading()}
				fetchReports={fetchReports}
				isSummary={true}>
				{() => {
					return {
						SelectedActions,
						FiltersActions,
					};
				}}
			</ControlledTable>
		</Page>
	);
});
