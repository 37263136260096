import { Fragment } from 'react';
import { SecondaryText } from '@monorepo/base/src/components/buttons/buttons';
import { SelectedLinerButton } from '@monorepo/base/src/components/table/action-liner/liner-buttons';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { ITableActions } from './default.actions';
import { ActionModal, ModalTypes } from '@monorepo/controlled/src/components/modal/modals/action-modal/action-modal';
import { EnablePauseCampaignsModal } from '../../components/modals/enable-pause-campaigns';
import { IAdminxStore } from '../../stores';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { suffixToDataAttr } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { DispatchLogsTypes } from '@monorepo/controlled/src/hooks/use-logs';
import { ITableFiltersActions, TableFilters } from '@monorepo/base/src/components/table/table-filters/table-filters';
import { useRoute } from '../tools/use-route';
import { bidderCampaignFilterPrototypes } from '../../components/filters-menu/filters';
import routes from '../../routes/index.routes';

interface ICampaignActions {
	debugProps?: IDebugProps;
}

export function useBidderCampaignActions(props: ICampaignActions = {}) {
	const { debugProps } = props;
	const { settingsStore, bidderStore, modalsStore, toastsStore } = useStores<IAdminxStore>(); //settingsStore
	const { currentRouteWithoutSpecialChars } = useRoute();
	const bidderCampaignCrudStore = bidderStore.getCrud();

	const isLoading = bidderCampaignCrudStore.getIsLoading();

	const navigate = useNavigateParams();
	const filterStore = settingsStore
		.getPages()
		.get(currentRouteWithoutSpecialChars)
		?.tableStore.getFiltersStore(currentRouteWithoutSpecialChars);

	const currentFilters = filterStore?.getCurrentFilters();
	const { dataAttrs } = debugProps || {};

	const DefaultAvailableFilters = [...bidderCampaignFilterPrototypes];

	const enableCampaign = (campaignId: id) => {
		modalsStore.addModal({
			children: (
				<ActionModal
					type={ModalTypes.Info}
					primaryBtnTitle={'Enable'}
					onAction={() => {
						bidderCampaignCrudStore.enableCampaign(campaignId).then(() => {
							toastsStore.addToastOnNav({ msg: `Campaigns ${campaignId} Enabled`, type: DispatchLogsTypes.Success });
							navigate(0);
						});
					}}>
					<EnablePauseCampaignsModal action={'Enable'} campaignIds={[campaignId]} />
				</ActionModal>
			),
		});
	};

	const pauseCampaign = (campaignId: id) => {
		modalsStore.addModal({
			children: (
				<ActionModal
					type={ModalTypes.Info}
					primaryBtnTitle={'Paused'}
					onAction={() => {
						bidderCampaignCrudStore.pauseCampaign(campaignId).then(() => {
							toastsStore.addToastOnNav({ msg: `Campaigns ${campaignId} Paused`, type: DispatchLogsTypes.Success });
							navigate(0);
						});
					}}>
					<EnablePauseCampaignsModal action={'Paused'} campaignIds={[campaignId]} />
				</ActionModal>
			),
		});
	};

	const SelectedActions = (props: ITableActions<any>) => {
		const { rows } = props;
		const entitiesIds: id[] = rows.map(row => {
			if (row.original?.id) {
				return row.original?.id;
			}
		});

		return (
			<Fragment>
				<SelectedLinerButton>
					<SecondaryText
						icon={'play'}
						onClick={() => enableCampaign(entitiesIds[0])}
						textColor={'white'}
						isRotateIcon={isLoading}
						disabled={isLoading || entitiesIds.length !== 1}
						debugProps={{ dataAttrs: suffixToDataAttr('_selected_enable', dataAttrs) }}>
						Enable
					</SecondaryText>
				</SelectedLinerButton>
				<SelectedLinerButton>
					<SecondaryText
						icon={'pause-square'}
						onClick={() => pauseCampaign(entitiesIds[0])}
						isRotateIcon={isLoading}
						disabled={isLoading || entitiesIds.length !== 1}
						textColor={'white'}
						debugProps={{ dataAttrs: suffixToDataAttr('_selected_pause', dataAttrs) }}>
						Pause
					</SecondaryText>
				</SelectedLinerButton>
				<SelectedLinerButton>
					<SecondaryText
						icon={'edit-05'}
						onClick={() => navigate(`${routes.bidder.editCampaign(entitiesIds[0]?.toString())}`)}
						textColor={'white'}
						disabled={entitiesIds.length !== 1}
						debugProps={{ dataAttrs: suffixToDataAttr('_selected_edit', dataAttrs) }}>
						Edit
					</SecondaryText>
				</SelectedLinerButton>
			</Fragment>
		);
	};

	const FiltersActions = (props: ITableFiltersActions) => {
		if (!currentFilters) {
			return null;
		}

		return (
			<TableFilters
				{...props}
				availableFilters={DefaultAvailableFilters}
				currentFilters={currentFilters}
				clearAll={() => filterStore?.clearAll()}
				clearFilter={index => filterStore?.clearFilter(index)}
				addFilter={filter => filterStore?.addFilter(filter)}
				editFilter={(index: number, filter) => filterStore?.editFilter(index, filter)}
			/>
		);
	};

	return {
		SelectedActions,
		FiltersActions,
	};
}
