import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../stores';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { useEffect } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { IDomainHistory } from '../../../models/domain.models';
import { IMergedHeadersReturn } from '../index.headers';

const columnHelper = createColumnHelper<IDomainHistory>();

export const useDomainHistoryHeaders = (): IMergedHeadersReturn<IDomainHistory> => {
	const { settingsStore } = useStores<IAdminxStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();
	const tableStore = settingsStore.pages.get(currentRouteWithoutSpecialChars)?.tableStore;

	useEffect(() => {
		if (tableStore && Object.keys(tableStore.getColumnVisibility()).length === 0) {
			tableStore?.setDefaultColumnVisibility({
				id: true,
				domainStatus: true,
				description: true,
				billingId: true,
				billingName: true,
				createdBy: true,
				createdAt: true,
			});
			tableStore?.setColumnVisibility(tableStore.getDefaultColumnVisibility());
		}
	}, [tableStore]);

	return {
		columns: [
			columnHelper.accessor('id', {
				header: 'ID',
			}),
			columnHelper.accessor('domainStatus', {
				header: 'Status',
			}),
			columnHelper.accessor('description', {
				header: 'Description',
			}),
			columnHelper.accessor('billingId', {
				header: 'Billing Id',
			}),
			columnHelper.accessor('billingName', {
				header: 'Billing Name',
			}),
			columnHelper.accessor('createdBy', {
				header: 'Created By',
			}),
			columnHelper.accessor('createdAt', {
				header: 'Created At',
			}),
		],
	};
};
