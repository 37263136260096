import { CellContext, PaginationState, SortingState, VisibilityState } from '@tanstack/react-table';

export enum ReportFilterType {
	CONTAINS = 'CONTAINS',
	NOT_CONTAINS = 'CONTAINS',
	IS_NULL = 'IS_NULL',
	IS_NOT_NULL = 'IS_NULL',
	EQUALS = 'EQUALS',
	NOT_EQUALS = 'EQUALS',
	IN = 'IN',
	NOT_IN = 'NOT_IN',
	GREATER = 'GREATER',
	GREATER_AND_EQUALS = 'GREATER_EQUALS',
	LESS = 'LESSER',
	LESS_AND_EQUALS = 'LESSER_EQUALS',
	INCLUDE = 'INCLUDE',
}

export interface ReportFilter {
	column: string;
	filterType: ReportFilterType;
	value: string[];
	inverted?: boolean;
}
export interface ReportTimePeriod {
	periodStart: string; // 'YYYY-MM-DD'
	periodEnd: string; // 'YYYY-MM-DD'
}

export enum ReportDateSegments {
	Daily = 'DAILY',
	None = 'NONE',
}
export enum ReportSortOrder {
	Asc = 0,
	Desc = 1,
}

export enum ReportType {
	Statistics = 'STATISTICS',
}

export interface ReportParams {
	start: number;
	limit: number;
	periodStart: string; // 'YYYY-MM-DD'
	periodEnd: string; // 'YYYY-MM-DD'
	multipleTimePeriods: ReportTimePeriod[];
	filters: ReportFilter[];
	groupBys: string[];
	dateSegment?: ReportDateSegments;
	sortBy: string;
	sortOrder: ReportSortOrder;
	inResultsSort: string | null;
	inResultsSortOrder: ReportSortOrder | null;
	inResultsFilters: string[][];
	graphYaxis: string[]; // deprecated - always be an empty array
	graphLines: string[]; // deprecated - always be an empty array
	additionalColumns: string[]; // deprecated - always be an empty array
	reportName: string;
	submit: boolean;
	type: ReportType;
}

export const generateReportParams = (): ReportParams => {
	return {
		start: 0,
		limit: 1000,
		periodStart: '',
		periodEnd: '',
		multipleTimePeriods: [],
		filters: [],
		groupBys: [],
		sortBy: '',
		sortOrder: ReportSortOrder.Desc,
		dateSegment: ReportDateSegments.None,
		inResultsSort: null,
		inResultsSortOrder: 1,
		inResultsFilters: [],
		graphYaxis: [],
		graphLines: [],
		additionalColumns: [],
		reportName: '',
		submit: true,
		type: ReportType.Statistics,
	};
};

export interface ReportResponse<T> {
	success: boolean;
	errorMessage: string | null;
	data: T[];
	total: number;
	limited: boolean;
	footer: T;
	columns: string[][];
	graphData: object;
}

export type ColumnType = 'aggregation' | 'metrics' | 'data';

export enum FilterType {
	Text = 'text',
	Number = 'number',
	Date = 'date',
	Boolean = 'boolean',
	Select = 'select',
}

export interface FilterOption {
	value: string;
	label: string;
}

export interface FilterDefinition {
	type: FilterType; // The type of filter
	column: string; // The column name in the report
	label: string; // A human-readable label for the filter
	options?: Set<FilterOption>; // Optional: predefined options for the filter, if applicable
}

export interface ColumnConfig<T> {
	name: string; // The column name in the report fetching
	accessor: string; // The accessor for the column in the table
	header: string; // The header title for the table
	availableFilters: FilterDefinition[]; // Available filters for the column
	visible: boolean; // Initial visibility state of the column
	type: ColumnType; // Type of the column (aggregation, metrics, or data)
	alwaysVisible?: boolean; // Always visible attribute
	alwaysHidden?: boolean; // Always hidden attribute
	searchable: boolean; // Indicates if the column can be searched
	cell?: (info: CellContext<T, unknown>) => void; // Cell renderer for the column
	linkTo?: string[];
	footerFormatter?: (value: unknown) => string;
}

export enum PlatformsCells {
	Metrics = 'metrics',
	MetricsPercentage = 'metricsPercentage',
	Link = 'link',
}

export interface ITableState<T> {
	columnsConfig: ColumnConfig<T>[];
	pagination?: PaginationState;
	sorting?: SortingState;
	filters?: FilterOption[];
	columnVisibility?: VisibilityState;
	hideFooter?: boolean;
}
