import { createColumnHelper } from '@tanstack/react-table';
import { TableLink } from '@monorepo/base/src/components/table/table-link/table-link';
import { Tag, TagTypes } from '@monorepo/base/src/components/tag/tag';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import routes from '../../routes/index.routes';
import { IMergedHeadersReturn } from './index.headers';
import { IAdminxStore } from '../../stores';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { useEffect } from 'react';
import { AccountsModel } from '../../models/account.model';

const columnHelper = createColumnHelper<AccountsModel>();

export const useAccountHeaders = (): IMergedHeadersReturn<AccountsModel> => {
	const { settingsStore } = useStores<IAdminxStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();
	const tableStore = settingsStore.pages.get(currentRouteWithoutSpecialChars)?.tableStore;

	useEffect(() => {
		if (tableStore && Object.keys(tableStore.getColumnVisibility()).length === 0) {
			tableStore.setDefaultColumnVisibility({
				id: true,
				name: true,
				use_internal_pricing: true,
			});
			tableStore?.setColumnVisibility(tableStore.getDefaultColumnVisibility());
		}
	}, [tableStore]);

	return {
		columns: [
			columnHelper.accessor('id', {
				header: 'ID',
			}),
			columnHelper.accessor('name', {
				header: 'Name',
				cell: info => {
					const { row } = info;
					return (
						<TableLink
							toEdit={routes.accounts.edit(row.getValue('id'))}
							toEditState={{ isBlockRequest: true } as BetweenPagesStateProps}>
							{row.getValue('name')}
						</TableLink>
					);
				},
			}),
			columnHelper.accessor('use_internal_pricing', {
				header: 'Use Internal Pricing',
				cell: ({ row }) => {
					const v = row.getValue('use_internal_pricing');
					return <Tag type={v ? TagTypes.Success : TagTypes.Error}>{v ? 'Yes' : 'No'}</Tag>;
				},
			}),
		],
	};
};
