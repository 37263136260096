import { ReactNode, useState } from 'react';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import styles from './card.module.scss';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { generateDataAttrs } from '@monorepo/tools/src/lib/models/data-attr.model';

export interface ICard {
	children: JSX.Element | JSX.Element[] | ReactNode;
	titleChildren?: JSX.Element;
	title?: string;
	icon?: string;
	id?: string;
	className?: string;
	border?: boolean;
	full?: boolean;
	debugProps?: IDebugProps; // TODO - not working because of babel-plugin-react-generate-property
	collapsible?: boolean;
	defaultCollapsed?: boolean;
}

export const Card = (props: ICard) => {
	const {
		children,
		titleChildren,
		icon,
		title,
		id = '',
		className = '',
		border = true,
		full = false,
		debugProps,
		collapsible = false,
		defaultCollapsed = true,
	} = props;
	const { dataAttrs } = debugProps || {};

	const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);

	return (
		<div
			id={id}
			className={`card ${className} ${styles.wrapper} ${border ? styles.border : ''} ${full ? styles.full : ''}`}
			{...generateDataAttrs(dataAttrs)}>
			<h2 className={`${styles.title} ${collapsible ? styles.clickable : ''}`} onClick={() => setIsCollapsed(prev => !prev)}>
				<div className={styles.titleContent}>
					{collapsible ? (
						<Icon isMFP={true} className={styles.collapseIcon}>
							{isCollapsed ? 'chevron-right' : 'chevron-down'}
						</Icon>
					) : null}
					{icon ? (
						<Icon isMFP={true} className={styles.titleIcon}>
							{icon}
						</Icon>
					) : null}
					{title}
				</div>
				{titleChildren && <div className={styles.titleChildren}>{titleChildren || null}</div>}
			</h2>
			{((collapsible && !isCollapsed) || !collapsible) && children}
		</div>
	);
};
