import { Page, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useParams } from 'react-router-dom';
import styles from './domains-overview.module.scss';
import { PerformancesColumnsSnakeCase, useReportsList } from '../../../../modules/hooks/apis/use-reports-list';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../../modules/stores';
import { observer } from 'mobx-react';
import { useDomainPretargetingStatusHeaders } from '../../../../modules/hooks/theaders/domains/domains-pretargeting-status.headers';
import { Table } from '@monorepo/base/src/components/table/table';
import { useDomainCampaignsStatusHeaders } from '../../../../modules/hooks/theaders/domains/domains-campaigns-status.headers';
import { useDomainHistoryHeaders } from '../../../../modules/hooks/theaders/domains/domains-history.headers';
import { useDomainScoreHeaders } from '../../../../modules/hooks/theaders/domains/domains-scores.headers';

export const DomainsOverviewPage = observer(() => {
	const { domainsStore } = useStores<IAdminxStore>();
	const { domainName } = useParams();
	const {
		scores: scoresStore,
		pretargetingStatus: pretargetingStatusStore,
		history: historyStore,
		campaignStatus: campaignStatusStore,
	} = domainsStore;

	const getReportParams = (reportName: string, groupBy: PerformancesColumnsSnakeCase[], sortBy: string) => {
		return {
			reportName,
			groupBys: groupBy,
			sortBy,
			submit: true,
			filters: domainName
				? [
						{
							column: 'domain',
							filterType: 'EQUALS',
							value: [domainName],
							inverted: false,
						},
				  ]
				: [],
		};
	};
	useReportsList(scoresStore, getReportParams('adxDomainsScore', domainsStore.scoresGroupBy, 'score'));
	useReportsList(pretargetingStatusStore, getReportParams('adxDomainPretargetingStatus', domainsStore.pretargetingStatusGroupBy, 'name'));
	useReportsList(historyStore, getReportParams('adxDomainHistory', domainsStore.historyGroupBy, 'billing_name'));
	useReportsList(campaignStatusStore, getReportParams('adxDomainCampaignsStatus', domainsStore.campaignStatusGroupBy, 'campaign_name'));

	const { columns: pretargetingColumns } = useDomainPretargetingStatusHeaders();
	const { columns: campaignsColumns } = useDomainCampaignsStatusHeaders();
	const { columns: historyColumns } = useDomainHistoryHeaders();
	const { columns: scoresColumns } = useDomainScoreHeaders();

	return (
		<Page className={styles.domainOverviewPage}>
			<div className={`${styles.sectionWrapper} ${styles.pretatgetingAndHistoryWrapper}`}>
				<div className={styles.tableAndTitle}>
					<PagebarTitle>Pre-targeting status</PagebarTitle>
					<Table
						columns={pretargetingColumns}
						isLoading={pretargetingStatusStore.getIsLoading()}
						isBarLoader={pretargetingStatusStore.getIsLoading()}
						data={pretargetingStatusStore.getData()?.getData() || []}
						isSummary={false}
						isActionLiner={false}
						pageSize={5}
					/>
				</div>
				<div className={styles.tableAndTitle}>
					<PagebarTitle>Black / white list status</PagebarTitle>
					<Table
						columns={campaignsColumns}
						isLoading={campaignStatusStore.getIsLoading()}
						isBarLoader={campaignStatusStore.getIsLoading()}
						data={campaignStatusStore.getData()?.getData() || []}
						isSummary={false}
						isActionLiner={false}
						pageSize={5}
					/>
				</div>
			</div>
			<div className={styles.sectionWrapper}>
				<div className={styles.tableAndTitle}>
					<PagebarTitle>Scores</PagebarTitle>
					<Table
						columns={scoresColumns}
						isLoading={scoresStore.getIsLoading()}
						isBarLoader={scoresStore.getIsLoading()}
						data={scoresStore.getData()?.getData() || []}
						isSummary={false}
						isActionLiner={false}
						pageSize={5}
					/>
				</div>
			</div>
			<div className={styles.sectionWrapper}>
				<div className={styles.tableAndTitle}>
					<PagebarTitle>History</PagebarTitle>
					<Table
						columns={historyColumns}
						isLoading={historyStore.getIsLoading()}
						isBarLoader={historyStore.getIsLoading()}
						data={historyStore.getData()?.getData() || []}
						isSummary={false}
						isActionLiner={false}
						pageSize={5}
					/>
				</div>
			</div>
		</Page>
	);
});
