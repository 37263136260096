import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../stores';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { useEffect } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { IDomainCampaignsStatus } from '../../../models/domain.models';
import { IMergedHeadersReturn } from '../index.headers';
import { TableLink } from '@monorepo/base/src/components/table/table-link/table-link';
import routes from '../../../routes/index.routes';

const columnHelper = createColumnHelper<IDomainCampaignsStatus>();

export const useDomainCampaignsStatusHeaders = (): IMergedHeadersReturn<IDomainCampaignsStatus> => {
	const { settingsStore, sidebarStore } = useStores<IAdminxStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();
	const tableStore = settingsStore.pages.get(currentRouteWithoutSpecialChars)?.tableStore;

	useEffect(() => {
		if (tableStore && Object.keys(tableStore.getColumnVisibility()).length === 0) {
			tableStore?.setDefaultColumnVisibility({
				campaignId: true,
				campaignName: true,
				campaignStatus: true,
				listType: true,
			});
			tableStore?.setColumnVisibility(tableStore.getDefaultColumnVisibility());
		}
	}, [tableStore]);

	return {
		columns: [
			columnHelper.accessor('campaignId', {
				header: 'Campaign ID',
			}),
			columnHelper.accessor('campaignName', {
				header: 'Campaign Name',
				cell: info => {
					const { row } = info;
					return (
						<TableLink
							to={routes.campaigns.view(row.getValue('campaignId'))}
							toEdit={routes.campaigns.edit(row.getValue('campaignId'))}
							onClick={() => sidebarStore.setSidebarOpen(false)}>
							{row.getValue('campaignName')}
						</TableLink>
					);
				},
			}),
			columnHelper.accessor('campaignStatus', {
				header: 'Status',
			}),
			columnHelper.accessor('listType', {
				header: 'List Type',
			}),
		],
	};
};
