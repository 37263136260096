import { observer } from 'mobx-react';
import { FC } from 'react';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { Page, Pagebar, PagebarTitle, PagebarActions } from '@monorepo/base/src/components/page/page';
import { Outlet } from 'react-router-dom';
import { useDomainHeaders } from '../../../modules/hooks/theaders/domains.headers';
import { Table } from '@monorepo/base/src/components/table/table';
import { usePerformance } from '../../../modules/hooks/apis/use-performance';
import {
	IPerformanceOptionsSortBy,
	IPerformanceOptionsSortOrder,
	PerformancesColumnsSnakeCase,
} from '../../../modules/hooks/apis/use-reports-list';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../modules/stores';

export const Domains: FC = observer(() => {
	const { performanceStore } = useStores<IAdminxStore>();

	const { columns } = useDomainHeaders();
	usePerformance({
		groupBys: [PerformancesColumnsSnakeCase.Domain],
		limit: 5000,
		sortBy: IPerformanceOptionsSortBy.Wins,
		sortOrder: IPerformanceOptionsSortOrder.Desc,
		submit: false,
	});

	return (
		<Page>
			<Pagebar>
				<PagebarTitle>Domains</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
				</PagebarActions>
			</Pagebar>

			<Table
				columns={columns}
				isLoading={performanceStore.getIsLoading()}
				isBarLoader={performanceStore.getIsLoading()}
				data={performanceStore.getData()?.getData() || []}
				isSummary={true}
			/>
			<Outlet />
		</Page>
	);
});
