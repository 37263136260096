import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../stores';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { reportsFilterPrototypes } from '../../components/filters-menu/filters';
import { ITableFiltersActions, TableFilters } from '@monorepo/base/src/components/table/table-filters/table-filters';

export function useReportActions() {
	const { settingsStore } = useStores<IAdminxStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();

	const filterStore = settingsStore
		.getPages()
		.get(currentRouteWithoutSpecialChars)
		?.tableStore.getFiltersStore(currentRouteWithoutSpecialChars);
	const currentFilters = filterStore?.getCurrentFilters();

	const DefaultAvailableFilters = [...reportsFilterPrototypes];

	const FiltersActions = (props: ITableFiltersActions) => {
		if (!currentFilters) {
			return null;
		}

		return (
			<TableFilters
				{...props}
				availableFilters={DefaultAvailableFilters}
				currentFilters={currentFilters}
				clearAll={() => filterStore?.clearAll()}
				clearFilter={index => filterStore?.clearFilter(index)}
				addFilter={filter => filterStore?.addFilter(filter)}
				editFilter={(index, filter) => filterStore?.editFilter(index, filter)}
			/>
		);
	};

	return {
		FiltersActions,
	};
}
