import { id } from '@monorepo/tools/src/lib/types/primitives';

export enum DomainListTypes {
	BLACKLIST = 'BLACKLIST',
	WHITELIST = 'WHITELIST',
}

export enum PerformancePixelType {
	Q = 'q',
	P = 'p',
}

export enum CampaignTargetingLinkChoicePolicy {
	PerPublisher = 'perPublisher',
	ByWeight = 'byWeight',
	WithConversionLimit = 'withConversionLimit',
}

export const bidderCampaignTypeToTitle = new Map<CampaignTargetingLinkChoicePolicy, string>([
	[CampaignTargetingLinkChoicePolicy.PerPublisher, 'Per Publisher'],
	[CampaignTargetingLinkChoicePolicy.ByWeight, 'By Weight'],
	[CampaignTargetingLinkChoicePolicy.WithConversionLimit, 'With Conv. Limit'],
]);

export interface IBidderCampaignInitialBids {
	max_loss_per_key: number;
	max_num_Of_clicks_to_first_conv: number;
	maximum_bid: number;
	minimal_bid: number;
	random_bid_step: number;
}

export interface IBidderCampaignDomainStatesDistribution {
	domains_quantity: number;
	state_name: string;
}

export interface IBidderCampaignDomains {
	domains?: string;
	list_type?: DomainListTypes;
}

export interface IBidderCampaignDetailsMetaData {
	campaign_id: id;
	campaign_group_id: id;
	advertiser_id: id;
}

export interface UpdateCampaignResponse {
	MetaData: IBidderCampaignDetailsMetaData;
	success: boolean;
}
