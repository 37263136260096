import { Page, Pagebar, PagebarActions, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { observer } from 'mobx-react';
import { IAdminxStore } from '../../../../modules/stores';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { ControlledTable } from '@monorepo/controlled/src/components/table-wrapper/table-wrapper';
import { useRoute } from '../../../../modules/hooks/tools/use-route';
import { useStateBetweenPages } from '../../../../modules/hooks/tools/use-state-between-pages';
import { useList } from '../../../../modules/hooks/tools/use-list';
import { BidderCampaignTableModel } from '../../../../modules/models/bidder-campaign/bidder-camapign.model';
import { useBidderHeaders } from '../../../../modules/hooks/theaders/bidder.headers';
import { useBidderCampaignActions } from '../../../../modules/hooks/actions/bidder-campaign.actions';
import { usePageSettings } from '@monorepo/tools/src/lib/hooks/tools/use-page-settings';
import routes from '../../../../modules/routes/index.routes';

export const BidderCampaign = observer(() => {
	const {
		bidderStore: { campaignsList },
	} = useStores<IAdminxStore>();

	const { currentRouteWithoutSpecialChars } = useRoute();

	const stateBetweenPages = useStateBetweenPages(() => campaignsList.getList().length !== 0);

	useList<BidderCampaignTableModel>(campaignsList, undefined, stateBetweenPages);

	const { columns } = useBidderHeaders();
	const { SelectedActions, FiltersActions } = useBidderCampaignActions();

	return (
		<Page>
			<Pagebar>
				<PagebarTitle>Campaigns</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
				</PagebarActions>
			</Pagebar>

			<ControlledTable
				currentRoute={currentRouteWithoutSpecialChars}
				createRoute={routes.bidder.createCampaign()}
				columns={columns}
				data={campaignsList.getList()}
				defaultSortBy={usePageSettings().tableStore?.getSortingBy()}
				isLoading={campaignsList.getIsLoading()}>
				{() => {
					return {
						SelectedActions,
						FiltersActions,
					};
				}}
			</ControlledTable>
		</Page>
	);
});
