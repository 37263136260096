import { CellContext } from '@tanstack/react-table';
import { IDataType } from '../stores/data-stores/data-store.types';
import { currencyFormat, percents, percentsNoRatio } from '@monorepo/tools/src/lib/utils/number';

export const percentageRoundedValueFormatter = (value: unknown) => {
	const roasValue = value as number;
	return percents(Math.floor(roasValue * 100) / 100) ?? '0%';
};

export const percentageRoundedCellCreator = (accessor: string) => {
	return ({ row }: CellContext<IDataType, unknown>) => {
		const roasValue = row.getValue(accessor);
		const valueToDisplay = percentageRoundedValueFormatter(roasValue);
		return <span>{valueToDisplay}</span>;
	};
};

export const currencyWithDecimalValueFormatter = (value: unknown, decimals = 2) => {
	const currencyValue = value as number;
	return currencyFormat(currencyValue as number, true, { decimalLen: decimals }) ?? '-';
};

export const currencyWithDecimalCellCreator = (accessor: string, decimals = 2) => {
	return ({ row }: CellContext<IDataType, unknown>) => {
		const currencyValue = row.getValue(accessor);
		const valueToDisplay = currencyWithDecimalValueFormatter(currencyValue, decimals);
		return <span>{valueToDisplay}</span>;
	};
};

export const currencyValueFormatter = (value: unknown) => {
	const currencyValue = value as number;
	return currencyFormat(currencyValue as number) ?? '-';
};

export const currencyCellCreator = <T = IDataType,>(accessor: string) => {
	return ({ row }: CellContext<T, unknown>) => {
		const currencyValue = row.getValue(accessor);
		const valueToDisplay = currencyValueFormatter(currencyValue);
		return <span>{valueToDisplay}</span>;
	};
};

export const percentageValueFormatter = (value: unknown) => {
	const percentageValue = value as number;
	return percents(percentageValue) ?? '0%';
};
export const percentageNoRatioValueFormatter = (value: unknown) => {
	const percentageValue = value as number;
	return percentsNoRatio(percentageValue) ?? '0%';
};

export const percentageRatioCellCreator = (accessor: string) => {
	return ({ row }: CellContext<IDataType, unknown>) => {
		const percentageValue = row.getValue(accessor);
		const valueToDisplay = percentageValueFormatter(percentageValue);
		return <span>{valueToDisplay}</span>;
	};
};

export const percentageCellCreator = (accessor: string) => {
	return ({ row }: CellContext<IDataType, unknown>) => {
		const percentageValue = row.getValue(accessor);
		const valueToDisplay = percentageNoRatioValueFormatter(percentageValue);
		return <span>{valueToDisplay}</span>;
	};
};

export const defaultMetricValueFormatter = (value: unknown) => {
	const metricValue = value as number;
	return metricValue?.toLocaleString() ?? '-';
};

export const defaultMetricCellCreator = <T = IDataType,>(accessor: string) => {
	return ({ row }: CellContext<T, unknown>) => {
		const value = row.getValue<number>(accessor);
		const metricValue = Math.round((value ?? 0) * 100) / 100;
		const valueToDisplay = defaultMetricValueFormatter(metricValue);
		return <span>{valueToDisplay}</span>;
	};
};

export const defaultGraphValueFormatter = (value: unknown) => {
	const val = (value as number) ?? 0;

	let valueShrinked = 0;
	let valueSuffix = '';
	if (val >= 1_000_000_000_000) {
		valueShrinked = val / 1_000_000_000_000;
		valueSuffix = 'T';
	} else if (val >= 1_000_000_000) {
		valueShrinked = val / 1_000_000_000;
		valueSuffix = 'B';
	} else if (val >= 1_000_000) {
		valueShrinked = val / 1_000_000;
		valueSuffix = 'M';
	} else if (val >= 1_000) {
		valueShrinked = val / 1_000;
		valueSuffix = 'K';
	} else {
		valueShrinked = val;
	}

	return (Math.round(valueShrinked * 100) / 100).toString() + valueSuffix;
};
