import { Icon } from '../icon/icon';
import styles from './spinner.module.scss';
import classNames from 'classnames';

interface ISpinner {
	is?: boolean;
	size?: string;
	className?: string;
}

export const Spinner = ({ is = true, size = '20px', className }: ISpinner) => {
	if (!is) {
		return null;
	}

	return (
		<Icon size={size} className={classNames(styles.spinner, className)} isMFP={true}>
			loading-02
		</Icon>
	);
};
