import { BidderCampaign } from '../../pages/bidder/campaigns/index/bidder.campaign';
import { EditBidderCampaign } from '../../pages/bidder/campaigns/edit/edit-bidder-campaign.page';
import routes from '../routes/index.routes';
import { CreateBidderCampaign } from '../../pages/bidder/campaigns/create/create-bidder-campaign.page';

export const BidderRouter = [
	{
		path: routes.bidder.campaigns(),
		element: <BidderCampaign />,
	},
	{
		path: routes.bidder.createCampaign(),
		element: <CreateBidderCampaign />,
	},
	{
		path: routes.bidder.editCampaign(':bidderCampaignId'),
		element: <EditBidderCampaign />,
	},
];
