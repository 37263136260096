import { createColumnHelper } from '@tanstack/react-table';
import { TableLink } from '@monorepo/base/src/components/table/table-link/table-link';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import routes from '../../routes/index.routes';
import { IMergedHeadersReturn } from './index.headers';
import { IAdminxStore } from '../../stores';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { useEffect } from 'react';
import { statusCell } from '../../components/report-table/cells/adminx.cells';
import { BidderCampaignTableModel } from '../../models/bidder-campaign/bidder-camapign.model';
import {
	currencyCellCreator,
	defaultMetricCellCreator,
	percentageNoRatioValueFormatter,
} from '../../components/report-table/cells/metrics.cells';
import { TableMergeColumn } from '@monorepo/base/src/components/table/table-merge-column/table-merge-column';
import { TableSelectionCell, TableSelectionHeader } from '@monorepo/base/src/components/table/table-selection-cell/table-selection-cell';
import { SpacerX } from '@monorepo/base/src/components/spacer/spacer';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { Statuses } from '../../enums/status';
import { isSubRow } from '@monorepo/base/src/components/table/table';
import { TableStatusCell } from '../../components/table-status-cell/table-status-cell';

const columnHelper = createColumnHelper<BidderCampaignTableModel>();

export const useBidderHeaders = (): IMergedHeadersReturn<BidderCampaignTableModel> => {
	const { settingsStore } = useStores<IAdminxStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();
	const tableStore = settingsStore.pages.get(currentRouteWithoutSpecialChars)?.tableStore;

	useEffect(() => {
		if (tableStore && Object.keys(tableStore.getColumnVisibility()).length === 0) {
			tableStore.setDefaultColumnVisibility({
				id: true,
				name: true,
				status: true,
				advertiserName: true,
				advertiserId: true,
				dailyBudget: true,
				dailyMoneySpent: true,
				dailyMoneyEarn: true,
				maxLossPerDomain: true,
				maxClicksToFirstConv: true,
				qMaxLossPerDomain: true,
				qMaxClicksToFirstConv: true,
				dailySales: true,
				stateMachineId: true,
				maxWinsToFirstClick: true,
				pageLoadsClicksRatio: true,
				maxClicksToFirstPageLoad: true,
				maxPageLoadToFirstSale: true,
				convPageLoadsRatio: true,
				convClicksRatio: true,
				targetingLinkChoicePolicy: true,
			});

			tableStore?.setColumnVisibility(tableStore.getDefaultColumnVisibility());

			if (tableStore.getSortingBy().length === 0) {
				tableStore.setSortingBy([
					{
						id: 'id',
						desc: false,
					},
				]);
			}
		}
	}, [tableStore]);

	return {
		columns: [
			columnHelper.display({
				id: 'selection',
				enableHiding: false,
				header: headerProps => (
					<TableMergeColumn>
						<TableSelectionHeader headerProps={headerProps} />
						<SpacerX x={0.7} />
						<Icon size={'14px'} isMFP={true} color={'#c5c5c5'}>
							circle
						</Icon>
						<SpacerX x={0.7} />
					</TableMergeColumn>
				),
				cell: cellProps => {
					const { row } = cellProps;
					const campaign = new BidderCampaignTableModel(row.original);

					if (isSubRow(row)) {
						return <span></span>;
					}

					return (
						<TableMergeColumn>
							<TableSelectionCell cellProps={cellProps} />
							<SpacerX x={0.7} />
							<TableStatusCell status={campaign.status as Statuses} />
						</TableMergeColumn>
					);
				},
			}),

			columnHelper.accessor('id', { header: 'ID' }),
			columnHelper.accessor('name', {
				header: 'Name',
				cell: ({ row }) => {
					return (
						<TableLink
							toEdit={routes.bidder.editCampaign(row.getValue('id'))}
							toEditState={{ isBlockRequest: true } as BetweenPagesStateProps}>
							{row.getValue('name')}
						</TableLink>
					);
				},
			}),
			columnHelper.accessor('status', {
				header: 'Status',
				cell: statusCell,
			}),
			columnHelper.accessor('advertiserName', {
				header: 'Advertiser',
			}),
			columnHelper.accessor('advertiserId', {
				header: 'Advertiser ID',
			}),
			columnHelper.accessor('dailyBudget', {
				header: 'Daily Budget',
				cell: currencyCellCreator<BidderCampaignTableModel>('dailyBudget'),
			}),
			columnHelper.accessor('dailyMoneySpent', {
				header: 'Daily Money Spent',
				cell: currencyCellCreator<BidderCampaignTableModel>('dailyMoneySpent'),
			}),
			columnHelper.accessor('dailyMoneyEarn', {
				header: 'Daily Money Earn',
				cell: currencyCellCreator<BidderCampaignTableModel>('dailyMoneyEarn'),
			}),
			columnHelper.accessor('maxLossPerDomain', {
				header: 'Max Loss Per Domain',
				cell: defaultMetricCellCreator<BidderCampaignTableModel>('maxLossPerDomain'),
			}),
			columnHelper.accessor('maxClicksToFirstConv', {
				header: 'Max Clicks to First Conv',
				cell: defaultMetricCellCreator<BidderCampaignTableModel>('maxClicksToFirstConv'),
			}),
			columnHelper.accessor('qMaxLossPerDomain', {
				header: 'Q Max Loss Per Domain',
				cell: defaultMetricCellCreator<BidderCampaignTableModel>('qMaxLossPerDomain'),
			}),
			columnHelper.accessor('qMaxClicksToFirstConv', {
				header: 'Q Max Clicks to First Conv',
				cell: defaultMetricCellCreator<BidderCampaignTableModel>('qMaxClicksToFirstConv'),
			}),
			columnHelper.accessor('dailySales', {
				header: 'Daily Sales',
				cell: defaultMetricCellCreator<BidderCampaignTableModel>('dailySales'),
			}),
			columnHelper.accessor('stateMachineId', {
				header: 'State Machine Id',
			}),
			columnHelper.accessor('maxWinsToFirstClick', {
				header: 'Max Wins To First',
				cell: defaultMetricCellCreator<BidderCampaignTableModel>('maxWinsToFirstClick'),
			}),
			columnHelper.accessor('pageLoadsClicksRatio', {
				header: 'Page Loads Clicks Ratio',
				cell: ({ row }) => percentageNoRatioValueFormatter(row.getValue('pageLoadsClicksRatio')),
			}),
			columnHelper.accessor('maxClicksToFirstPageLoad', {
				header: 'Max Clicks to First Page Load',
			}),
			columnHelper.accessor('maxPageLoadToFirstSale', {
				header: 'Max Page Load To First Sale',
			}),
			columnHelper.accessor('convPageLoadsRatio', {
				header: 'Conv Page Load Ratio',
				cell: ({ row }) => percentageNoRatioValueFormatter(row.getValue('convPageLoadsRatio')),
			}),
			columnHelper.accessor('convClicksRatio', {
				header: 'Conv Clicks Ratio',
				cell: ({ row }) => percentageNoRatioValueFormatter(row.getValue('convClicksRatio')),
			}),
			columnHelper.accessor('targetingLinkChoicePolicy', {
				header: 'Targeting Link Choice Policy',
			}),
		],
	};
};
