import { Soon } from '@monorepo/base/src/components/soon/soon';
import { Domains } from '../../pages/domains/index/domains.page';
import { DomainsView } from '../../pages/domains/view/domains-view.page';
import routes from '../routes/index.routes';
import { DomainsOverviewPage } from '../../pages/domains/view/overview/domains-overview.page';

export const DomainsRouter = [
	{
		path: routes.domains.index(),
		element: <Domains />,
	},
	{
		element: <DomainsView />,
		children: [
			{
				path: routes.domains.view(':domainName'),
				element: <DomainsOverviewPage />,
			},
			{
				path: routes.domains.pages(':domainName'),
				element: <Soon />,
			},
			{
				path: routes.domains.bidKeys(':domainName'),
				element: <Soon />,
			},
		],
	},
];
